import axios from 'axios'
import React, { useEffect } from 'react'
import configData from "../../utils/constants/config.json";
import { useState } from 'react';
import { API } from '../../Pages/AddAppointment/API';
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const SubscriptionPlans = () => {

    const [data, setData] = useState([])
    const [selectedPlan, setSelectedPlan] = useState()
    const [subscriptionPlan, setSubscriptionPlan] = useState()
    const [businessId, setBusinessId] = useState()

    const [showPopUp, setShowPopUp] = useState(false)
    const [showPopUpWrong, setShowPopUpWrong] = useState(false)

    const navigate = useNavigate();

    useEffect(() => {
        const getData = async () => {
            await axios.get(`${configData.SERVER_URL}partner/subscriptionPlanBusiness/get-all-subscription-plan`, {
                headers: {
                    accesstoken: configData.ACCESSTOKEN,
                    logintoken: localStorage.getItem('loginToken'),
                },
            })
                .then(res => {
                    setData(res.data?.subscriptionPlans)
                    setSubscriptionPlan(res.data?.subscriptionPlanId)
                    setBusinessId(res.data?.businessId)
                })
                .catch(err => console.log('SubscriptionPlans', err))
        }
        getData()
    }, [])

    const getDescription = (desc) => {
        const texts = desc.split("+")
        return texts
    }

    const PopUpYouHaveToPay = () => {
        return <div className="max-w-lg mt-20 mx-auto p-8 drop-shadow-2xl popup-box " style={{ zIndex: '9999', top: "9%" }}>
            <div className="p-6 text-left">
                <span
                    className="popup-close-icon "
                    onClick={() => {
                        setShowPopUp(false)
                    }}
                >
                    x
                </span>
                <h3 className="text-2xl pt-5" >Selected Plan</h3>
                <p className="text-lg mt-3 underline font-bold">{selectedPlan.name}</p>
                <div className="mt-5">
                    {
                        getDescription(selectedPlan.description).map(text => {
                            return <p className="text-gray-600 text-start mb-4">
                                <span>&#x25cf;</span> {text}
                            </p>
                        })
                    }
                    <p className="text-gray-600 text-start mb-4">Price: <span className='font-bold'>{selectedPlan.price == 0 ? "Free" : `${selectedPlan.price}AED`}</span></p>
                </div>
                <button
                    className="mt-5 btn text-white base-btn bg-primary hover:bg-primary-focus focus:bg-primary-focus
            active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                    onClick={
                        () => {
                            if (selectedPlan.id == 1) {
                                updateOrCreateFreePlan()
                            } else {
                                createNewStripeSession()
                            }
                        }
                    }
                >Confirm</button>
            </div>
        </div >
    }

    const PopUpWrong = () => {
        return <div className="max-w-lg mt-20 mx-auto p-8 drop-shadow-2xl popup-box " style={{ zIndex: '9999', top: "9%" }}>
            <div className="p-6 text-left">
                <span
                    className="popup-close-icon "
                    onClick={() => {
                        setShowPopUpWrong(false)
                    }}
                >
                    x
                </span>
                <h3 className="text-2xl pt-5" >Selected Plan</h3>
                <p className="text-lg mt-3 underline font-bold">Warning</p>
                <div className="mt-5">
                    You already have this plan
                </div>
                <button
                    className="mt-5 btn text-white base-btn bg-primary hover:bg-primary-focus focus:bg-primary-focus
            active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                    onClick={() => {
                        setShowPopUpWrong(false)
                    }}
                >Ok</button>
            </div>
        </div>
    }

    const createNewStripeSession = async () => {
        if (!businessId) {
            return
        }

        try {
            const currentDomain = window.location.hostname;

            let baseURLRedirect = `https://partner.drclinica.com`;

            if (currentDomain.endsWith('.org')) {
                baseURLRedirect = `https://partner.drclinica.org`;
            } else {
                // Handle other domain extensions or cases
                baseURLRedirect = 'https://partner.drclinica.com';
            }

            const paymentFor = "FeaturedUpdateSubscriptionPlan"

            var urlencoded = new URLSearchParams()
            urlencoded.append("successURL", `${baseURLRedirect}/promotions/offers?session_id={CHECKOUT_SESSION_ID}`);
            urlencoded.append("cancelURL", `${baseURLRedirect}/promotions/offers`);
            urlencoded.append("paymentFor", paymentFor);
            urlencoded.append("noOfMonths", 1);
            urlencoded.append("SubscriptionPlanPrice", selectedPlan?.price);

            const response = await API({
                method: "post",
                url: `partner/promotion/create-stripe-session`,
                payload: urlencoded,
                contentType: "application/x-www-form-urlencoded"
            });

            if (response[200] == 'Stripe payment session created successfully') {
                localStorage.setItem("planIdToSetNewPlan", selectedPlan?.id)
                window.location.href = response?.session?.url
            } else {
                console.log("something went wrong")
            }

        } catch (error) {
            console.log(error)
        }
    }

    const updateOrCreateFreePlan = async () => {
        if (selectedPlan?.id) {
            axios({
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "accesstoken": configData.ACCESSTOKEN,
                    "logintoken": localStorage.getItem('loginToken')

                },
                url: `${configData.SERVER_URL}partner/subscriptionPlanBusiness/createOrUpdateFreePlan`,
                data: { subscriptionPlanId: parseInt(selectedPlan?.id) }
            })
                .then(res => {
                    if (res.status == 200) {
                        swal({
                            title: "Renewed Successfully",
                            text: "You can 5 add offers now",
                            icon: "success",
                            button: "ok",
                        });
                        navigate("/promotions/offers")
                    } else {
                        swal({
                            title: "Something Went Wrong, Try Again",
                            text: "If you still receive same issue please contact us",
                            icon: "warning",
                            button: "ok",
                        });
                    }
                })
                .catch(err => {
                    swal({
                        title: "Something Went Wrong, Try Again",
                        text: "If you still receive same issue please contact us",
                        icon: "warning",
                        button: "ok",
                    });
                    console.log(err)
                })
        }
    }

    return (
        <div className="main-content px-[var(--margin-x)] pb-8">
            <div className="flex items-center justify-between mt-5">
                <div className="flex items-start flex-col text-slate-800 dark:text-navy-50">
                    <div className="flex items-center gap-3">
                        <h2 className="text-3xl font-bold lg:text-2xl">Subscription Plans</h2>
                        <div className="border-2 border-gray-300 font-semibold px-[8px] rounded-full">
                            <span className=" text-xs"></span>
                        </div>
                    </div>
                </div>
            </div>

            <div className='mt-5 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 '>
                {
                    data && data.length > 0 &&
                    data.map(s => {
                        return <div
                            className="max-w-sm w-full bg-white p-8 rounded-md shadow-md transform transition-transform duration-300 hover:scale-105"

                        >
                            <h2 className="text-2xl font-semibold mb-4">{s.name}</h2>
                            <div className='h-[230px]'>
                                {
                                    getDescription(s.description).map(text => {
                                        return <p className="text-gray-600 text-start mb-4">
                                            <span>&#x25cf;</span> {text}
                                        </p>
                                    })
                                }
                                <p className="text-gray-600 text-start mb-4">Price: <span className='font-bold'>{s.price == 0 ? "Free" : `${s.price}AED`}</span></p>
                            </div>

                            {
                                <p onClick={() => {
                                    setSelectedPlan(s)
                                    if (subscriptionPlan == s.id) {
                                        setShowPopUpWrong(true)
                                    } else {
                                        setShowPopUp(true)
                                    }
                                }}
                                    className="text-blue-500 hover:underline cursor-pointer">
                                    Get Plan
                                </p>

                            }
                        </div>
                    })
                }

            </div>
            {showPopUp && PopUpYouHaveToPay()}
            {showPopUpWrong && PopUpWrong()}
        </div>

    )
}

export default SubscriptionPlans