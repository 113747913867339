import React from 'react'
import { Link } from 'react-router-dom'
import { Box } from "@mui/material";
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import configData from "../../utils/constants/config.json"
import { HiMagnifyingGlass } from 'react-icons/hi2';

const ProductsECommerce = () => {

  const [products, setProducts] = useState([])
  const [isOpenArray, setIsOpenArray] = useState([]);

  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [searchParam, setSearchParam] = useState('')

  const [rerender, setRerender] = useState(false)
  const [successDeleting, setSuccessDeleting] = useState(false)
  const [dangerDeleting, setDangerDeleting] = useState(false)

  useEffect(() => {

    const getData = () => {
      axios({
        method: "get",
        url: `${configData.SERVER_URL}partner/product/view-all-products?count=${5}&page=${page}&searchText=${searchParam}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          accesstoken: configData.ACCESSTOKEN,
          logintoken: localStorage.getItem("loginToken"),
        },
      })
        .then(res => {
          if (res?.data[200]) {
            setProducts(res?.data?.product)
            setTotalPages(Number(res?.data?.totalRecords));
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
    getData()
  }, [page, searchParam, rerender])


  const toggleDropdown = (index) => {
    const updatedIsOpenArray = [...isOpenArray];

    updatedIsOpenArray[index] = !updatedIsOpenArray[index];

    setIsOpenArray(updatedIsOpenArray);
  };

  const pagination = () => {
    if (page == 1) {
      return (
        <ol class="pagination mb-20">
          <li class="bg-slate-150 dark:bg-navy-500">
            <button
              data-page="1"
              onClick={(e) => setPage(e.currentTarget.getAttribute("data-page"))}
              class="flex h-8 min-w-[2rem] items-center justify-center rounded-lg bg-primary px-3 leading-tight text-white transition-colors hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
            >
              1
            </button>
          </li>
          {totalPages > 1 ? (
            <li class="bg-slate-150 dark:bg-navy-500">
              <button
                data-page="2"
                onClick={(e) => setPage(e.currentTarget.getAttribute("data-page"))}
                class="flex h-8 min-w-[2rem] items-center justify-center rounded-lg px-3 leading-tight transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
              >
                2
              </button>
            </li>
          ) : null}
          <li class="rounded-r-lg bg-slate-150 dark:bg-navy-500">
            <button
              data-page={totalPages}
              onClick={(e) => setPage(e.currentTarget.getAttribute("data-page"))}
              class="flex h-8 w-8 items-center justify-center rounded-lg text-slate-500 transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:text-navy-200 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </button>
          </li>
        </ol>
      );
    } else {
      if (page == totalPages) {
        return (
          <ol class="pagination">
            <li class="rounded-l-lg bg-slate-150 dark:bg-navy-500">
              <button
                data-page="1"
                onClick={(e) => setPage(e.currentTarget.getAttribute("data-page"))}
                class="flex h-8 w-8 items-center justify-center rounded-lg text-slate-500 transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:text-navy-200 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>
            </li>
            <li class="bg-slate-150 dark:bg-navy-500">
              <button
                data-page={parseInt(page) - 1}
                onClick={(e) => setPage(e.currentTarget.getAttribute("data-page"))}
                class="flex h-8 min-w-[2rem] items-center justify-center rounded-lg px-3 leading-tight transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
              >
                {parseInt(page) - 1}
              </button>
            </li>
            <li class="bg-slate-150 dark:bg-navy-500">
              <button
                data-page={page}
                onClick={(e) => setPage(e.currentTarget.getAttribute("data-page"))}
                class="flex h-8 min-w-[2rem] items-center justify-center rounded-lg bg-primary px-3 leading-tight text-white transition-colors hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
              >
                {page}
              </button>
            </li>
          </ol>
        );
      } else {
        return (
          <ol class="pagination">
            <li class="rounded-l-lg bg-slate-150 dark:bg-navy-500">
              <button
                data-page="1"
                onClick={(e) => setPage(e.currentTarget.getAttribute("data-page"))}
                class="flex h-8 w-8 items-center justify-center rounded-lg text-slate-500 transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:text-navy-200 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>
            </li>
            <li class="bg-slate-150 dark:bg-navy-500">
              <button
                data-page={parseInt(page) - 1}
                onClick={(e) => setPage(e.currentTarget.getAttribute("data-page"))}
                class="flex h-8 min-w-[2rem] items-center justify-center rounded-lg px-3 leading-tight transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
              >
                {parseInt(page) - 1}
              </button>
            </li>
            <li class="bg-slate-150 dark:bg-navy-500">
              <button
                data-page={page}
                onClick={(e) => setPage(e.currentTarget.getAttribute("data-page"))}
                class="flex h-8 min-w-[2rem] items-center justify-center rounded-lg bg-primary px-3 leading-tight text-white transition-colors hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
              >
                {page}
              </button>
            </li>
            <li class="bg-slate-150 dark:bg-navy-500">
              <button
                data-page={parseInt(page) + 1}
                onClick={(e) => setPage(e.currentTarget.getAttribute("data-page"))}
                class="flex h-8 min-w-[2rem] items-center justify-center rounded-lg px-3 leading-tight transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
              >
                {parseInt(page) + 1}
              </button>
            </li>
            <li class="rounded-r-lg bg-slate-150 dark:bg-navy-500">
              <button
                data-page={totalPages}
                onClick={(e) => setPage(e.currentTarget.getAttribute("data-page"))}
                class="flex h-8 w-8 items-center justify-center rounded-lg text-slate-500 transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:text-navy-200 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </button>
            </li>
          </ol>
        );
      }
    }
  };


  const handleDelete = (id, indexToCloseDropDown) => {

    axios({
      method: 'delete',
      url: configData.SERVER_URL + `partner/product/delete-product/${id}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        accesstoken: configData.ACCESSTOKEN,
        logintoken: localStorage.getItem('loginToken'),
      },
    }).then(res => {
      console.log(res)
      if (res.data[200]) {
        setRerender(!rerender)
        setSuccessDeleting(true)

        toggleDropdown(indexToCloseDropDown)

        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });

        setTimeout(() => {
          setSuccessDeleting(false)
        }, 2500);

      } else {
        setDangerDeleting(true)
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
        setTimeout(() => {
          setDangerDeleting(false)
        }, 2500);
      }
    })
      .catch(err => console.log(err))

  }

  return (
    <div className="main-content px-[var(--margin-x)] pb-8">
      <div className="flex items-center justify-between mt-5">
        <div class="items-center justify-between">
          <div class="flex items-center space-x-4 py-5 lg:py-6">
            <h2 class="text-xl font-medium text-slate-800 dark:text-navy-50 lg:text-2xl">
              Products List
            </h2>
            <div class="hidden h-full py-1 sm:flex">
              <div class="h-full w-px bg-slate-300 dark:bg-navy-600"></div>
            </div>
            <ul class="hidden flex-wrap items-center space-x-2 sm:flex">
              <li class="flex items-center space-x-2">
                <Link
                  class="text-primary transition-colors hover:text-primary-focus dark:text-accent-light dark:hover:text-accent"
                  href="/settings"
                >
                  Settings
                </Link>
                <svg
                  x-ignore
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </li>
              <li>Products Menu</li>
            </ul>
          </div>

        </div>

        <div className="flex items-center justify-between gap-3">
          <div className="text-right">
            <Link to={'/services/products-Ecommerce/add'}>
              <button
                className="btn text-white base-btn bg-primary hover:bg-primary-focus focus:bg-primary-focushanger
              
         active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
              >
                Add Product
              </button>
            </Link>
          </div>
        </div>
      </div>
      {successDeleting &&
        <div className='p-3' style={{ backgroundColor: 'rgba(0, 255, 0, .3)', borderRadius: '8px' }}>
          Product Has Been Deleted Successfully
        </div>
      }
      {
        dangerDeleting && <div className='p-3' style={{ backgroundColor: 'rgba(255, 0, 0, .3)', borderRadius: '8px' }}>
          Something went Wrong, Try Again Please!
        </div>
      }
      <div
        className="flex flex-auto items-center gap-3 w-[320px] lg:w-[380px] px-3 py-2 border-[1px]
               border-gray-400 rounded-full bg-white"
      >
        <HiMagnifyingGlass className="text-black font-bold h-4 w-4 lg:w-5 lg:h-5 cursor-pointer" />
        <input
          className="outline-none text-sm lg:text-base bg-transparent flex-auto"
          type="text"
          placeholder="Search by name "
          // value={searchText}
          onChange={(e) => setSearchParam(e.target.value)}
        />
      </div>
      <Box className="dark:text-navy-50">
        <div className="grid grid-cols-1 gap-4 sm:gap-5 mb-5 lg:grid-cols-1 lg:gap-6">
          <div className="card mt-3" style={{ marginBottom: "6rem" }}>
            <div
              className="is-scrollbar-hidden min-w-full overflow-x-auto"
              x-data="pages.tables.initExample1"
            >
              <table className="is-hoverable w-full text-left">
                <thead>
                  <tr>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      No#
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Name
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Barcode
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Product Brand
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Short Description
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Long Description
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Category
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Price
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Measurement
                    </th>

                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Inventory
                    </th>
                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    products && products.length > 0 && products.map((product, index) => {
                      return <tr className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500">
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          {product?.id}
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          {product?.name}
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          {product?.barcode}
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          {product?.productBrand?.name}
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          {product?.short_description}
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          {`${product?.description.substring(0,50)}...`}
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          {product?.productPrice?.pricefrom}
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          {product?.productCategory?.name}

                        </td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          {product?.measurement?.amount} {product?.measurement?.unit}
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          {product?.inventory}
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                          <div className="text-left">
                            <div>
                              <button
                                onClick={() => toggleDropdown(index)}
                                type="button"
                                className="inline-flex justify-center w-8 h-8 p-2 text-gray-500 hover:text-gray-800 focus:outline-none focus:ring focus:ring-gray-300"
                              >
                                {/* Three dots icon (you can replace this with your own) */}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-5 w-5"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  stroke-width="2"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                                  />
                                </svg>
                              </button>
                            </div>

                            {isOpenArray[index] && (
                              <div className="origin-top-right absolute right-0 mt-2 w-32 bg-white border border-gray-300 rounded-lg shadow-lg">
                                <div className="py-1">
                                  <Link
                                    to={`/services/products-Ecommerce/edit/${product?.id}`}
                                    className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                                  >
                                    Edit
                                  </Link>
                                  <a
                                    href="#"
                                    className="block px-4 py-2 text-red-600 hover:bg-red-200"
                                    onClick={() => handleDelete(product?.id, index)}
                                  >
                                    Delete
                                  </a>
                                </div>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Box>
      {pagination()}
    </div>
  )
}

export default ProductsECommerce