import { useState } from "react"
import configData from "../../utils/constants/config.json"
import axios from "axios"

const SelectMeasurement = ({
    isOpen,
    handleSelectMeasurement,
    handleOpenAddMeasurement,
    measurements,
    setSelectedMeasurement,
    setRerender
}) => {

    const [success, setSuccess] = useState(false)

    const handleDelete = (e, id) => {
        e.stopPropagation()
        axios({
            method: "delete",
            url: `${configData.SERVER_URL}partner/product/delete-product-measurement/${id}`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                accesstoken: configData.ACCESSTOKEN,
                logintoken: localStorage.getItem("loginToken"),
            },
        })
            .then(res => {
                setRerender(prev => !prev)
                setSuccess(true)
                setTimeout(() => {
                    setSuccess(false)
                }, 1500);
            })
            .catch(err => {
                console.log(err)
            })

    }
    return (
        <div className={`fixed inset-0 flex items-center overflow-hidden justify-center transition-opacity duration-300 ease-in-out z-[999] ${isOpen ? "opacity-100 z-[999] " : "opacity-0 pointer-events-none"}`} >
            <div className="absolute inset-0 bg-gray-800 opacity-75 "></div>
            <div className={`w-full sm:w-1/2 lg:w-1/3 z-50 bg-white p-8 rounded-lg shadow-lg mx-4`}>
                <h2 className="text-xl font-semibold mb-4">select a measurement</h2>

                <div className="flex justify-center">
                    <span className=" cursor-pointer text-lg text-blue-700 flex items-center pb-4">
                        <i class="fa-plus flex justify-center items-center mr-2 border rounded-full bg-white w-5 h-5 "></i>
                        <p
                            className="hover:underline"
                            onClick={handleOpenAddMeasurement}
                        > Add a measurement</p>
                    </span>
                </div>

                {
                    success && <div className='p-3' style={{ color: '#fff', backgroundColor: 'rgba(0, 255, 0, .3)', borderRadius: '8px' }}>
                        Measurement Has Been Deleted Successfully
                    </div>
                }

                <div className="max-h-[400px] overflow-y-auto">
                    {
                        measurements && measurements.length > 0 && measurements.map(mea => {
                            return <div className="cursor-pointer flex justify-between items-center hover:bg-gray-100 py-3 border-b border-t border-gray-200 px-2"
                                onClick={() => {
                                    setSelectedMeasurement(mea)
                                    handleSelectMeasurement()
                                }}
                            >
                                <span className=" text-lg text-black-700 flex items-center">
                                    {mea?.amount} {mea?.unit}
                                </span>
                                <i onClick={(e) => handleDelete(e, mea.id)} className="fa fa-trash text-red-600"></i>

                            </div>
                        })
                    }

                </div>

                <div
                    className='mt-4 btn border border-slate-300 font-medium hover:text-slate-300 text-slate-800 hover:bg-slate-800 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90'
                    onClick={handleSelectMeasurement}
                >
                    Close
                </div>
            </div>
        </div>
    )
}

export default SelectMeasurement