import React, { useEffect, useState } from "react";
import "./client.css";
import swal from "sweetalert";
import axios from "axios";
import configData from "../../utils/constants/config.json";
import ImageResize from "../../components/ImageCropper/imageupload";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { BiEdit } from 'react-icons/bi';
import { findDuration } from "../../utls";
import { API } from "../../Pages/AddAppointment/API";

export default function ClientDetail() {
  const location = useLocation();
  const [popup, showPopup] = useState(false);
  const [popup2, showPopup2] = useState(false);

  const [isupdate, setUpdate] = useState(false);

  const client = location.state;
  const [state, setState] = useState({
    imageResizer: null,
    loader: false,
    voucherImage: null,
    uplodedImag: client.image,
  });

  const history = useNavigate()
  const imageModalClose = (e) => {
    setState({ ...state, imageResizer: false });
  };
  const [customerAppointment, setCustomerAppointment] = useState({})

  useEffect(() => {
    if (client?.id) {
      API({
        method: 'get',
        url: `partner/clients/customer-appointments/7/${client?.id}?count=10&page=1`,
      }).then((res) => {
        setCustomerAppointment(res?.appointments)
      })
    }
  }, [client])


  const changeImage = (file) => {
    var url = URL.createObjectURL(file);
    setState({
      ...state,
      voucherImage: file,
      // profileSrc : url
    });
  };

  const uploadImage = () => {
    setState({ ...state, loader: true });
    var bodyFormData = new FormData();
    bodyFormData.append("image", state.voucherImage);
    axios({
      method: "post",
      url: configData.SERVER_URL + "partner/vouchers/voucher-image",
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        accesstoken: configData.ACCESSTOKEN,
        logintoken: localStorage.getItem("loginToken"),
      },
    })
      .then((resp) => {
        setState({ ...state, loader: false });
        setUpdate(!isupdate)
        setState({
          ...state,
          imageResizer: false,
          uplodedImag: resp.data.data.url[0],
        });
      })
      .catch((err) => {
        swal({
          title: "Server Not Responding",
          text: "Please try again later",
          icon: "warning",
          button: "ok",
        });
        console.log(err);
      });
  };

  const [formData, setFormData] = useState({
    customerId: client?.id,
    firstName: client.firstname,
    lastName: client.lastname,
    email: client.email,
    gender: client.gender,
    phone: client.phone,
    customerNotificationId: client.customerNotificationId,
    isemail: client.isemail,
    ismessage: client.ismessage,
    isapp: client.isapp,
    image: client.image || undefined,
    addressId: client?.customeraddress[0]?.id || 0,
    address: client?.customeraddress[0]?.address || "",
    city: client?.customeraddress[0]?.city || "",
    country: client?.customeraddress[0]?.country || "",
    state: client?.customeraddress[0]?.state || "",
    postcode: client?.customeraddress[0]?.postcode || 0,
    appartement: client?.customeraddress[0]?.appartement || "",
    district: client?.customeraddress[0]?.district || "",
    name: client?.customeraddress[0]?.name || "",
    postcode: client?.customeraddress[0]?.postcode || 0,
    region: client?.customeraddress[0]?.region || "",
  });

  const [addressData, setAddressData] = useState({
    id: 0,
    name: "",
    address: "",
    appartement: "0",
    district: "",
    city: "",
    region: "",
    postcode: 0,
    country: "",
    status: 0
  });
  const [notificationsettings, setnotificationsettings] = useState({
    isemail: client.isemail,
    ismessage: client.ismessage,
    isapp: client.isapp,
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormData({ ...formData, image: state.uplodedImag })
    console.log(formData, 'formData')

    API({
      method: "POST",
      url: "partner/clients/update-customer-details",
      contentType: "application/json",
      payload: JSON.stringify({
        customerId: client?.id,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        gender: formData.gender,
        phone: formData.phone,
        image: state.uplodedImag,
        customerNotificationId: client.customerNotificationSettings?.id,
        isemail: notificationsettings.isemail, //email notification 0 for false 1 for true
        ismessage: notificationsettings.ismessage, //message notification 0 for false 1 for true
        isapp: notificationsettings.isapp, //app notification 0 for false 1 for true
      })
    })
      .then((response) => {
        setFormData({
          ...formData,
          // customerId: response.customer.id,
          firstName: response.customer.firstname,
          lastName: response.customer.lastname,
          email: response.customer.email,
          gender: response.customer.gender,
          phone: response.customer.phone,
          customerNotificationId: response.customer.customerNotificationId,
          isemail: response.customer.isemail,
          ismessage: response.customer.ismessage,
          isapp: response.customer.isapp,
          image: response.customer.image || undefined
        })

        setUpdate(!isupdate)
        showPopup(!popup)
        // Handle response
        swal({
          title: "Client details updated successfully",
          icon: "success",
          button: "Ok",
        });
      })
      .catch((error) => {
        console.log(error);
        // Handle error
        swal({
          title: "Error updating client details",
          text: "Please try again later",
          icon: "error",
          button: "Ok",
        });
      });
  };



  const handleSubmitAddress = (e) => {
    e.preventDefault();
    setFormData({ ...formData, image: state.uplodedImag })
    console.log(formData, 'formData')

    API({
      method: "POST",
      url: "partner/clients/update-customer-address",
      contentType: "application/json",
      payload: JSON.stringify({
        id: formData.addressId,
        name: formData.name,
        address: formData.address,
        appartement: formData.appartement,
        district: formData.district,
        city: formData.city,
        region: formData.region,
        postcode: formData.postcode,
        country: formData.country,
        status: formData.status,

      })
    })
      .then((response) => {
        console.log(response, 'response')
        setFormData({
          ...formData,
          city: response.customerAddress.city,
          country: response.customerAddress.country,
          state: response.customerAddress.state,
          postcode: response.customerAddress.postcode,
          appartement: response.customerAddress.appartement,
          district: response.customerAddress.district,
          name: response.customerAddress.name,
          postcode: response.customerAddress.postcode,
          region: response.customerAddress.region,
        })
        setUpdate(!isupdate)
        showPopup2(!popup2)
        // Handle response
        swal({
          title: "Client details updated successfully",
          icon: "success",
          button: "Ok",
        });
      })
      .catch((error) => {
        console.log(error);
        // Handle error
        swal({
          title: "Error updating client details",
          text: "Please try again later",
          icon: "error",
          button: "Ok",
        });
      });
  };


  return (
    <main class="main-content px-[var(--margin-x)] pb-8">
      <div class="flex items-center space-x-4 py-5 lg:py-6">
        <h2 class="text-xl font-medium text-slate-800 dark:text-navy-50 lg:text-2xl">
          Client Details
        </h2>


      </div>
      <div class="flex grid-cols-3 gap-4 sm:gap-5 lg:gap-3 text-center">
        <div class="">

          <ul style={{ listStyle: "none", textAlign: 'center', height: "100%" }} className="card dr-client-Info mb-20">

            <li>
              <div>
                <div class="avatar h-36 w-36">
                  <img
                    class="rounded-full bg-slate-200"
                    src={state.profileSrc ? state.profileSrc : formData.image}
                    alt="avatar"
                    onClick={() => {
                      // document.getElementById("profile").click()
                      setState({ ...state, imageResizer: true });
                    }}
                  />
                </div>{" "}

                <p style={{ color: "blue", textDecoration: "", paddingTop: '10px' }}>
                  {formData.email}
                </p>
              </div>
            </li>
            {/* <li className="text-center">
              <p>Total Sales (AED)</p>
            </li>  */}
            <li style={{ cursor: "pointer" }} onClick={() => history("/team/provider-reviews", { state: formData.firstName })}>
              <p>
                <i class="fas fa-star fa-3x" style={{ color: "yellow" }}></i>
              </p>
              <p style={{ fontWeight: "bold" }}>
                {formData?.serviceProviderReviews?.length > 0
                  ? formData?.serviceProviderReviews?.length
                  : "0"} Reviews
              </p>
            </li>

            <li>
              {/* <p className="py-1">{customerAppointment?.appointments?.length}</p> */}
              <p className="" style={{ fontWeight: "bold", marginBottom: "-16px", marginTop: "-1px" }}><span >{client?.bookings?.length} </span> Bookings</p>
              {/* <p className="font-bold py-1">Appointments List</p> */}
            </li>
            {client?.bookings?.map(
              ({
                date,
                price,
                starttime,
                endtime,
                status,
                service,
                pricingDetails,
                bill,
              }) => {
                return (
                  <div
                    className="flex pt-3 br-1 justify-between items-center"
                    style={{ borderBottom: "1px solid #dddddd" }}
                  >
                    <div className="flex-none w-2/12 text-left">
                      <strong>{moment(date).format("MMM Do")}</strong>
                    </div>
                    <div
                      style={{ paddingLeft: "10px", }}
                      className="flex-initial pl-3 text-left w-7/12 border-l "
                    >
                      Service:{" "}
                      <strong>{pricingDetails?.serviceName}</strong>
                      <p>
                        Duration:{" "}
                        <strong>{findDuration(starttime, endtime)}</strong>
                      </p>
                    </div>
                    <div className="flex-initial pt-4 w-3/12 border-l">
                      AED <strong>{price}</strong>
                    </div>
                  </div>
                );
              }
            )}
            {client?.bookings?.length == 0 &&
              <div className="py-1 text-center">
                Not Found
              </div>}
          </ul>







        </div>
        <div class=" w-full ml-4 text-left pl-4">
          <div className="card text-left w-full px-4 py-4 sm:px-5">
            <div className="m-2">
              <h2 className="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                Basic info
              </h2>
            </div>
            <div className="m-2 ml-auto">
              <button
                style={{
                  color: "blue", textAlign: "center", margin: "auto",
                  display: "block",
                  position: "absolute",
                  top: "29px",
                  right: "20px", textDecoration: ""
                }}
                onClick={() => showPopup(!popup)}
                className="underline text-center base-btn dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
              >
                <BiEdit size={22} />
              </button>
            </div>
            <div className="my-1 h-px bg-slate-150 dark:bg-navy-500"></div>

            <div className="m-2 text-left">
              <h2 className="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                Name
              </h2>
              <p className="pb-4">{formData?.firstName + " " + formData?.lastName}</p>
              <h2 className="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                Gender
              </h2>
              <p className="pb-4">{formData?.gender}</p>
              <h2 className="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                Phone #
              </h2>
              <p className="pb-4">{formData?.phone}</p>
              {/* <h2 className="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                Date Of Birth
              </h2>
              <p className="pb-4">{formData?.dateofbirth}</p> */}
            </div>
          </div>

          <div className="card text-left mt-5 w-full px-4 py-4 sm:px-5">
            <div className="m-2">
              <h2 className="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                Address
              </h2>
              {client?.customeraddress?.length !== 0 &&
                <button
                  style={{
                    color: "blue", textAlign: "center", marginLeft: "auto",
                    top: "27px",
                    right: "16px",
                    position: "absolute",
                    display: "block", textDecoration: ""
                  }}
                  onClick={() => showPopup2(!popup2)}
                  className="underline text-center base-btn dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                >
                  <BiEdit size={22} />
                </button>}
            </div>
            <div className="m-2"></div>
            <div className="my-1 h-px bg-slate-150 dark:bg-navy-500"></div>

            <div className="m-2">
              <h2 className="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                {" "}
              </h2>
              {console.log(client?.customeraddress, "client?.customeraddress")}
              {client?.customeraddress?.length == 0 ?
                <p className="pb-4 text-center"> Not Found</p>
                :
                <div className="pb-4 flex">
                  {/* do width 50% */}
                  <div style={{ width: "50%" }}>
                    <h2 className="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                      Address Name
                    </h2>
                    <p className="pb-4">{formData.name}</p>
                    <h2 className="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                      Address
                    </h2>
                    <p className="pb-4">{formData.address}</p>
                    <h2 className="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                      City
                    </h2>
                    <p className="pb-4">{formData.city}</p>
                    <h2 className="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                      District
                    </h2>
                    <p className="pb-4">{formData.district}</p>
                  </div>
                  <div style={{ width: "50%" }}>
                    <h2 className="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                      Country
                    </h2>
                    <p className="pb-4">{formData.country}</p>
                    <h2 className="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                      Postcode
                    </h2>
                    <p className="pb-4">{formData.postcode}</p>
                    <h2 className="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                      Appartment
                    </h2>
                    <p className="pb-4">{formData.appartement}</p>
                  </div>
                  {/* // do line line  */}
                  <div className="my-1 h-px bg-slate-150 dark:bg-navy-500"></div>

                </div>

              }
            </div>
          </div>
        </div>

      </div>
      {state.imageResizer ? (
        <ImageResize
          loader={state.loader}
          aspectRatio={1.0}
          imageModalClose={imageModalClose}
          changeImage={changeImage}
          saveImageAdded={uploadImage}
        />
      ) : (
        <></>
      )}

      {popup && (
        <div className="col-md-9 mx-auto p-8 drop-shadow-2xl popup-box">
          <span className="popup-close-icon" onClick={() => {
            setFormData({
              ...formData,
              customerId: client?.id,
              firstName: client.firstname,
              lastName: client.lastname,
              email: client.email,
              gender: client.gender,
              phone: client.phone,
              customerNotificationId: client.customerNotificationId,
              isemail: client.isemail,
              ismessage: client.ismessage,
              isapp: client.isapp,
              image: client.image || undefined,
              addressId: client?.customeraddress[0]?.id || 0,
              address: client?.customeraddress[0]?.address || "",
            })

            showPopup(false)
          }}>
            x
          </span>
          <form className="space-x-6 p-6 text-left">
            {/* <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:grid-cols-12 lg:gap-12"> */}
            <div >
              <div className="relative flex flex-col overflow-hidden rounded-lg bg-gradient-to-br   p-3.5">
                <label className="block">
                  <div class="avatar h-12 w-12">
                    <img
                      class="rounded-full bg-slate-200"
                      src={
                        state.uplodedImag
                          ? state.uplodedImag
                          : "/images/upload-image.png"
                      }
                      alt="avatar"
                      onClick={() => {
                        // document.getElementById("profile").click()
                        setState({ ...state, imageResizer: true });
                      }}
                    />
                  </div>{" "}
                  <span className="after:content-['*'] after:ml-0.5 after:text-red-500   text-sm font-medium text-slate-700">
                    Profile image
                  </span>
                </label>
                <label className="block" style={{ marginTop: "20px" }}>
                  <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                    First Name
                  </span>
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                    placeholder="Exampel John"
                  />
                </label>
                <label className="" style={{ marginTop: "20px" }}>
                  <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                    Last Name
                  </span>
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                    placeholder="Example Doe"
                  />
                </label>
                <label
                  className="block"
                  style={{ marginLeft: "0px", marginTop: "20px" }}
                >
                  <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                    Email
                  </span>
                  <input
                    value={formData.email}
                    onChange={handleChange}
                    type="email"
                    name="email"
                    className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                    placeholder="you@example.com"
                  />
                </label>
                <label
                  className="block"
                  style={{ marginLeft: "0px", marginTop: "20px" }}
                >
                  <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                    Phone Number*
                  </span>
                  <input
                    value={formData.phone}
                    onChange={handleChange}
                    name="phone"
                    type="tel"
                    id="phone"
                    className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                    placeholder="+112345678998"
                  />
                </label>
                <div
                  style={{
                    marginLeft: "4px",
                    marginTop: "20px",
                    marginBottom: "8px",
                    color: "black",
                  }}
                >
                  <legend>Gender</legend>

                  <input
                    id="draft"
                    className="appearance-none border border-gray-300 rounded-full w-5 h-5 checked:bg-blue-600 checked:border-transparent focus:outline-none"
                    type="radio"
                    style={{ position: "relative", top: "4px", right: "3px" }}
                    name="gender"
                    value={"female"}
                    checked={formData.gender === "female"}
                    onChange={handleChange}
                  />
                  <label
                    for="draft"
                    className="peer-checked/draft:text-sky-500"
                  >
                    Female
                  </label>

                  <input
                    name="gender"
                    value={"male"}
                    style={{
                      marginLeft: "10px",
                      position: "relative",
                      top: "4px",
                      right: "3px",
                    }}
                    className="appearance-none border border-gray-300 rounded-full w-5 h-5 checked:bg-blue-600 checked:border-transparent focus:outline-none"
                    checked={formData.gender === "male"}
                    onChange={handleChange}
                    id="published"
                    type="radio"
                  />
                  <label
                    for="published"
                    className="peer-checked/published:text-sky-500"
                  >
                    Male
                  </label>
                </div>
                <div>
                  <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                    Add Notification
                  </h2>
                </div>
                <div class="pt-2">
                  <p>Automatically Notification sends to clients. </p>
                  <div class="inline-space mt-2 pt-2">
                    <label class="inline-flex items-center space-x-2">
                      <input
                        class="form-checkbox is-outline h-5 w-5 rounded-full border-slate-400/70 before:bg-primary checked:border-primary hover:border-primary focus:border-primary dark:border-navy-400 dark:before:bg-accent dark:checked:border-accent dark:hover:border-accent dark:focus:border-accent"
                        type="checkbox"
                        checked={notificationsettings.isemail}
                        onChange={() => {
                          setnotificationsettings({
                            ...notificationsettings,
                            isemail: !notificationsettings.isemail,
                          });
                        }}
                      />
                      <p>Email</p>
                    </label>

                    <label class="inline-flex items-center space-x-2">
                      <input
                        class="form-checkbox is-outline h-5 w-5 rounded-full border-slate-400/70 before:bg-primary checked:border-primary hover:border-primary focus:border-primary dark:border-navy-400 dark:before:bg-accent dark:checked:border-accent dark:hover:border-accent dark:focus:border-accent"
                        type="checkbox"
                        checked={notificationsettings.ismessage}
                        onChange={() => {
                          setnotificationsettings({
                            ...notificationsettings,
                            ismessage: !notificationsettings.ismessage,
                          });
                        }}
                      />
                      <p>SMS</p>
                    </label>

                    <label class="inline-flex items-center space-x-2">
                      <input
                        class="form-checkbox is-outline h-5 w-5 rounded-full border-slate-400/70 before:bg-primary checked:border-primary hover:border-primary focus:border-primary dark:border-navy-400 dark:before:bg-accent dark:checked:border-accent dark:hover:border-accent dark:focus:border-accent"
                        type="checkbox"
                        checked={notificationsettings.isapp}
                        onChange={() => {
                          setnotificationsettings({
                            ...notificationsettings,
                            isapp: !notificationsettings.isapp,
                          });
                        }}
                      />
                      <p>App</p>
                    </label>
                  </div>
                </div>
                <button
                  onClick={handleSubmit}
                  className="btn text-white base-btn bg-primary hover:bg-primary-focus focus:bg-primary-focus
                    active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                  style={{
                    float: "right",
                    marginTop: "5px",
                    marginBottom: "16px",
                  }}
                >
                  Update Client Detail
                </button>
              </div>

            </div>
          </form>
        </div>
      )}
      {popup2 && (
        <div className="col-md-9 mx-auto p-8 drop-shadow-2xl popup-box">
          <span className="popup-close-icon" onClick={() => {
            setFormData({
              ...formData,
              city: client?.customeraddress[0]?.city || "",
              country: client?.customeraddress[0]?.country || "",
              state: client?.customeraddress[0]?.state || "",
              postcode: client?.customeraddress[0]?.postcode || 0,
              appartement: client?.customeraddress[0]?.appartement || "",
              district: client?.customeraddress[0]?.district || "",
              name: client?.customeraddress[0]?.name || "",
              postcode: client?.customeraddress[0]?.postcode || 0,
              region: client?.customeraddress[0]?.region || "",
            })
            showPopup2(false)
          }}>
            x
          </span>
          <form className="space-x-6 p-6 text-left">
            <div className="grid grid-cols-1 gap-4 sm:gap-5  lg:gap-12">

              {/* column two  */}
              <div className="relative flex flex-col overflow-hidden rounded-lg bg-gradient-to-br   p-3.5">
                <div class="">
                  <div class=" ">
                    <div class="m-2">
                      <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                        Edit Address
                      </h2>
                    </div>
                    <label className="block" style={{ marginTop: "20px" }}>
                      Street address
                      <input
                        type="text"
                        name="name"
                        id="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                        placeholder="Street Address"
                      />
                    </label>

                    <label className="block" style={{ marginTop: "20px" }}>
                      Building
                      <input
                        type="text"
                        name="address"
                        id="address"
                        value={formData.address}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                        placeholder="Building"
                      />
                    </label>

                    <label className="block" style={{ marginTop: "20px" }}>
                      Apartment
                      <input
                        type="text"
                        name="appartement"
                        id="appartement"
                        value={formData.appartement}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                        placeholder="Appartement "
                      />
                    </label>

                    <label className="block" style={{ marginTop: "20px" }}>
                      District
                      <input
                        type="text"
                        name="district"
                        id="district"
                        value={formData.district}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                        placeholder="District"
                      />
                    </label>

                    <label className="block" style={{ marginTop: "20px" }}>
                      City
                      <input
                        type="text"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                        placeholder="City"
                      />
                    </label>

                    <label className="block" style={{ marginTop: "20px" }}>
                      Region
                      <input
                        type="text"
                        name="region"
                        id="region"
                        value={formData.region}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                        placeholder="Region"
                      />
                    </label>

                    <label className="block" style={{ marginTop: "20px" }}>
                      Postcode
                      <input
                        type="text"
                        name="postcode"
                        id="postcode"
                        value={formData.postcode}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                        placeholder="Postcode"
                      />
                    </label>
                    <label className="block" style={{ marginTop: "20px" }}>
                      Country
                      <input
                        type="text"
                        name="country"
                        id="country"
                        value={formData.country}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                        placeholder="Country"
                      />
                    </label>

                    <button
                      onClick={handleSubmitAddress}
                      className="btn mt-3 text-white base-btn bg-primary hover:bg-primary-focus focus:bg-primary-focus
                    active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                      style={{
                        float: "right",
                        marginTop: "23px",
                        marginBottom: "0px",
                      }}
                    >
                      Update Client Address
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}

    </main>
  );
}