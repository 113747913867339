import { Fragment } from 'react'
import DatePicker from "react-datepicker";
import { Popover, Transition } from "@headlessui/react";
import ClearDataButton from '../ClearDataButton/ClearDataButton';
import DatePickerCustomDay from '../DatePickerCustomDay/DatePickerCustomDay';
import DatePickerCustomHeaderTwoMonth from '../DatePickerCustomHeaderTwoMonth/DatePickerCustomHeaderTwoMonth';
import { CalendarIcon } from "@heroicons/react/24/outline";

const CalenderPopUp = ({
  className = '',
  // renderInput,
  selectedDate = new Date(),
  setSelectedDate,
  minDate = new Date(),
  maxDate = new Date(),
}) => {

  const onChangeDate = (date = null) => {
    date && setSelectedDate(date);
    document.getElementById("TimeSelector")?.click()
  };

  const renderInput = () => {
    return (
      <>
        <div className="text-neutral-300 dark:text-neutral-400">
          <CalendarIcon className="w-8 h-8 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow text-left">
          <span className="block xl:text-lg font-semibold">
            {selectedDate?.toLocaleDateString("en-US", {
              month: "short",
              day: "2-digit",
              year: "numeric"
            }) || "Add dates"}
          </span>
          <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {"Appointment Date"}
          </span>
        </div>
      </>
    );
  };
  return (
    <>
      {selectedDate &&
        // <div className="fixed inset-0 flex items-start  justify-center z-50">
        //   <div className="absolute inset-0 bg-gray-800 opacity-75"></div>
        //   <div className="mx-5 w-full sm:w-1/2 lg:w-1/3 z-50 bg-white p-4 rounded-lg shadow-lg mt-32" >
        <>
          <Popover className={`StayDatesRangeInput  z-10 relative flex ${className}`} >
            {({ open }) => (
              <>
                <Popover.Button
                  id={"DateSelector"}
                  className={`DateSelector flex-1 flex relative p-3 items-center space-x-3 focus:outline-none ${open ? "shadow-lg" : ""
                    }`}
                >
                  {renderInput()}
                  {selectedDate && open && (
                    <ClearDataButton onClick={() => onChangeDate(null)} />
                  )}
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute max-w-fit  left-auto xl:-right-10 right-0 z-10 mt-3 top-full w-screen max-w-sm px-4 sm:px-0 lg:max-w-lg  " style={{ top: '-100px', left: '-69px', zIndex: '999999' }}>
                    <div className="overflow-hidden  rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800 p-8">
                      <DatePicker
                        selected={selectedDate}
                        onChange={onChangeDate}
                        startDate={selectedDate}
                        monthsShown={1}
                        minDate={minDate}
                        maxDate={maxDate}
                        showPopperArrow={true}
                        inline
                        renderCustomHeader={(p) => (
                          <DatePickerCustomHeaderTwoMonth {...p} />
                        )}
                        renderDayContents={(day, date) => (
                          <DatePickerCustomDay dayOfMonth={day} date={date} />
                        )}
                      />
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}

          </Popover>

        </>
        //   </div>
        // </div>
      }
    </>
  )
}

export default CalenderPopUp