import React from 'react'
import { useRef } from 'react'
import configData from '../../utils/constants/config.json'
import { useState } from 'react'
import axios from 'axios'

const AddBrandPopUp = ({
    handleOpenAddBrand,
    handleOpenSelectBrand,
    setSelectedBrand
}) => {

    const brandName = useRef()

    const [CreatedSuccessfully, setCreatedSuccessfully] = useState(false)
    const [errorText, setErrorText] = useState(false)

    const handleCreateBrand = () => {
        if (!brandName?.current?.value) {
            setErrorText(true)
            setTimeout(() => {
                setErrorText(false)
            }, 2500);
            return
        }

        axios({
            method: "post",
            url: `${configData.SERVER_URL}partner/product/add-product-brand`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                accesstoken: configData.ACCESSTOKEN,
                logintoken: localStorage.getItem("loginToken"),
            },
            data: {
                name: brandName.current.value
            }
        })
            .then(res => {
                if(res?.status == 200){
                    setSelectedBrand(res?.data?.data?.brand)
                    setCreatedSuccessfully(true)
                    setTimeout(() => {
                        handleOpenSelectBrand()
                        handleOpenAddBrand() 
                        setCreatedSuccessfully(false)
                    }, 1500);
                }
            })
            .catch(err => console.log(err))
    }

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50" style={{ zIndex: '999' }}>
            <div className="absolute inset-0 bg-gray-800 opacity-75"></div>
            <div className=" w-full sm:w-1/2 lg:w-1/3 z-50 bg-white p-8 rounded-lg shadow-lg mx-4">
                <h2 className="text-xl font-semibold mb-4">Add a brand</h2>

                <div className="border-b border-gray-200 w-full my-4"></div>

                {CreatedSuccessfully && <div className='p-3' style={{color: '#fff', backgroundColor: 'rgba(0, 255, 0, .3)', borderRadius: '8px' }}>
                    Brand Has Been Added Successfully
                </div>}
                {
                    errorText && <div className='p-3' style={{color: '#fff', backgroundColor: 'rgba(255, 0, 0, .3)', borderRadius: '8px' }}>
                    Please fill the name field 
                </div>
                }

                <label className="p-2 block w-full text-left" style={{ marginTop: "8px" }}>
                    <span className="block lg:w-1/2 text-sm font-medium text-slate-700">
                        Brand Name
                    </span>
                    <input
                        ref={brandName}
                        type="text"
                        name="name"
                        id="name"
                        // value={formValues.name}
                        // onChange={handleInputChangeInventory}
                        className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                        placeholder="Enter Brand Name"
                        required
                    />
                </label>

                <div className='flex justify-around items-center'>
                    <div
                        className='mt-4 btn border border-slate-300 font-medium hover:text-slate-300 text-slate-800 hover:bg-slate-800 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90'
                        onClick={()=>handleCreateBrand()}
                    >
                        Add
                    </div>
                    <div
                        className='mt-4 btn border border-slate-300 font-medium hover:text-slate-300 text-slate-800 hover:bg-slate-800 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90'
                        onClick={handleOpenAddBrand}
                    >
                        Close
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AddBrandPopUp