import axios from "axios"
import configData from '../../utils/constants/config.json'
import { useState } from "react"

const SelectBrandPopUp = ({
    header,
    handleOpenAddBrand,
    handleOpenSelectBrand,
    isOpen,
    brands,
    setSelectedBrand,
    setRerender
}) => {

    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)

    const handleDelete = (e, id) => {
        e.stopPropagation()
        axios({
            method: "delete",
            url: `${configData.SERVER_URL}partner/product/delete-product-brand/${id}`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                accesstoken: configData.ACCESSTOKEN,
                logintoken: localStorage.getItem("loginToken"),
            },

        }).then(res => {
            if (res?.data[200]) {
                setRerender(prev => !prev)
                setSuccess(true)
                setTimeout(() => {
                    setSuccess(false)
                }, 1500);
            } else {
                setError(true)
                setTimeout(() => {
                    setError(false)
                }, 1500);
            }

        })
            .catch(err => {
                setError(true)
                setTimeout(() => {
                    setError(false)
                }, 1500);
                console.log(err)
            })

    }
    return (
        <div className={`fixed inset-0 flex items-center overflow-hidden justify-center transition-opacity duration-300 ease-in-out z-[999] ${isOpen ? "opacity-100 z-[999] " : "opacity-0 pointer-events-none"}`} >
            <div className="absolute inset-0 bg-gray-800 opacity-75 "></div>
            <div className={`w-full sm:w-1/2 lg:w-1/3 z-50 bg-white p-8 rounded-lg shadow-lg mx-4`}>
                <h2 className="text-xl font-semibold mb-4">Select a {header}</h2>
                {/* line  */}

                {success && <div className='p-3' style={{ color: '#fff', backgroundColor: 'rgba(0, 255, 0, .3)', borderRadius: '8px' }}>
                    Brand Has Been Deleted Successfully
                </div>}

                {error && <div className='p-3' style={{ color: '#fff', backgroundColor: 'rgba(255, 0, 0, .3)', borderRadius: '8px' }}>
                    Something went wrong, try again...
                </div>}
                <div className="border-b border-gray-200 w-full my-4"></div>

                <div className="flex justify-center">
                    <span className=" cursor-pointer text-lg text-blue-700 flex items-center pb-4">
                        <i class="fa-plus flex justify-center items-center mr-2 border rounded-full bg-white w-5 h-5 "></i>
                        <p
                            className="hover:underline"
                            onClick={handleOpenAddBrand}
                        > Add a {header}</p>
                    </span>
                </div>

                {/* line  */}
                {/* <div className="border-b border-gray-200 w-full my-4"></div> */}

                <div className="max-h-[400px] overflow-y-auto">
                    {
                        brands && brands.length > 0 && brands?.map((brand) => {
                            return <div
                                className="hover:bg-gray-100 py-3 border-b border-t border-gray-200 px-2 flex justify-between items-center cursor-pointer"
                                onClick={() => {
                                    setSelectedBrand(brand)
                                    handleOpenSelectBrand()
                                }}
                            >
                                <span className="cursor-pointer text-lg text-black-700 flex items-center">
                                    {brand?.name}
                                </span>
                                <i className="fa fa-trash text-red-600"
                                    onClick={(e) => {
                                        handleDelete(e, brand?.id)
                                    }}
                                ></i>
                                {/* <span className="cursor-pointer text-sm text-black-700 flex items-center">
                            {numberProduct} Products
                        </span> */}

                            </div>
                        })
                    }
                </div>

                <div
                    className='mt-4 btn border border-slate-300 font-medium hover:text-slate-300 text-slate-800 hover:bg-slate-800 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90'
                    onClick={handleOpenSelectBrand}
                >
                    Close
                </div>
            </div>

        </div>
    )
}

export default SelectBrandPopUp