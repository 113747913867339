import React from 'react'
import swal from 'sweetalert'
import axios from 'axios'
import configData from '../../utils/constants/config.json'
import ImageResize from '../../components/ImageCropper/imageupload'

export default class AddNewBundle extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        step : 1,
        categories: [],
        genders: [],
        services: [],
        branches: [],
        subcategories : [],
        filteredSubcategories : [],
        selectedSubCategory : null,
        name : null,
        category: null,
        gender: null,
        service: null,
        branch: null,
        description: "",
        onlinebooking: true,
        selectedServices : [],
        onlineBooking: false,
        servicePricing: true,
        customPricing:  false,
        percentageDiscount: false,
        price: 0.00,
        image: null,
        imageURL: '',
        imageResizer: false,
        loader:false,
        termExclusive: false
    }
  }

  componentDidMount() {
    axios({
      method: 'get',
      url: configData.SERVER_URL + 'partner/bundle/createBundlePreLoad',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        accesstoken: configData.ACCESSTOKEN,
        logintoken: localStorage.getItem('loginToken'),
      },
    })
      .then((resp) => {
        if (parseInt(Object.keys(resp.data)[0]) === 200) {
            this.setState({
                categories: resp.data.categories,
                genders:  resp.data.genders,
                services:  resp.data.services.map(service => {
                  return {
                    ...service,
                    sessionCount: 1
                  }
                }),
                branches:  resp.data.branches,
                subcategories: resp.data.subcategories
            })
        }
      })
      .catch((err) => {
        swal({
          title: 'Server Not Responding',
          text: 'Please try again later',
          icon: 'warning',
          button: 'ok',
        })
        console.log(err)
      })
  }

  handleInputChange = (event) => {
    event.preventDefault()
    this.setState({
      [event.target.id]: event.target.value,
    })
  }

  handleBasicInfoNext = (event) => {
    event.preventDefault()
    console.log(this.state.branch)
    if (this.state.name === null) {
      document.getElementById('name').focus()
      return
    }
    if(!this.state.termExclusive){
      return 
    }

    if (this.state.category === null) {
      document.getElementById('category').focus()
      return
    }
    if (this.state.branch === null || this.state.branch === undefined || this.state.branch === "") {
      document.getElementById('branch').focus()
      return
    }
    if (this.state.gender === null) {
      document.getElementById('gender').focus()
      return
    }
    this.setState((prevState) => { return {step : prevState.step + 1}})
  }

  handlePricingNext = (event) => {
    event.preventDefault()
    if (this.state.imageURL === '') {
        swal({
            title: 'Image',
            text: "Please add iamge for your package",
            icon: 'warning',
            button: 'ok',
        })
        return
    }
    this.setState((prevState) => { return {step : prevState.step + 1}})
  }

    handleAddService = (event) => {
    event.preventDefault()
    if(event.currentTarget.value !== ""){
        this.state.services.forEach(service => {
            if(service.id == event.currentTarget.value){
                this.setState({
                    selectedServices : [...this.state.selectedServices,service],
                    selectedService: service
                })
            }
        })
    }
    }

    handleSelectServiceSessionCount = (event,id) => {
      event.preventDefault()
      if(event.currentTarget.value !== ""){
          this.state.selectedServices.forEach(service => {
              if(service.id == id){
                service.sessionCount = event.currentTarget.value
                this.setState({
                    selectedServices : [...this.state.selectedServices],
                })
              }
          })
      }
    }

    handleRemoveService = (event) => {
        event.preventDefault()
        this.setState({
            selectedServices: this.state.selectedServices.filter(function(service) { 
                return service.id != event.currentTarget.id
            })
        })
    }

    handleBookingNext = (event) => {
        event.preventDefault()
        // if(this.state.selectedServices.length < 2){
        //     swal({
        //         title: "Service Information",
        //         text: "Please select at least two service for bundle",
        //         icon: "warning",
        //         button: "ok",
        //     })
        //     return
        // }
        this.setState((prevState) => { return {step : prevState.step + 1}})
    }

    handleConfirmBundle = (event) => {
    event.preventDefault()
    console.log(this.state)
    
    var dataval = JSON.stringify({
      name: this.state.name,
      categoryId: parseInt(this.state.category),
      branchId: parseInt(this.state.branch),
      genderId: parseInt(this.state.gender),
      description: this.state.description,
      onlinebooking: this.state.onlinebooking,
      servicePricing: this.state.servicePricing,
      customPricing: this.state.customPricing,
      percentageDiscount: this.state.percentageDiscount,
      price: parseFloat(this.state.price),
      image: this.state.imageURL,
      services: this.state.selectedServices.map(service => {
        return {
          id: service.id,
          sessionCount: parseInt(service.sessionCount)
        }
      })
    })

    var config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: configData.SERVER_URL + 'partner/bundle/createBundle',
      headers: {
        'Content-Type': 'application/json',
        accesstoken: configData.ACCESSTOKEN,
        logintoken: localStorage.getItem('loginToken'),
      },
      data: dataval,
    }

    axios(config)
      .then((resp) => {
        if (parseInt(Object.keys(resp.data)[0]) === 200) {
            window.location.replace('/services/bundlelist')
        } else {
          swal({
            title: 'Package Information',
            text: resp.data[Object.keys(resp.data)[0]],
            icon: 'warning',
            button: 'ok',
          })
        }
      })
      .catch((err) => {
        swal({
          title: 'Server Not Responding',
          text: 'Please try again later',
          icon: 'warning',
          button: 'ok',
        })
        console.log(err)
      })
    }

    imageAdd = (file) => {
        var url = URL.createObjectURL(file)
        this.setState({
            image: file,
            imageURL: url,
        })
    }

    saveImageAdded = (e) => {
      e.preventDefault()
      this.setState({
          imageResizer : false
      })
      this.setState({loader :true})
      var bodyFormData = new FormData()
      bodyFormData.append('image', this.state.image)
      axios({
          method: "post",
          url: configData.SERVER_URL + 'partner/bundle/uploadImage',
          data: bodyFormData,
          headers: { 
              "Content-Type": "multipart/form-data",
              "accesstoken" : configData.ACCESSTOKEN,
              "logintoken"  : localStorage.getItem('loginToken')
          },
      }).then(resp => {
          this.setState({loader :false})
           if(resp.data.response === 200){
              this.setState({ 
                  imageResizer : false , 
                  imageURL: resp.data.data.url[0],
                  image : null
              })
          }else{
              swal({
                  title: "Save Image",
                  text: resp.data.message,
                  icon: "warning",
                  button: "ok",
              })
          }
      }).catch(err => {
          swal({
              title: "Server Not Responding",
              text: "Please try again later",
              icon: "warning",
              button: "ok",
          })
          console.log(err)
      })
  }

    imageModalClose = (e) => {
    this.setState({
            imageResizer : false
        })
    }

    changeImage = (file) => {
        var url = URL.createObjectURL(file)
        this.setState({
            image: file,
            imageURL: url,
        })
    }

render() {
    const { count, inputsNew } = this.state
    return (
      <main className="main-content px-[var(--margin-x)] pb-[150px]">
        <div className="flex items-center space-x-4 py-5 lg:py-6">
          <h2 className="text-xl font-medium text-slate-800 dark:text-navy-50 lg:text-2xl">
            Add New Package
          </h2>
          <div className="hidden h-full py-1 sm:flex">
            <div className="h-full w-px bg-slate-300 dark:bg-navy-600"></div>
          </div>
          <ul className="hidden flex-wrap items-center space-x-2 sm:flex">
            <li className="flex items-center space-x-2">
              <a
                className="text-primary transition-colors hover:text-primary-focus dark:text-accent-light dark:hover:text-accent"
                href="/settings"
              >
                Settings
              </a>
              <svg
                x-ignore
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </li>
            <li>Add New Package</li>
          </ul>
        </div>

        <div
          className={
            this.state.step == 1
              ? 'grid grid-cols-12 gap-4 sm:gap-5 lg:gap-6'
              : 'grid grid-cols-12 gap-4 sm:gap-5 lg:gap-6 hide'
          }
        >
          <div className="col-span-12 grid lg:col-span-4 lg:place-items-center">
            <div>
              <ol className="steps is-vertical line-space [--size:2.75rem] [--line:.5rem]">
                <li className="step space-x-4 pb-12 before:bg-slate-200 dark:before:bg-navy-500">
                  <div className="step-header mask is-hexagon bg-primary text-white dark:bg-accent">
                    <i className="fa-solid fa-layer-group text-base"></i>
                  </div>
                  <div className="text-left">
                    <p className="text-xs text-slate-400 dark:text-navy-300">
                      Step 1
                    </p>
                    <h3 className="text-base font-medium text-primary dark:text-accent-light">
                      Basic Info
                    </h3>
                  </div>
                </li>
                <li className="step space-x-4 pb-12 before:bg-slate-200 dark:before:bg-navy-500">
                  <div className="step-header mask is-hexagon bg-slate-200 text-slate-500 dark:bg-navy-500 dark:text-navy-100">
                    <i className="fa-solid fa-ticket text-base"></i>
                  </div>
                  <div className="text-left">
                    <p className="text-xs text-slate-400 dark:text-navy-300">
                      Step 2
                    </p>
                    <h3 className="text-base font-medium">Booking</h3>
                  </div>
                </li>
                <li className="step space-x-4 pb-12 before:bg-slate-200 dark:before:bg-navy-500">
                  <div className="step-header mask is-hexagon bg-slate-200 text-slate-500 dark:bg-navy-500 dark:text-navy-100">
                    <i className="fa-solid fa-tag text-base"></i>
                  </div>
                  <div className="text-left">
                    <p className="text-xs text-slate-400 dark:text-navy-300">
                      Step 3
                    </p>
                    <h3 className="text-base font-medium">Pricing</h3>
                  </div>
                </li>
                <li className="step space-x-4 before:bg-slate-200 dark:before:bg-navy-500">
                  <div className="step-header mask is-hexagon bg-slate-200 text-slate-500 dark:bg-navy-500 dark:text-navy-100">
                    <i className="fa-solid fa-check text-base"></i>
                  </div>
                  <div className="text-left">
                    <p className="text-xs text-slate-400 dark:text-navy-300">
                      Step 4
                    </p>
                    <h3 className="text-base font-medium">Confirm</h3>
                  </div>
                </li>
              </ol>
            </div>
          </div>
          <div className="col-span-12 grid lg:col-span-8">
            <div className="card">
              <div className="border-b border-slate-200 p-4 dark:border-navy-500 sm:px-5">
                <div className="flex items-center space-x-2">
                  <div className="flex h-7 w-7 items-center justify-center rounded-lg bg-primary/10 p-1 text-primary dark:bg-accent-light/10 dark:text-accent-light">
                    <i className="fa-solid fa-layer-group"></i>
                  </div>
                  <h4 className="text-lg font-medium text-slate-700 dark:text-navy-100">
                    Basic Information
                  </h4>
                </div>
              </div>
              <div className="space-y-4 p-4 sm:p-5 text-left">
                <label className="block">
                  <span>Package Name*</span>
                  <input
                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                    placeholder="Enter Your Service Name"
                    type="text"
                    id="name"
                    onChange={this.handleInputChange}
                  />
                </label>

                <div className="grid grid-cols-2 gap-4">
                    <label className="block">
                      <span>Package Category*</span>
                      <select
                        className="mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent  px-3 py-2"
                        id="category"
                        onChange={this.handleInputChange}
                      >
                        <option value="" hidden>
                        Package Category
                        </option>
                        {this.state.categories.map((category) => (
                          <option value={category.id}>{category.name}</option>
                        ))}
                      </select>
                    </label>
                    <label className="block">
                      <span>Package Branch*</span>
                      <select
                        className="mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent  px-3 py-2"
                        id="branch"
                        onChange={this.handleInputChange}
                      >
                        <option value="" hidden>
                         Select Branch
                        </option>
                        {this.state.branches
                          .map((branch) => (
                            <option value={branch.id}>
                              {branch.name}
                            </option>
                          ))}
                      </select>
                    </label>
                </div>
                <label className="block">
                <span>Package Available for*</span>
                <select
                    className="mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent  px-3 py-2"
                    id="gender"
                    onChange={this.handleInputChange}
                >
                    <option value="" hidden>
                    Select Option
                    </option>
                    {this.state.genders.map((gender) => (
                    <option value={gender.id}>{gender.name}</option>
                    ))}
                </select>
                </label>
                <label className="block">
                <span>Description</span>
                <textarea
                    rows="4"
                    
                    placeholder="Enter Aftercare Description"
                    className="form-textarea  w-full resize-none rounded-lg border border-slate-300 bg-transparent p-2.5 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                    id="description"
                    onChange={this.handleInputChange}
                ></textarea>
                <div className='flex items-center mt-3'>
                    <input
                      class="form-checkbox is-outline h-5 w-5 rounded-full border-slate-400/70 before:bg-primary checked:border-primary hover:border-primary focus:border-primary dark:border-navy-400 dark:before:bg-accent dark:checked:border-accent dark:hover:border-accent dark:focus:border-accent"
                      type="checkbox"
                      id='termExclusive'
                      onChange={() => {

                        this.setState(({ termExclusive }) => ({
                          termExclusive: !termExclusive,
                        }))

                      }}
                    />
                    <label for="termExclusive" className='mx-1'>This package is exclusive for DrClinica</label>
                  </div>
                  {!this.state.termExclusive && <p className='text-red-500 text-start'>Please accept this term.</p>}
                  
                </label>

                <div className="flex justify-center space-x-2 pt-4">
                  <button
                    className="btn space-x-2 bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                    onClick={this.handleBasicInfoNext}
                  >
                    <span>Next</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            this.state.step == 2
              ? 'grid grid-cols-12 gap-4 sm:gap-5 lg:gap-6'
              : 'grid grid-cols-12 gap-4 sm:gap-5 lg:gap-6 hide'
          }
        >
          <div className="col-span-12 grid lg:col-span-4 lg:place-items-center">
            <div>
              <ol className="steps is-vertical line-space [--size:2.75rem] [--line:.5rem]">
                <li className="step space-x-4 pb-12 before:bg-primary dark:before:bg-navy-500">
                  <div className="step-header mask is-hexagon bg-primary text-white dark:bg-accent">
                    <i className="fa-solid fa-layer-group text-base"></i>
                  </div>
                  <div className="text-left">
                    <p className="text-xs text-slate-400 dark:text-navy-300">
                      Step 1
                    </p>
                    <h3 className="text-base font-medium text-primary dark:text-accent-light">
                      Basic Info
                    </h3>
                  </div>
                </li>
                <li className="step space-x-4 pb-12 before:bg-slate-200 dark:before:bg-navy-500">
                  <div className="step-header mask is-hexagon bg-primary text-white dark:bg-accent">
                    <i className="fa-solid fa-ticket text-base"></i>
                  </div>
                  <div className="text-left">
                    <p className="text-xs text-slate-400 dark:text-navy-300">
                      Step 2
                    </p>
                    <h3 className="text-base font-medium">Booking</h3>
                  </div>
                </li>
                <li className="step space-x-4 pb-12 before:bg-slate-200 dark:before:bg-navy-500">
                  <div className="step-header mask is-hexagon bg-slate-200 text-slate-500 dark:bg-navy-500 dark:text-navy-100">
                    <i className="fa-solid fa-tag text-base"></i>
                  </div>
                  <div className="text-left">
                    <p className="text-xs text-slate-400 dark:text-navy-300">
                      Step 3
                    </p>
                    <h3 className="text-base font-medium">Pricing</h3>
                  </div>
                </li>
                <li className="step space-x-4 before:bg-slate-200 dark:before:bg-navy-500">
                  <div className="step-header mask is-hexagon bg-slate-200 text-slate-500 dark:bg-navy-500 dark:text-navy-100">
                    <i className="fa-solid fa-check text-base"></i>
                  </div>
                  <div className="text-left">
                    <p className="text-xs text-slate-400 dark:text-navy-300">
                      Step 4
                    </p>
                    <h3 className="text-base font-medium">Confirm</h3>
                  </div>
                </li>
              </ol>
            </div>
          </div>
          <div className="col-span-12 grid lg:col-span-8">
            <div className="card">
              <div className="border-b border-slate-200 p-4 dark:border-navy-500 sm:px-5">
                <div className="flex items-center space-x-2">
                  <div className="flex h-7 w-7 items-center justify-center rounded-lg bg-primary/10 p-1 text-primary dark:bg-accent-light/10 dark:text-accent-light">
                    <i className="fa-solid fa-ticket"></i>
                  </div>
                  <h4 className="text-lg font-medium text-slate-700 dark:text-navy-100">
                    Booking
                  </h4>
                </div>
              </div>
              <div className="space-y-4 p-4 sm:p-5 text-left">
                <div class="grid grid-cols-1 gap-4 sm:grid-cols-1">
                    {/* <label class="inline-flex items-center space-x-2">
                        Enable online bookings, choose who the service is available for and add a short description.
                    </label> */}
                    <label class="inline-flex items-center space-x-2">
                        <input class="form-switch h-5 w-10 rounded-full bg-slate-300 before:rounded-full before:bg-slate-50 checked:bg-primary checked:before:bg-white dark:bg-navy-900 dark:before:bg-navy-300 dark:checked:bg-accent dark:checked:before:bg-white" type="checkbox" value={this.state.onlinebooking} defaultChecked={true} id="onlinebooking" 
                        onChange={()=>{
                                this.setState(({ onlinebooking }) => ({ onlinebooking: !onlinebooking }))
                        }}/>
                        <span>Enable Online Booking.</span>
                    </label>
                </div>
                <div class="my-3 mx-4 h-px bg-slate-200 dark:bg-navy-500"></div>
                <div className="grid grid-cols-2 gap-4">
                    <label className="block">
                    <span>Category*</span>
                    <select
                        className="mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent  px-3 py-2"
                        id="category"
                        onChange={(e) => {
                            let subcategories = this.state.subcategories.filter((subcategory) => subcategory.category == e.currentTarget.value)
                            console.log(subcategories,e.currentTarget.value)
                            this.setState({
                                filteredSubcategories : subcategories,
                                selectedSubCategory : subcategories[0].id,
                                selectedService : ""
                            })
                        }}
                    >
                        <option value="" hidden>Select Category</option>
                        {this.state.categories.map((category) => (
                            <option value={category.id}>{category.name}</option>
                        ))}
                    </select>
                    </label>
                    <label className="block">
                    <span>Select Sub Category*</span>
                    <select
                        className="mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent  px-3 py-2"
                        id="subcategory"
                        onChange={(e) => {
                            console.log(e.currentTarget.value)
                            this.setState({
                                selectedSubCategory : e.currentTarget.value,
                                selectedService : ""
                            })
                        }}
                    >
                        <option value="" hidden selected>Select Sub Category</option>
                        {this.state.filteredSubcategories.map((subcategory) => (
                            <option value={subcategory.id}>{subcategory.name}</option>
                        ))}
                    </select>
                    </label>
                </div>
                <label class="block">
                    <span>Select Services*</span>
                    <select class="mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent  px-3 py-2" id="selectedServices" value={this.state.selectedService} onChange={this.handleAddService}>
                        <option value="">Select Option</option>
                        {this.state.services.filter(filterService => filterService.subcategory == this.state.selectedSubCategory).map((service) => ( 
                            <option value={service.id}>{service.name}</option>
                        ))}
                    </select>
                </label>
                <h6 class="text-lg font-medium text-slate-700 dark:text-navy-100">Selected Services</h6>
            
                <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-5 lg:grid-cols-3 lg:gap-6 xl:grid-cols-4">
                    {this.state.selectedServices.map((service) => (
                        <div class="rounded-lg bg-gradient-to-r from-sky-400 to-blue-600 p-1  float-right" id={service.id}>
                            <div class="flex items-center justify-between text-white">
                                <h2 class="font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100 lg:text-base"></h2>
                                <button class="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25" id={service.id} onClick={this.handleRemoveService}>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4.5 w-4.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                    </svg>
                                </button>
                            </div>
                            
                            <div class="flex flex-col items-center p-4 text-center sm:p-5">
                                <div class="avatar h-20 w-20">
                                    <img class="rounded-full" src={service.category_categoryToservice.image} alt={service.name} />
                                </div>
                                <h3 class="pt-3 text-lg font-medium text-white">{service.name}</h3>
                                <input
                                    className="form-input w-full border rounded-lg border-slate-300 bg-slate-200 px-3 py-2"
                                    placeholder="Session Count"
                                    type="number"
                                    min={1}
                                    max={20}
                                    id="sessionCount"
                                    value={service.sessionCount}
                                    onChange={(e) => this.handleSelectServiceSessionCount(e,service.id)}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                </div>
                <div className="space-y-4 p-4 sm:p-5">

                    <div className="flex justify-center space-x-2 pt-4">
                    <button
                        className="btn space-x-2 bg-slate-150 font-medium text-slate-800 hover:bg-slate-200 focus:bg-slate-200 active:bg-slate-200/80 dark:bg-navy-500 dark:text-navy-50 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                        onClick={() => {
                            this.setState((prevState) => { return {step : prevState.step - 1}})
                        }}>
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        >
                        <path
                            fill-rule="evenodd"
                            d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                            clip-rule="evenodd"
                        />
                        </svg>
                        <span>Prev</span>
                    </button>
                    <button
                        className="btn space-x-2 bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                        onClick={(e) => {
                            this.handleBookingNext(e)
                        }}
                    >
                        <span>Next</span>
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        >
                        <path
                            fill-rule="evenodd"
                            d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                        />
                        </svg>
                    </button>
                    </div>
                </div>
            </div>
          </div>
        </div>

        <div
          className={
            this.state.step == 3
              ? 'grid grid-cols-12 gap-4 sm:gap-5 lg:gap-6'
              : 'grid grid-cols-12 gap-4 sm:gap-5 lg:gap-6 hide'
          }
        >
          <div className="col-span-12 grid lg:col-span-4 lg:place-items-center">
            <div>
              <ol className="steps is-vertical line-space [--size:2.75rem] [--line:.5rem]">
                <li className="step space-x-4 pb-12 before:bg-primary dark:before:bg-navy-500">
                  <div className="step-header mask is-hexagon bg-primary text-white dark:bg-accent">
                    <i className="fa-solid fa-layer-group text-base"></i>
                  </div>
                  <div className="text-left">
                    <p className="text-xs text-slate-400 dark:text-navy-300">
                      Step 1
                    </p>
                    <h3 className="text-base font-medium text-primary dark:text-accent-light">
                      Basic Info
                    </h3>
                  </div>
                </li>
                <li className="step space-x-4 pb-12 before:bg-primary dark:before:bg-navy-500">
                  <div className="step-header mask is-hexagon bg-primary text-white dark:bg-accent">
                    <i className="fa-solid fa-ticket text-base"></i>
                  </div>
                  <div className="text-left">
                    <p className="text-xs text-slate-400 dark:text-navy-300">
                      Step 2
                    </p>
                    <h3 className="text-base font-medium">Booking</h3>
                  </div>
                </li>
                <li className="step space-x-4 pb-12 before:bg-slate-200 dark:before:bg-navy-500">
                  <div className="step-header mask is-hexagon bg-slate-200 text-slate-500 dark:bg-navy-500 dark:text-navy-100">
                    <i className="fa-solid fa-tag text-base"></i>
                  </div>
                  <div className="text-left">
                    <p className="text-xs text-slate-400 dark:text-navy-300">
                      Step 3
                    </p>
                    <h3 className="text-base font-medium">Pricing</h3>
                  </div>
                </li>
                <li className="step space-x-4 before:bg-slate-200 dark:before:bg-navy-500">
                  <div className="step-header mask is-hexagon bg-slate-200 text-slate-500 dark:bg-navy-500 dark:text-navy-100">
                    <i className="fa-solid fa-check text-base"></i>
                  </div>
                  <div className="text-left">
                    <p className="text-xs text-slate-400 dark:text-navy-300">
                      Step 4
                    </p>
                    <h3 className="text-base font-medium">Confirm</h3>
                  </div>
                </li>
              </ol>
            </div>
          </div>

          <div className="col-span-12 grid lg:col-span-8">
            <div className="card">
              <div className="border-b border-slate-200 p-4 dark:border-navy-500 sm:px-5">
                <div className="flex items-center space-x-2">
                  <div className="flex h-7 w-7 items-center justify-center rounded-lg bg-primary/10 p-1 text-primary dark:bg-accent-light/10 dark:text-accent-light">
                    <i className="fa-solid fa-users"></i>
                  </div>
                  <h4 className="text-lg font-medium text-slate-700 dark:text-navy-100">
                    Pricing
                  </h4>
                </div> 
              </div>
              <div className="space-y-4 p-4 sm:p-5 text-left">
                <label class="block">
                    <span>Pricing Type*</span>
                    <select class="mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent  px-3 py-2"
                        onChange={(e) => {
                            if(e.currentTarget.value == 1){
                                this.setState({
                                    servicePricing: true,
                                    customPricing:  false,
                                    percentageDiscount: false,
                                })
                            }else{
                                if(e.currentTarget.value == 2){
                                    this.setState({
                                        servicePricing: false,
                                        customPricing:  true,
                                        percentageDiscount: false,
                                    })
                                }else{
                                    this.setState({
                                        servicePricing: false,
                                        customPricing:  false,
                                        percentageDiscount: true,
                                    })
                                }
                            }
                        }}>
                        <option value={1}>Services Price</option>
                        <option value={2}>Custom Price</option>
                        <option value={3}>Percentage Discount</option>
                    </select>
                </label>
                <label className="block">Retail Price*</label>
                    <label className="flex -space-x-px">
                    <div className="flex items-center justify-center rounded-l-lg border border-slate-300 px-3.5 font-inter dark:border-navy-450">
                        <span>{this.state.percentageDiscount ? `%` : 'AED'}</span>
                    </div>
                    <input
                        className="form-input w-full border rounded-r-lg border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:z-10 hover:border-slate-400 focus:z-10 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                        placeholder="Enter Price"
                        type="number"
                        id="price"
                        disabled={this.state.servicePricing}
                        value={this.state.price}
                        onChange={this.handleInputChange}
                    />
                    {/* <div className="flex items-center justify-center rounded-r-lg border border-slate-300 px-3.5 font-inter dark:border-navy-450">
                        <span>.00</span>
                    </div> */}
                </label>
                { this.state.imageURL !== "" ?
                    <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-5 lg:grid-cols-3 lg:gap-6">
                        <div class="card">
                            <div class="px-2 pb-2">
                                <img src={this.state.imageURL} class="h-48 w-full rounded-lg object-cover object-center" alt="image"/>
                            </div>
                        </div>
                    </div>
                    : <></>
                }
                <div class="my-1 h-px bg-slate-150 dark:bg-navy-500"></div>
                <div class="inline-space mt-5 flex flex-wrap">
                    <label class="btn relative bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90">
                        <button  class="flex items-center space-x-2" alt="avatar" onClick={() => {
                                this.setState({
                                imageResizer : true
                            })  }}>
                            <i class="fa-solid fa-cloud-arrow-up text-base"></i>
                            <span>Add New Image</span>
                        </button> 
                    </label>
                </div>
              </div>
              <div className="flex justify-center space-x-2 pt-4">
                  <button
                    className="btn space-x-2 bg-slate-150 font-medium text-slate-800 hover:bg-slate-200 focus:bg-slate-200 active:bg-slate-200/80 dark:bg-navy-500 dark:text-navy-50 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                    onClick={() => {
                        this.setState((prevState) => { return {step : prevState.step - 1}})
                    }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span>Prev</span>
                  </button>
                  <button
                    className="btn space-x-2 bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                    onClick={(e) => {
                        this.handlePricingNext(e)
                    }}
                  >
                    <span>Next</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
            </div>
          </div>
        </div>
        
        <div
          className={
            this.state.step == 4
              ? 'grid grid-cols-12 gap-4 sm:gap-5 lg:gap-6'
              : 'grid grid-cols-12 gap-4 sm:gap-5 lg:gap-6 hide'
          }
        >
          <div className="col-span-12 grid lg:col-span-4 lg:place-items-center">
            <div>
              <ol className="steps is-vertical line-space [--size:2.75rem] [--line:.5rem]">
                <li className="step space-x-4 pb-12 before:bg-primary dark:before:bg-navy-500">
                  <div className="step-header mask is-hexagon bg-primary text-white dark:bg-accent">
                    <i className="fa-solid fa-layer-group text-base"></i>
                  </div>
                  <div className="text-left">
                    <p className="text-xs text-slate-400 dark:text-navy-300">
                      Step 1
                    </p>
                    <h3 className="text-base font-medium text-primary dark:text-accent-light">
                      Basic Info
                    </h3>
                  </div>
                </li>
                <li className="step space-x-4 pb-12 before:bg-primary dark:before:bg-navy-500">
                  <div className="step-header mask is-hexagon bg-primary text-white dark:bg-accent">
                    <i className="fa-solid fa-ticket text-base"></i>
                  </div>
                  <div className="text-left">
                    <p className="text-xs text-slate-400 dark:text-navy-300">
                      Step 2
                    </p>
                    <h3 className="text-base font-medium">Booking</h3>
                  </div>
                </li>
                <li className="step space-x-4 pb-12 before:bg-slate-200 dark:before:bg-navy-500">
                  <div className="step-header mask is-hexagon bg-primary text-white dark:bg-accent">
                    <i className="fa-solid fa-tag text-base"></i>
                  </div>
                  <div className="text-left">
                    <p className="text-xs text-slate-400 dark:text-navy-300">
                      Step 3
                    </p>
                    <h3 className="text-base font-medium">Pricing</h3>
                  </div>
                </li>
                <li className="step space-x-4 before:bg-slate-200 dark:before:bg-navy-500">
                  <div className="step-header mask is-hexagon bg-slate-200 text-slate-500 dark:bg-navy-500 dark:text-navy-100">
                    <i className="fa-solid fa-check text-base"></i>
                  </div>
                  <div className="text-left">
                    <p className="text-xs text-slate-400 dark:text-navy-300">
                      Step 4
                    </p>
                    <h3 className="text-base font-medium">Confirm</h3>
                  </div>
                </li>
              </ol>
            </div>
          </div>
          {/* Pricing container */}
          <div className="col-span-12 grid lg:col-span-8">
            <div className="card">
              <div className="border-b border-slate-200 p-4 dark:border-navy-500 sm:px-5">
                <div className="flex items-center space-x-2">
                  <div className="flex h-7 w-7 items-center justify-center rounded-lg bg-primary/10 p-1 text-primary dark:bg-accent-light/10 dark:text-accent-light">
                    <i className="fa-solid fa-tag"></i>
                  </div>
                  <h4 className="text-lg font-medium text-slate-700 dark:text-navy-100">
                    Confirm
                  </h4>
                </div>
              </div>
              {/* pricing tabs */}
               <div className="space-y-4 p-4 sm:p-5">
               <label className="block">
                  <span>Package Name*</span>
                  <input
                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                    placeholder="Enter Your Service Name"
                    type="text"
                    id="name"
                    value={this.state.name}
                    readOnly={true}
                  />
                </label>

                <div className="grid grid-cols-2 gap-4">
                    <label className="block">
                      <span>Package Category*</span>
                      <select
                        className="mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent  px-3 py-2"
                        id="category"
                        
                      >
                        {this.state.categories.filter(category => category.id == this.state.category).map((category) => (
                          <option value={category.id}>{category.name}</option>
                        ))}
                      </select>
                    </label>
                    <label className="block">
                      <span>Package Branch*</span>
                      <select
                        className="mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent  px-3 py-2"
                        id="branch"
                        readOnly={true}
                      >
                        {this.state.branches.filter(branch => branch.id == this.state.branch)
                          .map((branch) => (
                            <option value={branch.id}>
                              {branch.name}
                            </option>
                          ))}
                      </select>
                    </label>
                </div>
                <label className="block">
                <span>Package Available for*</span>
                <select
                    className="mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent  px-3 py-2"
                    id="gender"
                    readOnly={true}
                >
                    {this.state.genders.filter(gender => gender.id == this.state.gender).map((gender) => (
                    <option value={gender.id}>{gender.name}</option>
                    ))}
                </select>
                </label>
                <label className="block">
                <span>Description</span>
                <textarea
                    rows="4"
                    
                    placeholder="Enter Aftercare Description"
                    className="form-textarea  w-full resize-none rounded-lg border border-slate-300 bg-transparent p-2.5 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                    id="description"
                    readOnly={true}
                    value={this.state.description}
                ></textarea>
                </label>
                <div class="grid grid-cols-1 gap-4 sm:grid-cols-1">
                    {/* <label class="inline-flex items-center space-x-2">
                        Enable online bookings, choose who the service is available for and add a short description.
                    </label> */}
                    <label class="inline-flex items-center space-x-2">
                        <input class="form-switch h-5 w-10 rounded-full bg-slate-300 before:rounded-full before:bg-slate-50 checked:bg-primary checked:before:bg-white dark:bg-navy-900 dark:before:bg-navy-300 dark:checked:bg-accent dark:checked:before:bg-white" type="checkbox" value={this.state.onlinebooking} defaultChecked={true} id="onlinebooking" 
                        readOnly={true}/>
                        <span>Enable Online Booking.</span>
                    </label>
                </div>
                <h6 class="text-lg font-medium text-slate-700 dark:text-navy-100">Selected Services</h6>
            
                <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-5 lg:grid-cols-3 lg:gap-6 xl:grid-cols-4">
                    {this.state.selectedServices.map((service) => (
                        <div class="rounded-lg bg-gradient-to-r from-sky-400 to-blue-600 p-1 text-white float-right" id={service.id}>
                            <div class="flex items-center justify-between">
                                <h2 class="font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100 lg:text-base"></h2>
                                <button class="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25" id={service.id} onClick={this.handleRemoveService}>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4.5 w-4.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                    </svg>
                                </button>
                            </div>
                            
                            <div class="flex flex-col items-center p-4 text-center sm:p-5">
                                <div class="avatar h-20 w-20">
                                    <img class="rounded-full" src={ service.category_categoryToservice.image} alt={service.name} />
                                </div>
                                <h3 class="pt-3 text-lg font-medium">{service.name}</h3>
                            </div>
                        </div>
                    ))}
                </div>
                <label class="block">
                    <span>Pricing Type*</span>
                    <select class="mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent  px-3 py-2">
                        
                        {this.state.servicePricing ?
                            <option value={1}>Services Price</option>
                            : 
                            this.state.customPricing ?
                                <option value={2}>Custom Price</option>
                                :
                                <option value={3}>Percentage Discount</option>
                        }
                    </select>
                </label>
                <label className="block">Retail Price*</label>
                    <label className="flex -space-x-px">
                    <div className="flex items-center justify-center rounded-l-lg border border-slate-300 px-3.5 font-inter dark:border-navy-450">
                        <span>{this.state.percentageDiscount ? `%` : 'AED'}</span>
                    </div>
                    <input
                        className="form-input w-full border rounded-r-lg border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:z-10 hover:border-slate-400 focus:z-10 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                        placeholder="Enter Price"
                        type="number"
                        id="price"
                        disabled={this.state.servicePricing}
                        value={this.state.price}
                        readOnly={true}
                    />
                    {/* <div className="flex items-center justify-center rounded-r-lg border border-slate-300 px-3.5 font-inter dark:border-navy-450">
                        <span>.00</span>
                    </div> */}
                </label>
                { this.state.imageURL !== "" ?
                    <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-5 lg:grid-cols-3 lg:gap-6">
                        <div class="card">
                            <div class="px-2 pb-2">
                                <img src={this.state.imageURL} class="h-48 w-full rounded-lg object-cover object-center" alt="image"/>
                            </div>
                        </div>
                    </div>
                    : <></>
                }

               <div className="flex justify-center space-x-2 pt-4">
                  <button
                    className="btn space-x-2 bg-slate-150 font-medium text-slate-800 hover:bg-slate-200 focus:bg-slate-200 active:bg-slate-200/80 dark:bg-navy-500 dark:text-navy-50 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                    onClick={() => {
                        this.setState((prevState) => { return {step : prevState.step - 1}})
                    }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span>Prev</span>
                  </button>
                  <button
                    className="btn space-x-2 bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                    onClick={this.handleConfirmBundle}
                  >
                    <span>Confirm</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              {/* end of pricing tab one  */}
            </div>
          </div>
        </div>
        { this.state.imageResizer ?
                <ImageResize loader={this.state.loader} aspectRatio={1.5} imageModalClose={()=>this.imageModalClose(false)} changeImage={this.imageAdd} saveImageAdded={this.saveImageAdded}/> : <></>
            }

      </main>
    )
  }
}
