import React, { useState, useEffect } from 'react'

import CalenderPopUp from '../CalenderPopUp/CalenderPopUp';
import TimeSlotInput from '../timeSlotsInput/TimeSlotsInput';
import ToastWarning from '../toastWarning/ToastWarning';
import ToastSuccess from '../toastSuccess/ToastSuccess';

const PopUpReschedule = ({
    selectedDate,
    setShowPopUpReschedule,
    setSelectedDate,
    slots,
    selectedSlot,
    setSelectedSlot,
    startDate,
    endDate,
    slotMessage,
    rescheduleAppointmentFunction,
    showToastSlotError,
    showToastRescheduleError,
    messageReschedule,
    messageRescheduleSuccess
}) => {

    return (
        <div className="fixed inset-0 flex items-start  justify-center z-50" style={{ zIndex: '999' }}>
            <div className="absolute inset-0 bg-gray-800 opacity-75"></div>
            <div className="mt-20 w-full sm:w-1/2 lg:w-1/3 z-50 bg-white p-8 rounded-lg shadow-lg mx-4">
                <h2 className="text-xl font-semibold mb-4">Reschedule Now</h2>

                {showToastSlotError && <ToastWarning message='Must Select Time Slot' />}
                {showToastRescheduleError && <ToastWarning message={messageReschedule} />}
                {messageRescheduleSuccess && <ToastSuccess message={"your Appointment Rescheduled Successfully"} />}

                <div className="p-5 bg-gray-200 rounded-lg mt-2">


                    <CalenderPopUp
                        // renderInput={<RenderInput />}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        minDate={startDate}
                        maxDate={new Date(endDate)}
                    />
                    <TimeSlotInput
                        classNameTopMines={'topMines-150px'}
                        slots={slots}
                        selectedSlot={selectedSlot}
                        setSelectedSlot={setSelectedSlot}
                        slotMessage={slotMessage}
                    />
                </div>
                <div className="w-full flex justify-between mt-4">
                    <button className="btn p-3 border border-primary font-medium text-primary hover:bg-primary hover:text-white focus:bg-primary focus:text-white active:bg-primary/90  "
                        onClick={() => rescheduleAppointmentFunction()}
                    >Reschedule Now</button>
                    <button className="btn p-3 border border-red-500 hover:border-primary font-medium text-white bg-red-500 hover:text-black hover:bg-white  "
                        onClick={() => {
                            setShowPopUpReschedule((prev) => !prev)
                            if (
                                document
                                    .getElementById('pooper-aa')
                                    .classList.contains('show')
                            ) {
                                document
                                    .getElementById('pooper-aa')
                                    .classList.remove('show')
                            } else {
                                document
                                    .getElementById('pooper-aa')
                                    .classList.add('show')
                            }

                        }}
                    >Cancel</button>
                    {/* <div
                // href="##"
                className=" w-3/6 sm:w-3/6 lg:w-2/6 text-center  py-2.5 cursor-pointer rounded-xl bg-primary-6000 text-neutral-50 focus:outline-none"
                onClick={() => setOpenBookingPopUp(false)}
            >
                Close
            </div> */}
                </div>
            </div>
        </div>
    )
}

export default PopUpReschedule