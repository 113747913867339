import React, { useEffect, useRef, useState } from 'react'
import SelectBrandPopUp from '../../../components/selectBrand/SelectBrandPopUp';
import AddBrandPopUp from '../../../components/addBrand/AddBrandPopUp';
import SelectCategoryProject from '../../../components/selectCategoryProject/SelectCategoryProject';
import AddCategoryProduct from '../../../components/addCategoryProduct/AddCategoryProduct';
import SelectMeasurement from '../../../components/selectMeasurement/SelectMeasurement';
import AddMeasurement from '../../../components/addMeasurement/AddMeasurement';
import axios from 'axios';
import configData from '../../../utils/constants/config.json'
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';

const AddEditProduct = () => {

  const [inputValuePrice, setInputValue] = useState('');
  const [inputValueInventory, setInputInventory] = useState('');
  const [errorPrice, setErrorPrice] = useState('');
  const [errorInventory, setErrorInventory] = useState('');
  const [errorBrand, setErrorBrand] = useState('');
  const [errorCategory, setErrorCategory] = useState('');
  const [errorMeasurement, setErrorMeasurement] = useState('');

  const [openSelectBrand, setOpenSelectBrand] = useState(false)
  const [openAddBrand, setOpenAddBrand] = useState(false)

  const [openSelectCategory, setOpenSelectCategory] = useState(false)
  const [openAddCategory, setOpenAddCategory] = useState(false)

  const [openSelectMeasurement, setOpenSelectMeasurement] = useState(false)
  const [openAddMeasurement, setOpenAddMeasurement] = useState(false)

  const [brands, setBrands] = useState([])
  const [categories, setCategories] = useState([])
  const [measurements, setMeasurement] = useState([])

  const [selectedBrand, setSelectedBrand] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedMeasurement, setSelectedMeasurement] = useState(null)

  const [rerender, setRerender] = useState(false)

  const [success, setSuccess] = useState(false)

  const productName = useRef()
  const barcode = useRef()
  const shortDesc = useRef()
  const description = useRef()

  const navigate = useNavigate();

  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImagesUpdating, setSelectedImagesUpdating] = useState([]);

  // ------------------------------ update product --------------------------------------

  let { id } = useParams();

  useEffect(() => {
    // this will run if there is id in the url that means it is updating mode 
    if (id) {
      axios({
        method: "get",
        url: `${configData.SERVER_URL}partner/product/fetch-product/${id}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          accesstoken: configData.ACCESSTOKEN,
          logintoken: localStorage.getItem("loginToken"),
        },
      }).then(res => {
        if (res.data[200]) {
          setSelectedBrand(res?.data?.data?.product?.productBrand)
          setSelectedCategory(res?.data?.data?.product?.productCategory)
          setSelectedMeasurement(res?.data?.data?.product?.measurement)
          barcode.current.value = res?.data?.data?.product?.barcode
          setInputInventory(res?.data?.data?.product?.inventory)
          setInputValue(res?.data?.data?.product?.productPrice?.pricefrom)
          productName.current.value = res?.data?.data?.product?.name
          shortDesc.current.value = res?.data?.data?.product?.short_description
          description.current.value = res?.data?.data?.product?.description
          setSelectedImagesUpdating(res?.data?.data?.product?.productImages)
        }
      })
        .catch(err => console.log(err))
    }
  }, [id, rerender])

  // ------------------------------ end update product --------------------------------------

  useEffect(() => {
    const getData = () => {
      axios({
        method: "get",
        url: `${configData.SERVER_URL}partner/product/product-preload`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          accesstoken: configData.ACCESSTOKEN,
          logintoken: localStorage.getItem("loginToken"),
        },
      })
        .then(res => {
          setBrands(res?.data?.brands)
          setCategories(res?.data?.categories)
          setMeasurement(res?.data?.measurements)

        })
        .catch(err => console.log(err))
    }
    getData()
  }, [openSelectBrand, openSelectCategory, openSelectMeasurement, rerender])


  const handleInputChangePrice = (e) => {
    const value = e.target.value;
    setInputValue(value);

    // Check if the input is a valid integer greater than 0
    if (/^[1-9]\d*$/.test(value) || value == "") {
      setErrorPrice('');
    } else {
      setErrorPrice('Please enter a valid integer greater than 0.');
    }
  };

  const handleInputChangeInventory = (e) => {
    const value = e.target.value;
    setInputInventory(value);

    // Check if the input is a valid integer greater than 0
    if (/^[1-9]\d*$/.test(value) || value == "") {
      setErrorInventory('');
    } else {
      setErrorInventory('Please enter a valid integer greater than 0.');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!selectedBrand) {
      setErrorBrand("Please select a brand")
      setTimeout(() => {
        setErrorBrand("")
      }, 2500);
      return
    }
    if (!selectedCategory) {
      setErrorCategory("Please select a category")
      setTimeout(() => {
        setErrorCategory("")
      }, 2500);
      return
    }
    if (!selectedMeasurement) {
      setErrorMeasurement("Please select a measurement")
      setTimeout(() => {
        setErrorMeasurement("")
      }, 2500);
      return
    }
    if (errorPrice) {
      return
    }
    if (errorInventory) {
      return
    }

    let data;

    // create price!!
    axios({
      method: "post",
      url: `${configData.SERVER_URL}partner/product/add-product-price`,
      headers: {
        accesstoken: configData.ACCESSTOKEN,
        logintoken: localStorage.getItem("loginToken"),
      },
      data: {
        "pricetype": 1,
        "pricefrom": inputValuePrice
      }
    })
      .then(res => {
        data = {
          name: productName?.current?.value,
          barcode: barcode?.current?.value,
          productBrandId: selectedBrand?.id, // Existing brand ID or null for new
          short_description: shortDesc?.current?.value,
          description: description?.current?.value,
          productCategoryId: selectedCategory?.id, // Existing category ID or null for new
          productPriceId: res?.data?.data?.productPrice?.id,
          productTaxId: 3, // Existing tax ID
          measurementId: selectedMeasurement?.id, // Existing tax ID
          inventory: parseInt(inputValueInventory)
        }
        createProduct(data)
      })
      .catch(err => {
        console.log(err)
      })




  }

  const createProduct = (data) => {
    axios({
      method: "post",
      url: `${configData.SERVER_URL}partner/product/add-new-product`,
      headers: {
        accesstoken: configData.ACCESSTOKEN,
        logintoken: localStorage.getItem("loginToken"),
      },
      data
    })
      .then(res => {
        if (res?.data[200]) {
          createProductImages(res?.data?.data?.product?.id)
          setSuccess(true)
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });


        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleOpenSelectBrand = () => {
    setOpenSelectBrand((prev) => !prev)
  }

  const handleOpenAddBrand = () => {
    setOpenAddBrand(!openAddBrand)
  }

  const handleOpenSelectCategory = () => {
    setOpenSelectCategory(prev => !prev)
  }

  const handleOpenAddCategory = () => {
    setOpenAddCategory(prev => !prev)
  }

  const handleSelectMeasurement = () => {
    setOpenSelectMeasurement(prev => !prev)
  }

  const handleOpenAddMeasurement = () => {
    setOpenAddMeasurement(prev => !prev)
  }

  const updateProduct = (e) => {
    e.preventDefault()

    if (!selectedBrand) {
      setErrorBrand("Please select a brand")
      setTimeout(() => {
        setErrorBrand("")
      }, 2500);
      return
    }
    if (!selectedCategory) {
      setErrorCategory("Please select a category")
      setTimeout(() => {
        setErrorCategory("")
      }, 2500);
      return
    }
    if (!selectedMeasurement) {
      setErrorMeasurement("Please select a measurement")
      setTimeout(() => {
        setErrorMeasurement("")
      }, 2500);
      return
    }
    if (errorPrice) {
      return
    }
    if (errorInventory) {
      return
    }

    let data;
    axios({
      method: "post",
      url: `${configData.SERVER_URL}partner/product/add-product-price`,
      headers: {
        accesstoken: configData.ACCESSTOKEN,
        logintoken: localStorage.getItem("loginToken"),
      },
      data: {
        "pricetype": 1,
        "pricefrom": inputValuePrice
      }
    })
      .then(res => {
        data = {
          name: productName?.current?.value,
          barcode: barcode?.current?.value,
          productBrandId: selectedBrand?.id, // Existing brand ID or null for new
          short_description: shortDesc?.current?.value,
          description: description?.current?.value,
          productCategoryId: selectedCategory?.id, // Existing category ID or null for new
          productPriceId: res?.data?.data?.productPrice?.id,
          productTaxId: 3, // Existing tax ID
          measurementId: selectedMeasurement?.id, // Existing tax ID
          inventory: parseInt(inputValueInventory)
        }
        updateProduct2(id, data)
      })
      .catch(err => {
        console.log(err)
      })

  }

  const updateProduct2 = (id, data) => {
    axios({
      method: "put",
      url: `${configData.SERVER_URL}partner/product/update-product/${id}`,
      headers: {
        accesstoken: configData.ACCESSTOKEN,
        logintoken: localStorage.getItem("loginToken"),
      },
      data
    })
      .then(res => {
        if (res?.data[200]) {
          createProductImages(id)
          setSuccess(true)
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });

          setTimeout(() => {
            navigate("/services/products-Ecommerce");
          }, 2500);
        }
      })
      .catch(err => console.log(err))
  }

  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;

    const selectedImagesArray = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      const imageUrl = URL.createObjectURL(selectedFiles[i]);
      selectedImagesArray.push({ url: imageUrl, file: selectedFiles[i] });
    }

    setSelectedImages(selectedImagesArray);
  };

  const handleRemoveImage = (index, imageId) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);

    if (imageId) {
      axios({
        method: "delete",
        url: `${configData.SERVER_URL}partner/product/deleteProductImage`,
        headers: {
          accesstoken: configData.ACCESSTOKEN,
          logintoken: localStorage.getItem("loginToken"),
        },
        data: {
          id: imageId
        }
      }).then(res => {
        setRerender(prev => !prev)
      })
        .catch(err => console.log(err))
    }
  };

  const createProductImages = (productId) => {

    const formData = new FormData();

    selectedImages.forEach((image) => {
      formData.append(`image`, image?.file);
    });

    formData.append('id', productId);

    axios({
      method: "post",
      url: `${configData.SERVER_URL}partner/product/addProductImage`,
      headers: {
        'Content-Type': 'multipart/form-data',
        accesstoken: configData.ACCESSTOKEN,
        logintoken: localStorage.getItem("loginToken"),
      },
      data: formData
    })
      .then(res => {
        setTimeout(() => {
          navigate("/services/products-Ecommerce");
        }, 2500);
      })
      .catch(err => [
        console.log(err)
      ])
  }


  return (
    <>
      <SelectBrandPopUp
        brands={brands}
        isOpen={openSelectBrand}
        handleOpenAddBrand={handleOpenAddBrand}
        handleOpenSelectBrand={handleOpenSelectBrand}
        header={"brand"}
        setSelectedBrand={setSelectedBrand}
        setRerender={setRerender}
      />

      {openAddBrand && <AddBrandPopUp
        handleOpenSelectBrand={handleOpenSelectBrand}
        handleOpenAddBrand={handleOpenAddBrand}
        setSelectedBrand={setSelectedBrand}
      />}

      <SelectCategoryProject
        isOpen={openSelectCategory}
        handleOpenSelectCategory={handleOpenSelectCategory}
        handleOpenAddCategory={handleOpenAddCategory}
        categories={categories}
        setSelectedCategory={setSelectedCategory}
        setRerender={setRerender}
      />

      {openAddCategory && <AddCategoryProduct
        handleOpenAddCategory={handleOpenAddCategory}
        setSelectedCategory={setSelectedCategory}
        handleOpenSelectCategory={handleOpenSelectCategory}
      />}

      <SelectMeasurement
        isOpen={openSelectMeasurement}
        handleSelectMeasurement={handleSelectMeasurement}
        handleOpenAddMeasurement={handleOpenAddMeasurement}
        measurements={measurements}
        setSelectedMeasurement={setSelectedMeasurement}
        setRerender={setRerender}
      />

      {openAddMeasurement && <AddMeasurement
        handleSelectMeasurement={handleSelectMeasurement}
        handleOpenAddMeasurement={handleOpenAddMeasurement}
        setSelectedMeasurement={setSelectedMeasurement}
      />}

      <div className="main-content px-[var(--margin-x)] pb-8">

        <div className="flex items-center justify-between mt-5">
          <div className="flex items-start flex-col text-slate-800 dark:text-navy-50">
            <div className="flex items-center gap-3">
              <h2 className="text-3xl font-bold lg:text-2xl">{id ? "Update" : "Add"}  Product</h2>
              <div className="border-2 border-gray-300 font-semibold px-[8px] rounded-full">
                <span className="text-xs"></span>
              </div>
            </div>
          </div>
        </div>

        {success && <div className='p-3' style={{ color: '#fff', backgroundColor: 'rgba(0, 255, 0, .3)', borderRadius: '8px' }}>
          Product {id ? "Updated" : "Added"} Successfully
        </div>}

        <form action=""
          onSubmit={id ? updateProduct : handleSubmit}
        >
          <div className='w-full lg:flex justify-between'>
            <label className="p-2 block w-full  lg:w-1/3 text-left" style={{ marginTop: "8px" }}>
              <span className="block  lg:w-1/2 text-sm font-medium text-slate-700">
                Product Name
              </span>
              <input
                ref={productName}
                type="text"
                name="name"
                id="name"
                // value={formValues.name}
                // onChange={handleInputChange}
                className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                placeholder="Enter Product Name"
                required
              />
            </label>
            <label className="p-2 block w-full  lg:w-1/3 text-left" style={{ marginTop: "8px" }}>
              <span className="block lg:w-1/2 text-sm font-medium text-slate-700">
                Barcode
              </span>
              <input
                ref={barcode}
                type="text"
                name="Barcode"
                id="Barcode"
                // value={formValues.name}
                // onChange={handleInputChange}
                className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                placeholder="Enter Barcode"
                required
              />
            </label>
            <label className="p-2 block w-full  lg:w-1/3 text-left" style={{ marginTop: "8px" }}>
              <span className="block lg:w-1/2 text-sm font-medium text-slate-700">
                Short Description
              </span>
              <input
                ref={shortDesc}
                type="text"
                name="short_description"
                id="short_description"
                // value={formValues.name}
                // onChange={handleInputChange}
                className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                placeholder="Enter Short Description"
                required
              />
            </label>

          </div>

          <div className='p-2 w-full lg:flex justify-between'>
            <label className="p-2 block w-full text-left" style={{ marginTop: "8px" }}>
              <div className='flex'>
                <span className="block text-sm font-medium text-slate-700">
                  Product Description
                </span>
                <span className=" text-sx  text-gray-700 mx-1">
                  - VAT (5%)
                </span>
              </div>
              <textarea
                ref={description}
                name="description"
                id="description"
                cols="30"
                rows="5"
                placeholder="Enter Description"
                className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                required
              ></textarea>
            </label>
          </div>

          <div className='p-2 w-full lg:flex justify-between'>
            <label className="p-2 block w-full  lg:w-1/3 text-left" style={{ marginTop: "8px" }}>
              <span className="block lg:w-1/2 text-sm font-medium text-slate-700">
                Product Price
              </span>
              <input
                // ref={name}
                type="number"
                name="Price"
                id="Price"
                value={inputValuePrice}
                onChange={handleInputChangePrice}
                className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                placeholder="Enter Product Price"
                required
              />    <p style={{ color: 'red' }}>{errorPrice}</p>


            </label>


            <label className=" p-2 flex flex-col py-5 justify-start w-full sm:w-1/2 md:w-1/3 text-left" style={{ marginTop: "8px" }}>
              <span className="block lg:w-1/2 text-sm font-medium text-slate-700">
                Product Brand
              </span>
              <span
                className="cursor-pointer text-blue-700 flex items-center"
                onClick={handleOpenSelectBrand}
              >
                <i class="fa-plus flex justify-center items-center mr-2 border rounded-full bg-white w-5 h-5 "></i> Select A Brand
              </span>
              <p style={{ color: 'red' }}>{errorBrand}</p>

              {
                selectedBrand && <div
                  className="p-2 border border-slate-200 rounded-lg flex justify-between items-center cursor-pointer"

                >
                  {selectedBrand.name}
                  <i onClick={() => setSelectedBrand(null)} className="fa fa-trash text-red-600"></i>
                </div>
              }
            </label>


            <label className=" p-2 flex flex-col py-5 justify-start w-full sm:w-1/2 md:w-1/3 text-left" style={{ marginTop: "8px" }}>
              <span className="block lg:w-1/2 text-sm font-medium text-slate-700">
                Product Category
              </span>
              <span className="cursor-pointer text-blue-700 flex items-center"
                onClick={handleOpenSelectCategory}
              >
                <i class="fa-plus flex justify-center items-center mr-2 border rounded-full bg-white w-5 h-5 "></i> Select A Category
              </span>
              <p style={{ color: 'red' }}>{errorCategory}</p>
              {
                selectedCategory && <div
                  className="p-2 border border-slate-200 rounded-lg flex justify-between items-center cursor-pointer"

                >
                  {selectedCategory.name}
                  <i onClick={() => setSelectedCategory(null)} className="fa fa-trash text-red-600"></i>
                </div>
              }
            </label>

            <label className=" p-2 flex flex-col py-5 justify-start w-full sm:w-1/2 md:w-1/3 text-left" style={{ marginTop: "8px" }}>
              <span className="block  text-sm font-medium text-slate-700">
                Product Measurement
              </span>
              <span className="cursor-pointer text-blue-700 flex items-center"
                onClick={handleSelectMeasurement}
              >
                <i class="fa-plus flex justify-center items-center mr-2 border rounded-full bg-white w-5 h-5 "></i> Select A Measurement
              </span>
              <p style={{ color: 'red' }}>{errorMeasurement}</p>
              {
                selectedMeasurement && <div
                  className="p-2 border border-slate-200 rounded-lg flex justify-between items-center cursor-pointer"

                >
                  {selectedMeasurement.amount} {selectedMeasurement.unit}
                  <i onClick={() => setSelectedMeasurement(null)} className="fa fa-trash text-red-600"></i>
                </div>
              }
            </label>

          </div>

          <div className='flex justify-between items-center p-2'>
            <label className="p-2 block w-full  lg:w-1/3 text-left" style={{ marginTop: "8px" }}>
              <span className="block lg:w-1/2 text-sm font-medium text-slate-700">
                Product Inventory
              </span>
              <input
                // ref={name}
                type="number"
                name="Inventory"
                id="Inventory"
                value={inputValueInventory}
                onChange={handleInputChangeInventory}
                className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                placeholder="Enter Product Inventory"
                required
              />
              <p style={{ color: 'red' }}>{errorInventory}</p>
            </label>
            <label className="p-2 block w-full  lg:w-1/3 text-left" style={{ marginTop: "8px" }}>
              <span className="block lg:w-1/2 text-sm font-medium text-slate-700">
                Select Images
              </span>
              <input
                // ref={name}
                type="file"
                name="file"
                id="file"
                multiple
                onChange={handleImageChange}
                required={id ? false : true}
              />
            </label>

          </div>

          <div className='flex gap-3'>
            {[...selectedImages, ...selectedImagesUpdating].map((image, index) => (
              <div className=''>

                <img
                  key={index}
                  src={image.image || image.url}
                  alt={`Image ${index}`}
                  width="150"
                />
                <div onClick={() => handleRemoveImage(index, image?.id)}>
                  <i className="fa fa-trash cursor-pointer text-red-600 "></i>
                </div>
              </div>
            ))}
          </div>

          <div className='mb-10 p-2'>
            <button
              type='submit'
              className='btn border border-slate-300 font-medium hover:text-slate-300 text-slate-800 hover:bg-slate-800 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90'
            >
              {id ? "Update" : "Add"} Product
            </button>
          </div>


        </form>



      </div>
    </>

  )
}

export default AddEditProduct