export const dates = [
  // { value: '0', displayTime: '12:00am' },
  // { value: '300', displayTime: '01:05am' },
  // { value: '600', displayTime: '01:10am' },
  // { value: '900', displayTime: '02:15am' },
  // { value: '1200', displayTime: '2:20am' },
  // { value: '1500', displayTime: '2:25am' },
  // { value: '1800', displayTime: '2:30am' },
  // { value: '2100', displayTime: '2:35am' },
  // { value: '2400', displayTime: '2:40am' },
  // { value: '2700', displayTime: '2:45am' },
  // { value: '3000', displayTime: '2:50am' },
  // { value: '3300', displayTime: '2:55am' },
  // { value: '3600', displayTime: '03:00am' },
  // { value: '3900', displayTime: '03:05am' },
  // { value: '4200', displayTime: '03:10am' },
  // { value: '4500', displayTime: '03:15am' },
  // { value: '4800', displayTime: '03:20am' },
  // { value: '5100', displayTime: '03:25am' },
  // { value: '5400', displayTime: '03:30am' },
  // { value: '5700', displayTime: '03:35am' },
  // { value: '6000', displayTime: '03:40am' },
  // { value: '6300', displayTime: '03:45am' },
  // { value: '6600', displayTime: '03:50am' },
  // { value: '6900', displayTime: '03:55am' },
  // { value: '7200', displayTime: '04:00am' },
  // { value: '7500', displayTime: '04:05am' },
  // { value: '7800', displayTime: '04:10am' },
  // { value: '8100', displayTime: '04:15am' },
  // { value: '8400', displayTime: '04:20am' },
  // { value: '8700', displayTime: '04:25am' },
  // { value: '9000', displayTime: '04:30am' },
  // { value: '9300', displayTime: '04:35am' },
  // { value: '9600', displayTime: '04:40am' },
  // { value: '9900', displayTime: '04:45am' },
  // { value: '10200', displayTime: '2:50am' },
  // { value: '10500', displayTime: '2:55am' },
  // { value: '10800', displayTime: '3:00am' },
  // { value: '11100', displayTime: '3:05am' },
  // { value: '11400', displayTime: '3:10am' },
  // { value: '11700', displayTime: '3:15am' },
  // { value: '12000', displayTime: '3:20am' },
  // { value: '12300', displayTime: '3:25am' },
  // { value: '12600', displayTime: '3:30am' },
  // { value: '12900', displayTime: '3:35am' },
  // { value: '13200', displayTime: '3:40am' },
  // { value: '13500', displayTime: '3:45am' },
  // { value: '13800', displayTime: '3:50am' },
  // { value: '14100', displayTime: '3:55am' },
  // { value: '14400', displayTime: '4:00am' },
  // { value: '14700', displayTime: '4:05am' },
  // { value: '15000', displayTime: '4:10am' },
  // { value: '15300', displayTime: '4:15am' },
  // { value: '15600', displayTime: '4:20am' },
  // { value: '15900', displayTime: '4:25am' },
  // { value: '16200', displayTime: '4:30am' },
  // { value: '16500', displayTime: '4:35am' },
  // { value: '16800', displayTime: '4:40am' },
  // { value: '17100', displayTime: '4:45am' },
  // { value: '17400', displayTime: '4:50am' },
  // { value: '17700', displayTime: '4:55am' },
  // { value: '18000', displayTime: '5:00am' },
  // { value: '18300', displayTime: '5:05am' },
  // { value: '18600', displayTime: '5:10am' },
  // { value: '18900', displayTime: '5:15am' },
  // { value: '19200', displayTime: '5:20am' },
  // { value: '19500', displayTime: '5:25am' },
  // { value: '19800', displayTime: '5:30am' },
  // { value: '20100', displayTime: '5:35am' },
  // { value: '20400', displayTime: '5:40am' },
  // { value: '20700', displayTime: '5:45am' },
  // { value: '21000', displayTime: '5:50am' },
  // { value: '21300', displayTime: '5:55am' },
  // { value: '21600', displayTime: '6:00am' },
  // { value: '21900', displayTime: '6:05am' },
  // { value: '22200', displayTime: '6:10am' },
  // { value: '22500', displayTime: '6:15am' },
  // { value: '22800', displayTime: '6:20am' },
  // { value: '23100', displayTime: '6:25am' },
  // { value: '23400', displayTime: '6:30am' },
  // { value: '23700', displayTime: '6:35am' },
  // { value: '24000', displayTime: '6:40am' },
  // { value: '24300', displayTime: '6:45am' },
  // { value: '24600', displayTime: '6:50am' },
  // { value: '24900', displayTime: '6:55am' },
  // { value: '25200', displayTime: '7:00am' },
  // { value: '25500', displayTime: '7:05am' },
  // { value: '25800', displayTime: '7:10am' },
  // { value: '26100', displayTime: '7:15am' },
  // { value: '26400', displayTime: '7:20am' },
  // { value: '26700', displayTime: '7:25am' },
  // { value: '27000', displayTime: '7:30am' },
  // { value: '27300', displayTime: '7:35am' },
  // { value: '27600', displayTime: '7:40am' },
  // { value: '27900', displayTime: '7:45am' },
  // { value: '28200', displayTime: '7:50am' },
  // { value: '28500', displayTime: '7:55am' },
  // { value: '28800', displayTime: '8:00am' },
  // { value: '29100', displayTime: '8:05am' },
  // { value: '29400', displayTime: '8:10am' },
  // { value: '29700', displayTime: '8:15am' },
  // { value: '30000', displayTime: '8:20am' },
  // { value: '30300', displayTime: '8:25am' },
  // { value: '30600', displayTime: '8:30am' },
  // { value: '30900', displayTime: '8:35am' },
  // { value: '31200', displayTime: '8:40am' },
  // { value: '31500', displayTime: '8:45am' },
  // { value: '31800', displayTime: '8:50am' },
  // { value: '32100', displayTime: '8:55am' },
  // { value: '32400', displayTime: '9:00am' },
  { value: '32700', displayTime: '9:00am' },
  { value: '33000', displayTime: '9:10am' },
  { value: '33300', displayTime: '9:15am' },
  { value: '33600', displayTime: '9:20am' },
  { value: '33900', displayTime: '9:25am' },
  { value: '34200', displayTime: '9:30am' },
  { value: '34500', displayTime: '9:35am' },
  { value: '34800', displayTime: '9:40am' },
  { value: '35100', displayTime: '9:45am' },
  { value: '35400', displayTime: '9:50am' },
  { value: '35700', displayTime: '9:55am' },
  { value: '36000', displayTime: '10:00am' },
  { value: '36300', displayTime: '10:05am' },
  { value: '36600', displayTime: '10:10am' },
  { value: '36900', displayTime: '10:15am' },
  { value: '37200', displayTime: '10:20am' },
  { value: '37500', displayTime: '10:25am' },
  { value: '37800', displayTime: '10:30am' },
  { value: '38100', displayTime: '10:35am' },
  { value: '38400', displayTime: '10:40am' },
  { value: '38700', displayTime: '10:45am' },
  { value: '39000', displayTime: '10:50am' },
  { value: '39300', displayTime: '10:55am' },
  { value: '39600', displayTime: '11:00am' },
  { value: '39900', displayTime: '11:05am' },
  { value: '40200', displayTime: '11:10am' },
  { value: '40500', displayTime: '11:15am' },
  { value: '40800', displayTime: '11:20am' },
  { value: '41100', displayTime: '11:25am' },
  { value: '41400', displayTime: '11:30am' },
  { value: '41700', displayTime: '11:35am' },
  { value: '42000', displayTime: '11:40am' },
  { value: '42300', displayTime: '11:45am' },
  { value: '42600', displayTime: '11:50am' },
  { value: '42900', displayTime: '11:55am' },
  { value: '43200', displayTime: '12:00pm' },
  { value: '43500', displayTime: '12:05pm' },
  { value: '43800', displayTime: '12:10pm' },
  { value: '44100', displayTime: '12:15pm' },
  { value: '44400', displayTime: '12:20pm' },
  { value: '44700', displayTime: '12:25pm' },
  { value: '45000', displayTime: '12:30pm' },
  { value: '45300', displayTime: '12:35pm' },
  { value: '45600', displayTime: '12:40pm' },
  { value: '45900', displayTime: '12:45pm' },
  { value: '46200', displayTime: '12:50pm' },
  { value: '46500', displayTime: '12:55pm' },
  { value: '46800', displayTime: '1:00pm' },
  { value: '47100', displayTime: '1:05pm' },
  { value: '47400', displayTime: '1:10pm' },
  { value: '47700', displayTime: '1:15pm' },
  { value: '48000', displayTime: '1:20pm' },
  { value: '48300', displayTime: '1:25pm' },
  { value: '48600', displayTime: '1:30pm' },
  { value: '48900', displayTime: '1:35pm' },
  { value: '49200', displayTime: '1:40pm' },
  { value: '49500', displayTime: '1:45pm' },
  { value: '49800', displayTime: '1:50pm' },
  { value: '50100', displayTime: '1:55pm' },
  { value: '50400', displayTime: '2:00pm' },
  { value: '50700', displayTime: '2:05pm' },
  { value: '51000', displayTime: '2:10pm' },
  { value: '51300', displayTime: '2:15pm' },
  { value: '51600', displayTime: '2:20pm' },
  { value: '51900', displayTime: '2:25pm' },
  { value: '52200', displayTime: '2:30pm' },
  { value: '52500', displayTime: '2:35pm' },
  { value: '52800', displayTime: '2:40pm' },
  { value: '53100', displayTime: '2:45pm' },
  { value: '53400', displayTime: '2:50pm' },
  { value: '53700', displayTime: '2:55pm' },
  { value: '54000', displayTime: '3:00pm' },
  { value: '54300', displayTime: '3:05pm' },
  { value: '54600', displayTime: '3:10pm' },
  { value: '54900', displayTime: '3:15pm' },
  { value: '55200', displayTime: '3:20pm' },
  { value: '55500', displayTime: '3:25pm' },
  { value: '55800', displayTime: '3:30pm' },
  { value: '56100', displayTime: '3:35pm' },
  { value: '56400', displayTime: '3:40pm' },
  { value: '56700', displayTime: '3:45pm' },
  { value: '57000', displayTime: '3:50pm' },
  { value: '57300', displayTime: '3:55pm' },
  { value: '57600', displayTime: '4:00pm' },
  { value: '57900', displayTime: '4:05pm' },
  { value: '58200', displayTime: '4:10pm' },
  { value: '58500', displayTime: '4:15pm' },
  { value: '58800', displayTime: '4:20pm' },
  { value: '59100', displayTime: '4:25pm' },
  { value: '59400', displayTime: '4:30pm' },
  { value: '59700', displayTime: '4:35pm' },
  { value: '60000', displayTime: '4:40pm' },
  { value: '60300', displayTime: '4:45pm' },
  { value: '60600', displayTime: '4:50pm' },
  { value: '60900', displayTime: '4:55pm' },
  { value: '61200', displayTime: '5:00pm' },
  { value: '61500', displayTime: '5:05pm' },
  { value: '61800', displayTime: '5:10pm' },
  { value: '62100', displayTime: '5:15pm' },
  { value: '62400', displayTime: '5:20pm' },
  { value: '62700', displayTime: '5:25pm' },
  { value: '63000', displayTime: '5:30pm' },
  { value: '63300', displayTime: '5:35pm' },
  { value: '63600', displayTime: '5:40pm' },
  { value: '63900', displayTime: '5:45pm' },
  { value: '64200', displayTime: '5:50pm' },
  { value: '64500', displayTime: '5:55pm' },
  { value: '64800', displayTime: '6:00pm' },
  // { value: '65100', displayTime: '6:05pm' },
  // { value: '65400', displayTime: '6:10pm' },
  // { value: '65700', displayTime: '6:15pm' },
  // { value: '66000', displayTime: '6:20pm' },
  // { value: '66300', displayTime: '6:25pm' },
  // { value: '66600', displayTime: '6:30pm' },
  // { value: '66900', displayTime: '6:35pm' },
  // { value: '67200', displayTime: '6:40pm' },
  // { value: '67500', displayTime: '6:45pm' },
  // { value: '67800', displayTime: '6:50pm' },
  // { value: '68100', displayTime: '6:55pm' },
  // { value: '68400', displayTime: '7:00pm' },
  // { value: '68700', displayTime: '7:05pm' },
  // { value: '69000', displayTime: '7:10pm' },
  // { value: '69300', displayTime: '7:15pm' },
  // { value: '69600', displayTime: '7:20pm' },
  // { value: '69900', displayTime: '7:25pm' },
  // { value: '70200', displayTime: '7:30pm' },
  // { value: '70500', displayTime: '7:35pm' },
  // { value: '70800', displayTime: '7:40pm' },
  // { value: '71100', displayTime: '7:45pm' },
  // { value: '71400', displayTime: '7:50pm' },
  // { value: '71700', displayTime: '7:55pm' },
  // { value: '72000', displayTime: '8:00pm' },
  // { value: '72300', displayTime: '8:05pm' },
  // { value: '72600', displayTime: '8:10pm' },
  // { value: '72900', displayTime: '8:15pm' },
  // { value: '73200', displayTime: '8:20pm' },
  // { value: '73500', displayTime: '8:25pm' },
  // { value: '73800', displayTime: '8:30pm' },
  // { value: '74100', displayTime: '8:35pm' },
  // { value: '74400', displayTime: '8:40pm' },
  // { value: '74700', displayTime: '8:45pm' },
  // { value: '75000', displayTime: '8:50pm' },
  // { value: '75300', displayTime: '8:55pm' },
  // { value: '75600', displayTime: '9:00pm' },
  // { value: '75900', displayTime: '9:05pm' },
  // { value: '76200', displayTime: '9:10pm' },
  // { value: '76500', displayTime: '9:15pm' },
  // { value: '76800', displayTime: '9:20pm' },
  // { value: '77100', displayTime: '9:25pm' },
  // { value: '77400', displayTime: '9:30pm' },
  // { value: '77700', displayTime: '9:35pm' },
  // { value: '78000', displayTime: '9:40pm' },
  // { value: '78300', displayTime: '9:45pm' },
  // { value: '78600', displayTime: '9:50pm' },
  // { value: '78900', displayTime: '9:55pm' },
  // { value: '79200', displayTime: '10:00pm' },

  // { value: '79500', displayTime: '10:05pm' },

  // { value: '79800', displayTime: '10:10pm' },

  // { value: '80100', displayTime: '10:15pm' },

  // { value: '80400', displayTime: '10:20pm' },

  // { value: '80700', displayTime: '10:25pm' },

  // { value: '81000', displayTime: '10:30pm' },

  // { value: '81300', displayTime: '10:35pm' },

  // { value: '81600', displayTime: '10:40pm' },

  // { value: '81900', displayTime: '10:45pm' },

  // { value: '82200', displayTime: '10:50pm' },

  // { value: '82500', displayTime: '10:55pm' },

  // { value: '82800', displayTime: '11:00pm' },

  // { value: '83100', displayTime: '11:05pm' },

  // { value: '83400', displayTime: '11:10pm' },

  // { value: '83700', displayTime: '11:15pm' },

  // { value: '84000', displayTime: '11:20pm' },

  // { value: '84300', displayTime: '11:25pm' },

  // { value: '84600', displayTime: '11:30pm' },
  // { value: '84900', displayTime: '11:35pm' },
  // { value: '85200', displayTime: '11:40pm' },
  // { value: '85500', displayTime: '11:45pm' },
  // { value: '85800', displayTime: '11:50pm' },
  // { value: '86100', displayTime: '11:55pm' },
]

export const durations = [
  { value: '20', display: '20min' },

  { value: '30', display: '30min' },
  { value: '40', display: '40min' },
  { value: '50', display: '50min' },
  { value: '60', display: '1h' },
  { value: '70', display: '1h 10min' },
  { value: '90', display: '1h 30min' },
  { value: '110', display: '1h 50min' },
  { value: '120', display: '2h' },
  { value: '135', display: '2h 15min' },
  { value: '150', display: '2h 30min' },
  { value: '165', display: '2h 45min' },
  { value: '180', display: '3h' },
  { value: '195', display: '3h 15min' },
  { value: '210', display: '3h 30min' },
  { value: '225', display: '3h 45min' },
  { value: '240', display: '4h' },
  { value: '270', display: '4h 30min' },
  { value: '300', display: '5h' },
  { value: '330', display: '5h 30min' },
  { value: '360', display: '6h' },
  { value: '390', display: '6h 30min' },
  { value: '420', display: '7h' },
  { value: '450', display: '7h 30min' },
  { value: '480', display: '8h' },
  { value: '540', display: '9h' },
]
