import React from 'react'
import axios from 'axios'
import { useEffect } from 'react'
import { useState } from 'react'
import { useRef } from 'react'
import configData from '../../../utils/constants/config.json'
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import moment from "moment";
import ImageResize from '../../../components/ImageCropper/imageupload'

const EditPromoCode = () => {

    const [promoCodeData, setPromoCodeData] = useState({})
    const [branchesServices, setBranchesServices] = useState([])

    const [selectedStartDate, setSelectedStartDate] = useState('')
    const [selectedDate, setSelectedDate] = useState('')

    const [imageUrl, setImageUrl] = useState('');

    const [SelectedFile, setSelectedFile] = useState(null)

    const [selectedServices, setSelectedServices] = useState([])
    const [selectedBranches, setSelectedBranches] = useState([])

    const [createdSuccess, setCreatedSuccess] = useState(false)
    const [createdError, setCreatedError] = useState(false)

    const [selectServiceError, setSelectServiceError] = useState(false)
    const [selectBranchError, setSelectBranchError] = useState(false)

    const [imageResizer, setImageResizer] = useState(false);
    const [loader, setLoader] = useState(false);
    const [imageResizerMobile, setImageResizerMobile] = useState(false);
    const [promoCodeImage, setPromoCodeImage] = useState(null);

    const [errorDate, setErrorDate] = useState(false);

    const name = useRef()
    const expiredOn = useRef()
    const startsOn = useRef()
    const value = useRef()
    const description = useRef()
    const banner = useRef()
    const limit = useRef()
    const promoCode = useRef()

    let { id } = useParams();

    const navigate = useNavigate();

    // get the promo code data
    useEffect(() => {
        const getData = () => {
            axios({
                method: 'get',
                url: configData.SERVER_URL + 'partner/promocodes/fetch-promocode/' + id,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    accesstoken: configData.ACCESSTOKEN,
                    logintoken: localStorage.getItem('loginToken'),
                },
            }).then(res => {
                if (res.data[200]) {
                    setPromoCodeData(res.data.promoCode)
                }
            })
                .catch(err => console.log(err))
        }
        getData()
    }, [])

    // get the services and branches 
    useEffect(() => {
        const getData = () => {
            axios({
                method: 'get',
                url: configData.SERVER_URL + 'partner/promocodes/get-branches-and-services',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    accesstoken: configData.ACCESSTOKEN,
                    logintoken: localStorage.getItem('loginToken'),
                },
            }).then(res => {

                if (res.data[200]) {
                    setBranchesServices(res.data.data)
                }
            })
                .catch(err => console.log(err))
        }
        getData()
    }, [])

    // set: selectedExpiredDate, selectedStartDate, selectedBranched, selectedServices 
    useEffect(() => {
        const setData = () => {
            const branches = promoCodeData?.promocodelocations?.flatMap(branchId =>
                branchesServices?.map(branch => {
                    if (branchId?.branchId == branch?.id) {
                        return {
                            id: branch?.id,
                            name: branch?.name,
                            address: branch?.address
                        };
                    }
                })
            );

            const services = promoCodeData?.promocodeservices?.flatMap(serviceId =>
                branchesServices?.flatMap(branch =>
                    branch?.service?.filter(s => s?.id === serviceId?.serviceId)?.map(s => ({
                        id: s.id,
                        name: s?.name
                    }))
                )
            );

            setSelectedBranches(branches)
            setSelectedServices(services)
            setSelectedStartDate(moment(promoCodeData?.startson).format('YYYY-MM-DD'))
            setSelectedDate(moment(promoCodeData?.expiredon).format('YYYY-MM-DD'))

        }
        if (branchesServices.length > 0 && promoCodeData) {
            setData()
        }
    }, [branchesServices, promoCodeData])

    const uploadFile = async (file) => {
        const formData = new FormData();
        formData.append('image', promoCodeImage);

        try {
            const response = await axios({
                method: 'post',
                url: configData.SERVER_URL + 'partner/promocodes/add-image',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    accesstoken: configData.ACCESSTOKEN,
                    logintoken: localStorage.getItem('loginToken'),
                },
                data: formData,
            });

            if (response.data[200]) {
                setImageResizer(false)
                setImageUrl(response.data.image)
            } else {
                setCreatedError(true)
            }

        } catch (err) {
            console.error(err);
            throw err;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        


        try {
            let imageUrlReturned;

            if (selectedBranches.length <= 0) {
                setSelectBranchError(true)
                return
            }
            else if (selectedServices.length <= 0) {
                setSelectServiceError(true)
                return 
            }

            if (imageUrl) {
                imageUrlReturned = imageUrl
            }

            const data = {
                promocodelocations: selectedBranches.map(b => parseInt(b?.id)),
                promocodeservices: selectedServices.map(s => parseInt(s?.id)),
                expiredon: expiredOn.current.value,
                startson: startsOn.current.value,
                value: parseInt(value.current.value),
                name: name.current.value,
                description: description.current.value,
                limit: parseInt(limit.current.value),
                promoCode: promoCode.current.value,
                // banner: imageUrl ? imageUrlReturned : promoCodeData?.banner,
                banner: "imageUrl",
            }

            const response = await axios({
                method: 'put',
                url: configData.SERVER_URL + 'partner/promocodes/update-promocode/' + id,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    accesstoken: configData.ACCESSTOKEN,
                    logintoken: localStorage.getItem('loginToken'),
                },
                data
            })

            if (response.data[200]) {
                setCreatedSuccess(true)
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });

                setTimeout(() => {
                    navigate("/promotions/promoCodes");
                }, 2500);
            }

            if (response.data[400]) {
                setErrorDate(true)
                setTimeout(() => {
                    setErrorDate(false)
                }, 4000);
            }


        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const handleRemoveBranch = (id) => {
        const newBranches = selectedBranches.filter(branch => branch?.id != id)
        setSelectedBranches(newBranches)
    }

    const handleRemoveAllBranch = () => {
        setSelectedBranches([])
    }

    const handleRemoveService = (id) => {
        const newServices = selectedServices.filter(service => service?.id != id)
        setSelectedServices(newServices)
    }

    const handleRemoveAllService = () => {
        setSelectedServices([])
    }

    const imageModalClose = (e) => {
        e ? setImageResizerMobile(true) : setImageResizer(false);
    };

    const changeImage = (file) => {
        var url = URL.createObjectURL(file);
        setPromoCodeImage(file);
    };

    const isSelectedAllBranches = () => {
        if (branchesServices && branchesServices.length > 0 && selectedBranches && selectedBranches.length > 0) {
            const isAllBranches = branchesServices.length == selectedBranches.length
            return isAllBranches
        }

        return false

    }

    const isSelectedAllServices = () => {
        if (branchesServices && branchesServices.length > 0 && selectedServices && selectedServices.length > 0) {
            // create array each item consider the length of service in each branch 
            const items = branchesServices?.map(branch => {
                return branch.service.length
            })
            
            // calculate the total of these items in the array
            const totalItems = items.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            
            const isAllServices = totalItems == selectedServices.length
            
            return isAllServices

        }

        return false

    }

    const showSelectedOptions = (nameItem, array, remove, removeAll, isSelectedAllItem) => {
        if (isSelectedAllItem()) {
            return <div

                className='flex justify-between items-center p-3 border border-gray-300 w-auto h-10'>
                <span>{`All ${nameItem}`}</span>
                <span
                    className='flex items-center justify-center w-5 h-5 cursor-pointer'
                    style={{ backgroundColor: '#f00', borderRadius: '50%', color: '#fff' }}
                    onClick={() => removeAll()}
                >
                    X
                </span>
            </div>
        }

        if (array?.length > 0 && array[0]?.name == 'all') {
            return <div

                className='flex justify-between items-center p-3 border border-gray-300 w-auto h-10'>
                <span>{`All ${nameItem}`}</span>
                <span
                    className='flex items-center justify-center w-5 h-5 cursor-pointer'
                    style={{ backgroundColor: '#f00', borderRadius: '50%', color: '#fff' }}
                    onClick={() => removeAll()}
                >
                    X
                </span>
            </div>
        }
        else if (array?.length > 0) {

            const mapping = array.map(item => (
                <div
                    key={item?.id}
                    className='flex justify-between items-center p-3 border border-gray-300 w-auto h-10'>
                    <span>{item?.name}</span>
                    <span
                        className='flex items-center justify-center w-5 h-5 cursor-pointer'
                        style={{ backgroundColor: '#f00', borderRadius: '50%', color: '#fff' }}
                        onClick={() => remove(item?.id)}
                    >
                        X
                    </span>
                </div>
            ))
            return mapping
        } else {
            return null
        }
    }

    const setBranchSelected = (e) => {
        // setErrorBranches(false)

        if (isSelectedAllBranches() && e.target.value != 'all') {
            setSelectedBranches([])
            setSelectedBranches((prev) => [...prev, { id: e.target.value, name: e.target.selectedOptions[0].textContent }])
        } else {
            setSelectedBranches(branchesServices?.map(branch => {
                return {
                    id: branch?.id,
                    name: "all"
                }
            }))
        }
    }

    const setServiceSelected = (e) => {
        // setErrorServices(false)

        if (isSelectedAllServices() && e.target.value != 'all') {
            setSelectedServices([])
            setSelectedServices((prev) => [...prev, { id: e.target.value, name: e.target.selectedOptions[0].textContent }])
        }

        else if (e.target.value != 'all') {
            setSelectedServices((prev) => [...prev, { id: e.target.value, name: e.target.selectedOptions[0].textContent }])
        }

        else {
            const branches = branchesServices?.map(branch => {
                return branch?.service?.map((serv) => {
                    return {
                        id: serv?.id,
                        name: "all"
                    }
                })

            }).flat()

            setSelectedServices(branches)
        }
    }

    return (
        <>
            {
                promoCodeData && <div className="main-content px-[var(--margin-x)] pb-8">

                    <div className="flex items-center justify-between mt-5">
                        <div className="flex items-start flex-col text-slate-800 dark:text-navy-50">
                            <div className="flex items-center gap-3">
                                <h2 className="text-3xl font-bold lg:text-2xl">Edit Promo Code</h2>
                                <div className="border-2 border-gray-300 font-semibold px-[8px] rounded-full">
                                    <span className=" text-xs"></span>
                                </div>
                            </div>
                            {/* <div className="mt-2 hidden md:inline-flex items-center gap-1 font-medium text-base">
                                <span>View, Promo Codes.</span>
                                <span className="cursor-pointer text-sky-500">Learn more</span>
                            </div> */}
                        </div>
                    </div>

                    {
                        createdSuccess && <div className='p-3' style={{ backgroundColor: 'rgba(0, 255, 0, .3)', borderRadius: '8px' }}>
                            Promo Code Has Been Updated Successfully
                        </div>
                    }
                    {
                        createdError && <div className='p-3' style={{ backgroundColor: 'rgba(255, 0, 0, .3)', borderRadius: '8px' }}>
                            Something went Wrong, Try Again Please!
                        </div>
                    }

                    <form action=""
                        onSubmit={handleSubmit}
                    >
                        <div className='w-full lg:flex justify-between'>
                            <label className="block w-full sm:w-1/2 text-left" style={{ marginTop: "8px" }}>
                                <span className="block lg:w-1/2 text-sm font-medium text-slate-700">
                                    name
                                </span>
                                <input
                                    ref={name}
                                    defaultValue={promoCodeData?.name}
                                    type="text"
                                    name="name"
                                    id="name"
                                    className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                                    placeholder="Enter Name"
                                    required
                                />
                            </label>
                            <label className="block px-4 w-full sm:w-1/2 text-left" style={{ marginTop: "8px" }}>
                                <span className="block lg:w-1/2 text-sm font-medium text-slate-700">
                                    promo Code
                                </span>
                                <input
                                    ref={promoCode}
                                    defaultValue={promoCodeData?.promoCode}
                                    type="text"
                                    name="promoCode"
                                    id="promoCode"
                                    className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                                    placeholder="Enter Promo Code"
                                    required
                                />
                            </label>
                        </div>

                        <div className='w-full lg:flex justify-between'>
                            <label className="block w-full sm:w-1/2 text-left" style={{ marginTop: "8px" }}>
                                <span className="block lg:w-1/2 text-sm font-medium text-slate-700">
                                    Description
                                </span>
                                <textarea
                                    ref={description}
                                    defaultValue={promoCodeData?.description}
                                    name="description"
                                    id="description"
                                    cols="30"
                                    rows="5"
                                    placeholder="Enter Description"
                                    className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                                    required
                                ></textarea>
                            </label>
                            <div className='lg:flex flex-col w-full px-3 sm:w-1/2 text-left'>
                                <div className='w-full flex flex-col sm:flex-row justify-between'>
                                    <label className="pr-3 block w-full sm:w-1/2 text-left" style={{ marginTop: "8px" }}>
                                        <span className="block  text-sm font-medium text-slate-700">
                                            Start on
                                        </span>
                                        <input
                                            ref={startsOn}
                                            value={selectedStartDate}
                                            type="date"
                                            name="expiredOn"
                                            id="expiredOn"
                                            className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                                            required
                                            onChange={(e) => setSelectedStartDate(e.target.value)}
                                        />
                                    </label>
                                    <label className="block w-full sm:w-1/2 text-left" style={{ marginTop: "8px" }}>
                                        <span className="block  text-sm font-medium text-slate-700">
                                            Expired on
                                        </span>
                                        <input
                                            ref={expiredOn}
                                            value={selectedDate}
                                            type="date"
                                            name="expiredOn"
                                            id="expiredOn"
                                            className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                                            required
                                            onChange={(e) => setSelectedDate(e.target.value)}
                                        />
                                    </label>

                                </div>
                                {errorDate && <span className='p-2' style={{ color: '#f00' }}>Please start Date should be less than expire date</span>}

                                <div className='w-full flex flex-col sm:flex-row justify-between'>
                                    <label className="block pr-3 w-full text-left" style={{ marginTop: "8px" }}>
                                        <span className="block w-full text-sm font-medium text-slate-700">
                                            Percentage Discount (%)
                                        </span>
                                        <input
                                            ref={value}
                                            defaultValue={promoCodeData?.value}
                                            type="number"
                                            name="value"
                                            id="value"
                                            className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                                            required
                                        />
                                    </label>
                                    <label className="block w-full  text-left" style={{ marginTop: "8px" }}>
                                        <span className="block  text-sm font-medium text-slate-700">
                                            Limit
                                        </span>
                                        <input
                                            defaultValue={promoCodeData?.limit}
                                            ref={limit}
                                            type="number"
                                            name="limit"
                                            id="limit"
                                            className="mt-1 p-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                                            required
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className='sm:flex justify-between'>
                            <label className="block pr-3 w-full sm:w-1/2 text-left" style={{ marginTop: "8px" }}>
                                <span className="block lg:w-1/2 text-sm font-medium text-slate-700">
                                    Branches
                                </span>
                                <select
                                    className=" form-select mt-1.5 w-full rounded-lg bg-slate-150 px-3 py-2 ring-primary/50 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:hover:bg-navy-900 dark:focus:bg-navy-900"
                                    onChange={(e) => {
                                        // setSelectedBranches((prev) => [...prev, { id: e.target.value, name: e.target.selectedOptions[0].textContent }])
                                        setBranchSelected(e)
                                    }}
                                >
                                    <option value="" disabled selected hidden>Select branch</option>
                                    <option value="all">All Branches</option>
                                    {
                                        branchesServices && branchesServices.length > 0 &&
                                        branchesServices.map((branch) => {
                                            return <option
                                                key={branch.id}
                                                value={branch?.id}
                                            >
                                                {branch?.name} ({branch?.address})
                                            </option>
                                        })
                                    }

                                </select>
                                {selectBranchError && selectedBranches.length <= 0 && <span style={{ color: '#f00' }}>Please Select Branch</span>}
                                {
                                    showSelectedOptions(
                                        "Branches",
                                        selectedBranches,
                                        handleRemoveBranch,
                                        handleRemoveAllBranch,
                                        isSelectedAllBranches
                                    )
                                }

                            </label>
                            <label className="block w-full sm:w-1/2 text-left" style={{ marginTop: "8px" }}>
                                <span className="block sm:w-1/2 text-sm font-medium text-slate-700">
                                    Services
                                </span>
                                <select
                                    className="form-select mt-1.5 w-full rounded-lg bg-slate-150 px-3 py-2 ring-primary/50 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:hover:bg-navy-900 dark:focus:bg-navy-900"
                                    onChange={(e) => {
                                        // setSelectedServices((prev) => [...prev, { id: e.target.value, name: e.target.selectedOptions[0].textContent }])
                                        setServiceSelected(e)
                                    }}
                                >
                                    <option value="" disabled selected hidden>Select Services</option>
                                    <option value="all">All Services</option>
                                    {branchesServices &&
                                        branchesServices.length > 0 &&
                                        branchesServices.map((branch) => {
                                            return branch.service.map((s) => (
                                                <option key={s.id} value={s.id}>
                                                    {s.name}
                                                </option>
                                            ));
                                        })}
                                </select>

                                {selectServiceError && selectedServices.length <= 0 && <span style={{ color: '#f00' }}>Please Select Service</span>}

                                {
                                    showSelectedOptions(
                                        "Services",
                                        selectedServices,
                                        handleRemoveService,
                                        handleRemoveAllService,
                                        isSelectedAllServices
                                    )
                                }
                            </label>
                        </div>

                        <div>
                            {/* <label className="block pr-3 w-full sm:w-1/2 text-left" style={{ marginTop: "8px" }}>
                                <span className="block lg:w-1/2 text-sm font-medium text-slate-700">
                                    Upload Banner
                                </span>
                                <div
                                    className="btn h-12 w-full   min-w-[7rem] border font-medium text-slate-800 bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                                    style={{ border: "dashed" }}
                                    id="btn1"
                                    alt="avatar"
                                    onClick={(e) => { e.preventDefault(); setImageResizer(true); }}
                                >
                                    Upload Image (1040px X 694px)
                                </div>
                                {imageResizer && (
                                    <ImageResize
                                        loader={loader}
                                        aspectRatio={1 / .1}
                                        imageModalClose={() => imageModalClose(false)}
                                        changeImage={changeImage}
                                        saveImageAdded={() => uploadFile(false)}
                                    />
                                )}
                                {
                                    imageUrl ?
                                        <img src={imageUrl} alt="Selected" style={{ maxWidth: '100%' }} /> :
                                        promoCodeData?.banner ? <img src={promoCodeData?.banner} alt="Selected" style={{ maxWidth: '100%' }} /> :
                                            null
                                }
                            </label> */}
                        </div>
                        <div className='mt-10'>
                            <button
                                type='submit'
                                className='btn min-w-[7rem] border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90'
                            >
                                Submit
                            </button>
                        </div>
                    </form>


                </div>
            }
        </>

    )
}

export default EditPromoCode