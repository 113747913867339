import React from "react";
import swal from "sweetalert";
import axios from "axios";
import configData from "../../utils/constants/config.json";
import moment from "moment";
import { ExportToExcel } from "../../utils/helpers/utilityFunctions";

export default class SoldBundles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tacbleAction: "popper-root",
      bundles: [],
      page: 1,
      bundlesoldCount: 0,
      pageRecordCount: 5,
      totalPages: 0,
      showDropdown: false,
      searchtext: "",
      branchFilter: 0,
      branchList: []
    };
  }
  couponsoldListAPICall(searchText) {
    axios({
      method: "get",
      url: configData.SERVER_URL +
      `partner/bundle/getSoldBundles/${this.state.page}/${this.state.pageRecordCount}?search=${searchText}&branch=${this.state.branchFilter}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        accesstoken: configData.ACCESSTOKEN,
        logintoken: localStorage.getItem("loginToken"),
      },
    })
      .then((resp) => {
        if (parseInt(Object.keys(resp.data)[0]) === 200) {
        console.log("resp.data.bundle",resp.data.bundle)
          this.setState({
            bundles: resp.data.bundle,
            page: resp.data.page,
            bundlesoldCount: resp.data.bundlesCount,
            pageRecordCount: resp.data.pageRecordCount,
            totalPages: resp.data.totalPages,
            branchList: resp.data.branches
          });
        }
      })
      .catch((err) => {
        swal({
          title: "Server Not Responding",
          text: "Please try again later",
          icon: "warning",
          button: "ok",
        });
        console.log(err);
      });
  }
  componentDidMount() {
    document.body.classList.add("is-sidebar-open");
    this.couponsoldListAPICall("");
  }
  // componant did update and will mount when change the searchtext
  componentDidUpdate(prevProps, prevState) {
    if (prevState.searchtext !== this.state.searchtext || prevState.branchFilter !== this.state.branchFilter) {
      console.log(this.state.searchtext)
      this.couponsoldListAPICall(this.state.searchtext);
    }
  }
  handlePageChange = (e) => {
    e.preventDefault();
    axios({
      method: "get",
      url: configData.SERVER_URL +
        `partner/bundle/getSoldBundles/${e.currentTarget.getAttribute("data-page")}/${this.state.pageRecordCount}?search=${this.state.searchtext}&branch=${this.state.branchFilter}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        accesstoken: configData.ACCESSTOKEN,
        logintoken: localStorage.getItem("loginToken"),
      },
    })
      .then((resp) => {
        console.log('aaaaaaa',resp.data);
        if (parseInt(Object.keys(resp.data)[0]) === 200) {
          this.setState({
            bundles: resp.data.bundle,
            page: resp.data.page,
            bundlesoldCount: resp.data.bundlesCount,
            pageRecordCount: resp.data.pageRecordCount,
            totalPages: resp.data.totalPages,
            branchList: resp.data.branches
          });
        }
      })
      .catch((err) => {
        swal({
          title: "Server Not Responding",
          text: "Please try again later",
          icon: "warning",
          button: "ok",
        });
        console.log(err);
      });
  };

  handleRecordCountChange = (e) => {
    e.preventDefault();
    axios({
      method: "get",
      url: configData.SERVER_URL +
        `partner/bundle/getSoldBundles/${1}/${e.currentTarget.value}?search=${this.state.searchtext}&branch=${this.state.branchFilter}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        accesstoken: configData.ACCESSTOKEN,
        logintoken: localStorage.getItem("loginToken"),
      },
    })
      .then((resp) => {
        console.log(resp.data);
        if (parseInt(Object.keys(resp.data)[0]) === 200) {
          this.setState({
            bundles: resp.data.bundle,
            page: resp.data.page,
            bundlesoldCount: resp.data.bundlesCount,
            pageRecordCount: resp.data.pageRecordCount,
            totalPages: resp.data.totalPages,
            branchList: resp.data.branches
          });
        }
      })
      .catch((err) => {
        swal({
          title: "Server Not Responding",
          text: "Please try again later",
          icon: "warning",
          button: "ok",
        });
        console.log(err);
      });
  };

  onToggletableAction = (e) => {
    if (this.state.tacbleAction == "popper-root") {
      this.setState({
        tacbleAction: "popper-root show",
      });
    } else {
      this.setState({
        tacbleAction: "popper-root",
      });
    }
  };

  pagination() {
    if (this.state.page == 1) {
      return (
        <ol class="pagination">
          <li class="bg-slate-150 dark:bg-navy-500">
            <button
              data-page="1"
              onClick={this.handlePageChange}
              class="flex h-8 min-w-[2rem] items-center justify-center rounded-lg bg-primary px-3 leading-tight text-white transition-colors hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
            >
              1
            </button>
          </li>
          {this.state.totalPages > 1 ? (
            <li class="bg-slate-150 dark:bg-navy-500">
              <button
                data-page="2"
                onClick={this.handlePageChange}
                class="flex h-8 min-w-[2rem] items-center justify-center rounded-lg px-3 leading-tight transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
              >
                2
              </button>
            </li>
          ) : null}
          <li class="rounded-r-lg bg-slate-150 dark:bg-navy-500">
            <button
              data-page={this.state.totalPages}
              onClick={this.handlePageChange}
              class="flex h-8 w-8 items-center justify-center rounded-lg text-slate-500 transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:text-navy-200 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </button>
          </li>
        </ol>
      );
    } else {
      if (this.state.page == this.state.totalPages) {
        return (
          <ol class="pagination">
            <li class="rounded-l-lg bg-slate-150 dark:bg-navy-500">
              <button
                data-page="1"
                onClick={this.handlePageChange}
                class="flex h-8 w-8 items-center justify-center rounded-lg text-slate-500 transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:text-navy-200 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>
            </li>
            <li class="bg-slate-150 dark:bg-navy-500">
              <button
                data-page={parseInt(this.state.page) - 1}
                onClick={this.handlePageChange}
                class="flex h-8 min-w-[2rem] items-center justify-center rounded-lg px-3 leading-tight transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
              >
                {parseInt(this.state.page) - 1}
              </button>
            </li>
            <li class="bg-slate-150 dark:bg-navy-500">
              <button
                data-page={this.state.page}
                onClick={this.handlePageChange}
                class="flex h-8 min-w-[2rem] items-center justify-center rounded-lg bg-primary px-3 leading-tight text-white transition-colors hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
              >
                {this.state.page}
              </button>
            </li>
          </ol>
        );
      } else {
        return (
          <ol class="pagination">
            <li class="rounded-l-lg bg-slate-150 dark:bg-navy-500">
              <button
                data-page="1"
                onClick={this.handlePageChange}
                class="flex h-8 w-8 items-center justify-center rounded-lg text-slate-500 transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:text-navy-200 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>
            </li>
            <li class="bg-slate-150 dark:bg-navy-500">
              <button
                data-page={parseInt(this.state.page) - 1}
                onClick={this.handlePageChange}
                class="flex h-8 min-w-[2rem] items-center justify-center rounded-lg px-3 leading-tight transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
              >
                {parseInt(this.state.page) - 1}
              </button>
            </li>
            <li class="bg-slate-150 dark:bg-navy-500">
              <button
                data-page={this.state.page}
                onClick={this.handlePageChange}
                class="flex h-8 min-w-[2rem] items-center justify-center rounded-lg bg-primary px-3 leading-tight text-white transition-colors hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
              >
                {this.state.page}
              </button>
            </li>
            <li class="bg-slate-150 dark:bg-navy-500">
              <button
                data-page={parseInt(this.state.page) + 1}
                onClick={this.handlePageChange}
                class="flex h-8 min-w-[2rem] items-center justify-center rounded-lg px-3 leading-tight transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
              >
                {parseInt(this.state.page) + 1}
              </button>
            </li>
            <li class="rounded-r-lg bg-slate-150 dark:bg-navy-500">
              <button
                data-page={this.state.totalPages}
                onClick={this.handlePageChange}
                class="flex h-8 w-8 items-center justify-center rounded-lg text-slate-500 transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:text-navy-200 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </button>
            </li>
          </ol>
        );
      }
    }
  }

  handleToogleOptionMenu = () => {
    this.setState((prevState) => ({
      ...prevState,
      showDropdown: !prevState.showDropdown,
    }));
  };

  handleExportToExcel = () => {
    ExportToExcel(this.state.services, "ServicesSheet");
    this.setState((prevState) => ({
      ...prevState,
      showDropdown: false,
    }));
  };

  render() {
    return (
      <main class="main-content px-[var(--margin-x)] pb-8">
        <div class="items-center justify-between">
          <div class="flex items-center space-x-4 py-5 lg:py-6">
            <h2 class="text-xl font-medium text-slate-800 dark:text-navy-50 lg:text-2xl">
            Packages Sold
            </h2>
            <div class="hidden h-full py-1 sm:flex">
              <div class="h-full w-px bg-slate-300 dark:bg-navy-600"></div>
            </div>
            <ul class="hidden flex-wrap items-center space-x-2 sm:flex">
              <li class="flex items-center space-x-2">
                <a
                  class="text-primary transition-colors hover:text-primary-focus dark:text-accent-light dark:hover:text-accent"
                  
                >
                  Sales
                </a>
                <svg
                  x-ignore
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </li>
              <li>Packages Sold</li>
            </ul>
          </div>
          {/* <div class="flex items-center -mt-2 justify-end gap-3">
            <div className="flex flex-col justify-center items-end relative">
              <button
                onClick={() => this.handleToogleOptionMenu()}
                className="btn border-[1px] border-gray-400 flex item-center gap-2
                base-btn  hover:bg-gray-200 dark:text-navy-50"
                style={{ backgroundColor: "#b9b9b947" }}
              >
                <span className="text-black font-bold">Options</span>
                <HiChevronDown className="mt-1 text-black font-bolder" />
              </button>

              {this.state.showDropdown && (
                <ul style={{background:"#e7e8ea" , 
                            position: "absolute",
                            padding: "8px 6px",
                            top: "37px",
                            borderBottom: "1px solid",}}
                          className="  z-10 absolute top-14 min-w-[140px] text-center border-[1px] border-slate-400 rounded">
                  <li
                    onClick={() => this.handleExportToExcel()}
                    className="base-btn hover:bg-gray-200 dark:text-navy-50 cursor-pointer"
                  >
                    <span className="text-black font-medium">
                      Export as Excel
                    </span>
                  </li>
                </ul>
              )}
            </div>
          </div> */}
        </div>

        <div class="grid grid-cols-1 mt-3 gap-4 sm:gap-5 lg:gap-6">
          <div>
            <div class="flex items-center justify-between bg-slate-200 mt-6 p-4 lg:p-5 rounded-lg">
              <div
                class="flex"
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "13px",
                  padding: "8px",
                  marginRight: "auto",
                  marginLeft: "11px",
                  background:"white"
                }}
              >
                <div class="flex items-center" x-data="{isInputActive:false}">
                  <label class="block">
                    <input
                      x-effect="isInputActive === true && $nextTick(() => { $el.focus()});"
                      class="form-input bg-transparent px-1 text-left transition-all duration-100 placeholder:text-slate-500 dark:placeholder:text-navy-200"
                      placeholder="Search by name..."
                      type="text"
                      onChange={(event) =>
                        this.setState({ searchtext: event.target.value })
                      }
                    />
                  </label>
                </div>
              </div>
              <div className="flex icon-btn px-5 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90">
                <span className="w-60 mt-2  md:inline-flex text-slate-800 dark:text-navy-50 font-bold text-sm lg:text-base">
                  Select Branch
                </span>
                <select
                  value={this.state.branchFilter}
                  onChange={(e)=> this.setState({branchFilter: e.currentTarget.value})}
                  className="block appearance-none text-gray-600 w-full bg-white border border-gray-400 shadow-inner px-4 py-2 pr-8 rounded"
                >
                <option id={0} value={0}>
                  Select Branch
                </option>
                  {this.state.branchList?.map(({ id, name }, key) => {
                    return (
                      <option id={key} value={id}>
                        {name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div class="card mt-3">
              <div
                class="is-scrollbar-hidden min-w-full overflow-x-auto"
                x-data="pages.tables.initExample1"
              >
                <table class="is-hoverable w-full text-left">
                  <thead>
                    <tr>
                      <th class="whitespace-nowrap rounded-tl-lg bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                        #
                      </th>
                      <th class="whitespace-no
                      wrap rounded-tr-lg bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                        Payment Date
                      </th>
                      <th class="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                        Client
                      </th>
                      <th class="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                        Email
                      </th>
                      <th class="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                        Phone Number
                      </th>
                      <th class="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                        Coupon Title
                      </th>
                      {/* <th class="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                        Redeemed
                      </th> */}
                      <th class="whitespace-nowrap rounded-tr-lg bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                        Payment Method
                      </th>
                      <th class="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.bundles.map((bundle, index) => (
                      <tr class="border-y border-transparent border-b-slate-200 dark:border-b-navy-500">
                        <td class="whitespace-nowrap px-4 py-3 sm:px-5">
                          {((this.state.page - 1) * this.state.pageRecordCount) + (index + 1)}
                        </td>
                        <td class="whitespace-nowrap px-4 py-3 sm:px-5">
                          {moment(bundle.createdAt).format('YYYY-MM-DD hh:MM A')}
                        </td>
                        <td class="whitespace-nowrap px-4 py-3 sm:px-5">
                          {bundle.customer.firstname + " " + bundle.customer.lastname}
                        </td>
                        <td class="whitespace-nowrap px-3 py-3 font-medium text-slate-700 dark:text-navy-100 lg:px-5">
                          {bundle.customer.email}
                        </td>
                        <td class="whitespace-nowrap px-4 py-3 sm:px-5">
                          {bundle.customer.phone}
                        </td>
                        <td class="whitespace-nowrap px-4 py-3 sm:px-5">
                          {bundle.bundle.name}
                        </td>
                        {/* <td class="whitespace-nowrap px-4 py-3 sm:px-5"> */}
                          {/* {coupon.redemmed? "Redeemed" : "Available"} */}
                        {/* </td> */}
                        <td class="whitespace-nowrap px-4 py-3 sm:px-5">
                          {bundle?.mop}
                        </td>
                        <td class="whitespace-nowrap px-4 py-3 sm:px-5">
                          {bundle.bill} AED
                        </td>
                       
                       
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div class="flex flex-col justify-between space-y-4 px-4 py-4 sm:flex-row sm:items-center sm:space-y-0 sm:px-5">
                <div class="flex items-center space-x-2 text-xs+">
                  <span>Show</span>
                  <label class="block">
                    <select
                      onChange={this.handleRecordCountChange}
                      class="form-select rounded-full border border-slate-300 bg-white px-2 py-1 pr-6 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                    </select>
                  </label>
                  <span>entries</span>
                </div>

                {this.pagination()}

                <div class="text-xs+">
                  {(this.state.page - 1) * this.state.pageRecordCount} -{" "}
                  {this.state.page * this.state.pageRecordCount} of{" "}
                  {this.state.couponsoldCount} entries
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
