import React from 'react';

export default class PrivacyPolicy extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <>
                <main class="p-4 px-8">
                    <div class="mx-4 p-6 grid grid-cols-1 gap-4 sm:gap-5 lg:grid-cols-1 lg:gap-6 text-center">
                        <div class="card rounded-2xl px-8 py-8 sm:px-5">
                            <div>
                                <h1 class="text-5xl font-medium tracking-wide text-slate-700 dark:text-navy-100">Privacy Policy</h1>
                            </div>
                            <div class="pt-2 px-8 sm:px-0 text-left">
                                <div class="pt-2">
                                    <p>This privacy policy ("Policy") explains and is intended to help you understand how the personally identifiable information ("Personal Information") we may collect from you through your use of the www.drclinica.com website ("Website" or "Service") and any of its related products and services (collectively, "Services") and, importantly, how your Personal Information is used and protected to ensure compliance with all applicable laws.</p>
                                {/* set the bellow text in div and p tag each line  */}
                                    <p> 
                                        The purpose of this "Policy" is to provide you with an understanding of how your "Personal Information" may be collected through your use of the "www.drclinica.com" website and its related "Services".
                                    </p>
                                    <p>
                                        Additionally, it outlines how your "Personal Information" is protected to ensure compliance with all applicable laws.
                                    </p>
                                    <p>
                                        Furthermore, this "Policy" explains the rights and choices you have concerning the use of your "Personal Information" and provides instructions on how to access, manage, update, export, and delete this information. As a "User" of the Website, it is important that you carefully read and consider this "Policy".
                                    </p>
                                    <p>
                                        It is important to note that this "Policy" does not apply to companies that we do not own or control, or to individuals that we do not employ or manage. Any "Personal Information" collected about you and processed following this "Privacy Policy" is subject to the laws of the United Arab Emirates.
                                    </p>
                                    <p>
                                        This Policy is a legally binding agreement between all Users and CLINICA MARKETING L.L.C Trade Name No. 2955544 Issued by the Department of Economy & Tourism Government of Dubai and registered office address "Sobha ivory tower 2, 704, Business Bay, Dubai, United Arab Emirates ("DrClinica", "we", "us" or "our").
                                    </p>
                                    <p>
                                        By accessing and using the Website and Services, you acknowledge that you have carefully read and fully understood this Policy, and you agree to be bound by its terms. If you do not agree with any provision of this Policy, you should immediately stop using the Website and Services. Your continued use of the Website and Services after any changes or revisions to this Policy constitutes your acceptance of such changes or revisions.
                                    </p>
                                </div>
                            </div>

                            <div class="pt-4 mt-4 px-8 sm:px-0 text-left">
                                <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">Automatic Collection of Information</h2>
                            </div>

                            <div class="pt-2 px-8 sm:px-0 text-left">
                                <div class="pt-2">
                                    <p>When you open the Website, our servers automatically record information sent by your browser such as :</p>
                                </div>
                                <div class="pt-2 px-5">
                                    <ul>
                                        <li style={{ listStyleType: "disc" }}>Location information such data may include country,  province, city, and neighborhood information. </li>
                                        <li style={{ listStyleType: "disc" }}>Time spent on those pages, the information you search for on the Website.</li>
                                        <li style={{ listStyleType: "disc" }}>Access times and dates.</li>
                                        <li style={{ listStyleType: "disc" }}> Webpage you were visiting before you came to the Website and  Services. </li>
                                        <li style={{ listStyleType: "disc" }}>How you found our website, including, whether you discovered us through a search engine, partner website, ad campaign, or email campaign. </li>
                                        <li style={{ listStyleType: "disc" }}> Internet Protocol (“IP”) address, browser type and version,  operating system type and version, and language preferences.</li>
                                        <li style={{ listStyleType: "disc" }}>The pages of the Website and Services that you visit.</li>
                                    </ul>
                                </div>
                                <div class="pt-2">
                                    <h4>Other statistics.</h4>
                                    <div class="pt-2">
                                        <p>The information collected automatically is utilized solely to identify any potential abuse of the system and for generating statistical information concerning the usage and traffic of the Website and Services. It is important to note that this statistical information is not otherwise compiled in a manner that would enable the identification of any particular User of the system.</p>
                                    </div>
                                </div>
                            </div>



                            <div class="pt-4 mt-4 px-8 sm:px-0 text-left">
                                <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">Collection of Personal Information </h2>
                            </div>
                            <div class="pt-2 px-8 sm:px-0 text-left">
                                <div class="pt-2">
                                    <p> It is possible to access and utilize the Website and Services without disclosing any information that could be used to identify you as a specific and identifiable individual. However, if you intend to utilize certain features on the Website, you may be required to provide specific Personal Information. This Personal Information may include the following</p>
                                </div>
                                <div class="pt-2 px-5">
                                    <ul>
                                        <li style={{ listStyleType: "disc" }}>Personal details and contact information such as your name, email address, mobile, country of residence, etc.  </li>
                                        <li style={{ listStyleType: "disc" }}>Account details such as your user name, unique user ID, password, etc. </li>
                                        <li style={{ listStyleType: "disc" }}>Financial information needs for payment processing purposes such as your credit or debit card number, expiration date, and card verification code, etc.</li>
                                        <li style={{ listStyleType: "disc" }}>Transaction details such as any bookings, rescheduling, redemptions, or  cancellations you action via the Website. </li>
                                        <li style={{ listStyleType: "disc" }}> Participation information such as any reviews or other methods of communication you make or submit via the Website. </li>
                                        <li style={{ listStyleType: "disc" }}>  Geolocation data such as your latitude and longitude.</li>
                                        <li style={{ listStyleType: "disc" }}>Any other materials you willingly submit to us such as articles, images, feedback, etc. </li>
                                    </ul>
                                </div>
                                <div class="pt-2">
                                    <p>
                                        When you create an account, make a purchase, publish content, or fill out online forms on the Website, any information you knowingly provide to us is received and stored by our system. Additionally, we may receive and collect Personal Information about you from other affiliated sources that you have registered with, including public databases, social media platforms, third-party data providers, and joint marketing partners. We may associate and use this information with other Personal Information we have collected from you. This information may include demographic information (such as age and gender), device information (such as IP addresses), location data (such as city and state), and online behavioral data (such as information about your use of social media websites, page view information, search results, and links).
                                    </p>

                                </div>
                                <div class="pt-2">
                                    <p>
                                        While you are not obligated to provide us with your Personal Information, if you choose not to do so, you may not be able to take advantage of some of the features on the Website. If you are unsure about what information is mandatory, please feel free to contact us.
                                    </p>
                                </div>

                                <div class="pt-4 mt-4 text-left">
                                    <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                                        Information About Others
                                    </h2>
                                </div>
                                <div className='pt-2'>
                                    <p>
                                        If you provide information about someone else while using the Website or Services, you must ensure that you are authorized to disclose such information to DrClinica and that DrClinica is not required to take any additional steps to ensure compliance with any applicable laws. If someone else has provided Personal Information about you or if you have provided Personal Information about someone else and you would like it to be deleted, please contact DrClinica to arrange for its removal.
                                    </p>
                                </div>
                                <div class="pt-4 mt-4 text-left">
                                    <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                                        Use and Processing of Collected Information
                                    </h2>
                                </div>
                                <div className='pt-2'>
                                    <p>
                                        Your Personal Information is used concerning our Website and Services  such uses may include
                                    </p>
                                </div>
                                <div className='pt-2 px-5'>
                                    <ul>
                                        <li style={{ listStyleType: "disc" }}>Facilitate, arrange, and deliver requested appointments from service providers.</li>
                                        <li style={{ listStyleType: "disc" }}>Track appointments and perform any necessary analytics.</li>
                                        <li style={{ listStyleType: "disc" }}>Improve our Website, services including analysis of your use of the Website and Services to improve the User's experience, displaying more relevant advertising and content.</li>
                                        <li style={{ listStyleType: "disc" }}>Providing support services to Users including responding to inquiries and feedback from Website Users.</li>
                                        <li style={{ listStyleType: "disc" }}>Provide marketing, promotional advertising, targeted advertising, offers,  discounts, and sales by way of electronic communication directly to the  User and/or via the Website;</li>
                                        <li style={{ listStyleType: "disc" }}>Communicating and providing you with additional information that may be of interest to you about DrClinica and our service providers.</li>
                                        <li style={{ listStyleType: "disc" }}>Responding and attending to customer testimonials, legal requests, and reports of online abusive behavior, trolling, and malicious use of the Website and/or  Services.</li>
                                        <li style={{ listStyleType: "disc" }}>We primarily use your Personal Information to provide you with access to our Website and Services. Additional uses of your Personal Information may include but are not limited to, managing our Website and Services, such as daily business operations, administration, fraud prevention, reporting, enforcement of terms of use, policies, procedures, and conditions, performing analytics, and complying with applicable laws. Any further use of your Personal Information will only be done if necessary to meet a legal obligation or comply with applicable laws. The processing of your Personal Information will depend on your interaction with our Website and Services, your location in the world, and whether one of the following applies.</li>
                                        <li style={{ listStyleType: "disc" }}>You have given your consent for one or more specific purposes.</li>
                                        <li style={{ listStyleType: "disc" }}>Provision of information is necessary for the performance of an agreement with you and/or for any pre-contractual obligations thereof.</li>
                                        <li style={{ listStyleType: "disc" }}>Processing is necessary for compliance with a legal obligation to which you are subject.</li>
                                        <li style={{ listStyleType: "disc" }}>Processing is related to a task that is carried out in the public interest or the exercise of official authority vested in us.</li>
                                        <li style={{ listStyleType: "disc" }}>Processing is necessary for the legitimate interests pursued by us or by a third party.</li>
                                        <li style={{ listStyleType: "disc" }}>It is important to note that certain legislations may permit us to continue processing information until you exercise your right to object, without relying on your consent or any other legal basis listed below. However, we are always willing to explain the specific legal basis that applies to your personal information and whether providing such information is a statutory or contractual requirement or necessary to enter into a contract.</li>
                                    </ul>
                                </div>

                                <div class="pt-4 mt-4 text-left">
                                    <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                                        Billing and Payments
                                    </h2>
                                </div>
                                    <div className='pt-2'>
                                        <p>
                                            In case of services requiring payment, we will request only the necessary credit card, debit card, or other payment account information, which will be used solely for the processing of payments.
                                        </p>
                                    
                                    
                                        <p>
                                            We do not store or retain any payment data received for your transaction.
                                        </p>
                                    
                                        <p>
                                            Any payment data provided is encrypted by way of a secure iFrame Tokenizer which converts your payment data into a string of randomly generated numbers called a ‘token’ which is utilized to obtain approval from your bank to transfer the necessary payment for any services. Tokenization is irreversible in the event of a data breach or other exposure.
                                        </p>
                                    
                                        <p>
                                            Sensitive and private data exchange happens over an SSL-secured communication channel and is encrypted and protected with digital signatures, and the Website and Services are also in compliance with PCI vulnerability standards to create as secure of an environment as possible for Users. Scans for malware are performed regularly for additional security and protection.
                                        </p>
                                    
                                        <p>
                                            For services that require payment, we will only request necessary payment information such as credit card or debit card details, which will solely be used to process payments. We do not keep or store any payment information that is received for transaction purposes.
                                        </p>
                                    
                                        <p>
                                            To protect your payment information, we use a secure iFrame Tokenizer to encrypt the payment data into a string of randomly generated numbers called a ‘token’. This token is utilized to get approval from your bank for transferring the payment required for any services. In the case of a data breach or exposure, tokenization is irreversible.
                                        </p>
                                    
                                        <p>
                                            Sensitive and private data exchange occurs over an SSL-secured communication channel and is encrypted and protected with digital signatures. Our Website and Services comply with the PCI vulnerability standards to create a highly secure environment for Users. We conduct regular malware scans for additional security and protection.
                                        </p>
                                </div>
                            </div>

                            <div class="pt-4 mt-4 px-8 sm:px-0 text-left">
                                <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                                    Managing & Deletion of Information
                                </h2>
                            </div>
                            <div className='pt-2 px-8 sm:px-0'>
                                <p className='text-left'>
                                    At any time, you may choose to delete your account by accessing the settings page on our Website or by contacting us directly. Upon your deletion request, we will anonymize any Personal Information you have provided to us. This anonymized data will only be retained as necessary to fulfill our legal, financial, or auditing obligations, resolve disputes, or enforce our agreements. If permitted or required by law, we may retain this anonymized data for a longer period. We will not use this data for any other purpose beyond those stated above.
                                </p>
                            </div>
                            <div class="pt-4 mt-4 px-8 sm:px-0 text-left">
                                <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                                    Retention of Information
                                </h2>
                                {/* adjust the up content  */}
                                <div className='pt-2'>
                                    <p>
                                        Your Personal Information will be retained and utilized for as long as you continue to use our Website and Services. Additionally, we may retain and use it to fulfill any contractual obligations with you, comply with legal or auditing requirements, resolve disputes, and enforce our agreements. The retention period may be extended if required or permitted by law.

                                    </p>
                                </div>
                                <div class="pt-4 mt-4 text-left">
                                    <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                                        Managing & Deletion of Information
                                    </h2>
                                    {/* adjust the up content  */}
                                    <div className='pt-2'>
                                        <p>
                                            At any time, you may choose to delete your account by accessing the settings page on our Website or by contacting us directly. Upon your deletion request, we will anonymize any Personal Information you have provided to us. This anonymized data will only be retained as necessary to fulfill our legal, financial, or auditing obligations, resolve disputes, or enforce our agreements. If permitted or required by law, we may retain this anonymized data for a longer period. We will not use this data for any other purpose beyond those stated above.
                                        </p>
                                    </div>
                                    <div class="pt-4 mt-4 text-left">
                                        <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                                            Retention of Information
                                        </h2>
                                        {/* adjust the up content  */}
                                        <div className='pt-2'>
                                            <p>
                                                Your Personal Information will be retained and utilized for as long as you continue to use our Website and Services. Additionally, we may retain and use it to fulfill any contractual obligations with you, comply with legal or auditing requirements, resolve disputes, and enforce our agreements. The retention period may be extended if required or permitted by law.
                                            </p>
                                        
                                            <p>
                                                Any aggregated data derived from your Personal Information may be used after you update or delete it, but not in a manner that would personally identify you.
                                            </p>
                                            <p>
                                                Upon expiration of the retention period, your Personal Information will be deleted, and therefore, you will not be able to exercise your right to access, erasure, rectification, or data portability.
                                            </p>
                                        </div>
                                        <div class="pt-4 mt-4 text-left">
                                            <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                                                Disclosure of Information
                                            </h2>
                                            <div className='pt-2'>
                                                <p>
                                                    We do not engage in the sharing, selling, trading, or renting of any Personal Information with unaffiliated third parties. However, depending on the services you request or as necessary to complete a transaction or provide a service, we may partner with other companies and share your information (with your consent) with our trusted third-party affiliates and subsidiaries that assist us with our operations. These third-party affiliates and subsidiaries may include payment processors, cloud service providers, advertising networks, analytics providers, domain registrars, and other similar service providers.
                                                </p>
                                                <p>
                                                    we only share your Personal Information with third parties as described in this Privacy Policy. We do not sell your Personal Information to third parties.
                                                </p>
                                                <p>
                                                    Any contracted third party or service provider is not authorized to use or disclose your information, except as necessary to perform services on our behalf or comply with legal requirements.
                                                </p>
                                                <p>
                                                    We will disclose any Personal Information we collect, use, or receive if required or permitted by law (including to our professional or legal advisors), such as complying with a court order, subpoena, or similar legal process, or when we believe in good faith that disclosure is necessary to protect our rights, your safety, or the safety of others, investigate fraud, or respond to a government request.
                                                </p>
                                                <p>
                                                    If we undergo a business transition, such as a merger, acquisition by another company, or sale of all or a portion of our assets, your user account and Personal Information may be among the assets transferred. 
                                                </p>
                                            </div>
                                        </div>

                                        <div class="pt-4 mt-4 text-left">
                                            <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                                                Transfer of Information
                                            </h2>
                                            <div className='pt-2'>
                                                <p>
                                                Data transfers may require the transfer and storage of your information in a country other than your own, depending on your location. If information is transferred to a country outside the United Arab Emirates or any other jurisdiction, country, or international organization governed by public international law, such as the UN, you are entitled to learn about the legal basis of such transfers and the security measures taken by us to protect your information. Relevant sections of this Policy provide more information about such transfers, or you can contact us using the information provided in the contact section. Whenever we transfer personal information, we take all reasonably necessary steps to ensure that the information is treated securely and in compliance with applicable data protection laws.
                                                </p>
                                            </div>
                                        </div>

                                        <div class="pt-4 mt-4 text-left">
                                            <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                                                The Rights of Users
                                            </h2>
                                            <div className='pt-2'>
                                                <p>
                                                    You may exercise certain rights regarding your Personal Information processed by us. 
                                                    In particular, you have the right to do the following
                                                </p>
                                                <div className='pt-2 px-5'>
                                                <p>
                                                    <ul>
                                                        <li style={{ listStyleType: "disc" }}>You have the right to withdraw consent where you have previously given your consent to the processing of your Personal Information.</li>
                                                        <li style={{ listStyleType: "disc" }}>You have the right to object to the processing of your Personal Information if the processing is carried out on a legal basis other than consent.</li>
                                                        <li style={{ listStyleType: "disc" }}>You have the right to learn if Personal Information is being stored and/or processed by us, to obtain disclosure regarding certain aspects of the processing, and obtain a copy of the Personal Information undergoing processing.</li>
                                                        <li style={{ listStyleType: "disc" }}>You have the right to verify the accuracy of your Personal Information and ask for it to be updated or corrected.</li>
                                                        <li style={{ listStyleType: "disc" }}>You have the right, under certain circumstances, to restrict or object to the processing of your Personal Information (including for marketing, profiling, or a third party’s interest), in which case, we will not process your Personal Information for any purpose other than storing it.</li>
                                                        <li style={{ listStyleType: "disc" }}>You have the right, under certain circumstances, to be forgotten and require the erasure of your Personal Information from us.</li>
                                                        <li style={{ listStyleType: "disc" }}>You have the right to receive your Personal Information in a structured, commonly used, and machine-readable format and, if technically feasible, to have it transmitted to another controller without any hindrance.</li>
                                                    </ul>
                                                </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="pt-4 mt-4 text-left">
                                            <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                                            The Right to Object to Processing
                                            </h2>
                                            <div className='pt-2'>
                                                <p>
                                                If your Personal Information is processed for the public interest, in the exercise of an official authority vested in us, or for our legitimate interests, you have the right to object to such processing by providing a reason related to your specific situation to justify the objection. However, if we process your Personal Information for direct marketing purposes, you can object to such processing at any time without having to provide any justification. 
                                                </p>
                                            </div>
                                        </div>

                                        <div class="pt-4 mt-4 text-left">
                                            <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                                                How To Exercise These Rights
                                            </h2>
                                            <div className='pt-2'>
                                                <p>
                                                    Requests to exercise your rights can be submitted to CLINICA MARKETING L.L.C. using the contact information provided in this document. Please be aware that we may request you to verify your identity before responding to your request. You need to provide enough information to enable us to authenticate your identity or your authority to act on behalf of the person concerned. Additionally, you should provide sufficient details about your request for us to comprehend it accurately and respond to it appropriately. Unless we have confirmed your identity or authorization to make such a request and verified that the Personal Information pertains to you, we are unable to fulfill your request or disclose Personal Information to you. 
                                                </p>
                                            </div>
                                        </div>

                                        <div class="pt-4 mt-4 text-left">
                                            <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                                                Minimum Age Requirements
                                            </h2>
                                            <div className='pt-2'>
                                                <p>
                                                    We do not intentionally collect Personal Information from individuals who are under 18 years old. If you are under 18 years old, please refrain from submitting any Personal Information through our Website and Services. If you believe that someone under 18 years old has provided us with their Personal Information through our Website and Services, please notify us immediately.
                                                    Privacy of Children: We advise parents and legal guardians to supervise and monitor their children's use of the internet and educate them to never share Personal Information through our Website and Services without their permission.
                                                </p>
                                            </div>
                                        </div>

                                        <div class="pt-4 mt-4 text-left">
                                            <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                                                Cookies
                                            </h2>
                                            <div className='pt-2'>
                                                <p>
                                                    The Website and Services utilize "cookies" to enhance and personalize your online experience. A cookie is a small text file that a web page server places on your hard drive. Cookies cannot be used to execute programs or deliver viruses to your computer. They are uniquely assigned to you and can only be read by a web server in the domain that issued the cookie.
                                                    We may use cookies for statistical purposes, to collect, store, and track information necessary to operate the Website and Services. You can accept or decline cookies. Although most web browsers automatically accept cookies, you can usually modify your browser settings to decline them. If you decline cookies, you may not have access to all the features of the Website and Services.
                                                </p>
                                            </div>
                                        </div>

                                        <div class="pt-4 mt-4 text-left">
                                            <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                                                Do Not Track Signals
                                            </h2>
                                            <div className='pt-2'>
                                                <p>
                                                Certain web browsers offer a Do Not Track feature, which indicates to websites that you do not want your online activities to be tracked. It's important to note that tracking is different from collecting information in connection with a website. Tracking refers to the collection of personally identifiable information from consumers who use or visit a website or online service as they move across various websites over time.
                                                </p>
                                            <div className='pt-2'>
                                                <p>
                                                Our Website and Services do not track visitors over time and across third-party websites. However, some third-party sites may track your browsing activities when serving you content, enabling them to personalize their content for you.
                                                </p>
                                            </div>
                                            <div className='pt-2'>
                                                <p>
                                                    We may share aggregated and non-identifying information about our customers, collected through the use of our Website and Services, to better understand how our customers use our website, create performance metrics, and customize our website to better suit our customers. We do not share personally identifiable information about individual customers with advertisers. 
                                                </p>
                                            </div>
                                        </div>

                                        <div class="pt-4 mt-4 text-left">
                                            <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                                                Affiliates & Advertising
                                            </h2>
                                            <div className='pt-2'>
                                                <p>
                                                We may participate in affiliate marketing and display third-party online advertisements on our Website and Services. Our affiliates may have links on our Website, and third-party companies may help us tailor advertising that we believe may be of interest to our users, as well as collect and use other data about user activities on the Website. These companies may use cookies to track user behavior and deliver ads that are targeted to the user's interests.
                                                </p>
                                            </div>
                                            <div className='pt-2'>
                                                <p>
                                                If you click on an affiliate link or third-party advertisement, a third-party cookie may be placed on your browser to track any resulting sales for commission purposes. These third-party cookies automatically collect information about your visit to our Website, including your IP address, ISP, and browser. The collected information may be used to deliver advertising targeted to your interests and to better understand the usage and visitation patterns of our platform and Websites. 
                                                </p>
                                            </div>
                                        </div>

                                        <div class="pt-4 mt-4 text-left">
                                            <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                                             Information Security
                                            </h2>
                                            <div className='pt-2'>
                                                <p>
                                                We take reasonable measures to secure the information you provide on our computer servers in a controlled and secure environment to prevent unauthorized access, use, or disclosure. We have implemented administrative, technical, and physical safeguards to protect your Personal Information from unauthorized access, use, modification, or disclosure. However, please be aware that no method of data transmission over the Internet or wireless network can be 100% secure, and we cannot guarantee the absolute security of your Personal Information.
                                                </p>
                                            </div>
                                            <div className='pt-2'>
                                                <p>
                                                Therefore, while we strive to protect your Personal Information, you acknowledge  that:
                                                </p>
                                            </div>
                                            <div className='pt-2 px-5'>
                                                <p>
                                                    <ul>
                                                        <li style={{ listStyleType: "disc" }}>There exist security and privacy limitations of the Internet that are outside of our jurisdiction.</li>
                                                        <li style={{ listStyleType: "disc" }}>The security, integrity, and privacy of any information and data exchanged between you and the Website and Services cannot be guaranteed.</li>
                                                        <li style={{ listStyleType: "disc" }}>Any such information and data may be viewed or tampered with in transit by a third party, despite best efforts.</li>
                                                    </ul>
                                                </p>
                                            </div>
                                        </div>

                                        <div class="pt-4 mt-4 text-left">
                                            <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                                                Links to Third Party Websites and Services
                                            </h2>
                                            <div className='pt-2'>
                                                <p>
                                                    The Website and Services may provide links to external resources that are not under our ownership or control. Please note that we do not have control over the privacy practices or policies of such resources or third parties. Therefore, we recommend that you exercise caution and review the privacy statements of every external resource that may collect Personal Information before interacting with them. It's important to note that we are not liable for the privacy policies or practices of any third-party websites.
                                                </p>
                                            </div>
                                        </div>

                                        <div class="pt-4 mt-4 text-left">
                                            <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                                                Data Breach
                                            </h2>
                                            <div className='pt-2'>
                                                <p>
                                                    If we become aware of any external activity compromising the security of the Website and Services, or if the Personal Information of our users has been disclosed to unrelated third parties, we will take appropriate measures. These may include investigating the incident, reporting it to the relevant authorities, and cooperating with law enforcement. In case of a data breach that poses a risk of harm to our users or is required by law, we will make reasonable efforts to notify the affected individuals. We will notify you via email.
                                                </p>
                                            </div>
                                        </div>

                                        <div class="pt-4 mt-4 text-left">
                                            <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                                                Changes and Amendments
                                            </h2>
                                            <div className='pt-2'>
                                                <p>
                                                    We may update this Policy or its terms related to the Website and Services at any time at our discretion. If we make significant changes to how we process your information, we will notify you via email and ask for your consent to such changes as required by law. We will also update the date on this page and may provide notice through other means such as the contact information you have provided. The updated Policy will be effective immediately upon posting unless otherwise specified. Your continued use of the Website and Services after the effective date of the revised Policy (or such other act specified at that time) will constitute your consent to the changes. However, we will not use your Personal Information in a way materially different from what was stated at the time it was collected without your consent. 
                                                </p>
                                            </div>
                                        </div> 

                                        <div class="pt-4 mt-4 text-left">
                                            <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                                                Acceptance of this Policy
                                            </h2>
                                            <div className='pt-2'>
                                                <p>
                                                    By accessing and using the Website and Services, you confirm that you have read and understood this Policy and agree to all its terms and conditions. Your continued use of the Website and Services constitutes your acceptance of this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to access or use the Website and Services.
                                                </p>
                                            </div>
                                        </div> 

                                        <div class="pt-4 mt-4 text-left">
                                            <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                                                Contacting Us
                                            </h2>
                                            <div className='pt-2'>
                                                <p>
                                                    If you have any questions or concerns about this Policy, or if you would like to exercise any of your rights related to your Personal Information, please feel free to contact us at hello@drclinica.com. We will make reasonable efforts to address your inquiry in a timely and professional manner. This Policy was last updated on April 16, 2023.
                                                </p>
                                            </div>
                                        </div>                      

                                    </div>
                                </div>
                            </div>
                            {/* Adjust structur the bellow content in html bellow
                            
                            Retention of Information 
                            Your Personal Information will be retained and utilized for as long as you continue to use our Website and Services. Additionally, we may retain and use it to fulfill any contractual obligations with you, comply with legal or auditing requirements, resolve disputes, and enforce our agreements. The retention period may be extended if required or permitted by law.
                            Any aggregated data derived from your Personal Information may be used after you update or delete it, but not in a manner that would personally identify you.
                            Upon expiration of the retention period, your Personal Information will be deleted, and therefore, you will not be able to exercise your right to access, erasure, rectification, or data portability.

                            Disclosure of Information 
                            We do not engage in the sharing, selling, trading, or renting of any Personal Information with unaffiliated third parties. However, depending on the services you request or as necessary to complete a transaction or provide a service, we may partner with other companies and share your information (with your consent) with our trusted third-party affiliates and subsidiaries that assist us in operating the Website and providing services to you.
                            We only share your Personal Information with third parties whose privacy policies align with ours, or who agree to adhere to our policies regarding Personal Information. These third parties are granted access only to the Personal Information necessary to perform their designated functions and are not authorized to use or disclose Personal Information for their purposes.
                            Any contracted third party or service provider is not authorized to use or disclose your information, except as necessary to perform services on our behalf or comply with legal requirements.
                            We will disclose any Personal Information we collect, use, or receive if required or permitted by law (including to our professional or legal advisors), such as complying with a court order, subpoena, or similar legal process, or when we believe in good faith that disclosure is necessary to protect our rights, your safety, or the safety of others, investigate fraud, or respond to a government request.
                            If we undergo a business transition, such as a merger, acquisition by another company, or sale of all or a portion of our assets, your user account and Personal Information may be among the assets transferred. 

                            Transfer of Information 
                            Data transfers may require the transfer and storage of your information in a country other than your own, depending on your location. If information is transferred to a country outside the United Arab Emirates or any other jurisdiction, country, or international organization governed by public international law, such as the UN, you are entitled to learn about the legal basis of such transfers and the security measures taken by us to protect your information. Relevant sections of this Policy provide more information about such transfers, or you can contact us using the information provided in the contact section. Whenever we transfer personal information, we take all reasonably necessary steps to ensure that the information is treated securely and in compliance with applicable data protection laws.
                            The Rights of Users 
                            You may exercise certain rights regarding your Personal Information processed by us. 
                            In particular, you have the right to do the following
                            • You have the right to withdraw consent where you have previously given your consent to the processing of your Personal Information.
                            • You have the right to object to the processing of your Personal Information if the processing is carried out on a legal basis other than consent.
                            • You have the right to learn if Personal Information is being stored and/or processed by us, to obtain disclosure regarding certain aspects of the processing, and obtain a copy of the Personal Information undergoing processing.
                            • You have the right to verify the accuracy of your Personal Information and ask for it to be updated or corrected. 
                            • You have the right, under certain circumstances, to restrict or object to the processing of your Personal Information (including for marketing, profiling, or a third party’s interest), in which case, we will not process your  Personal Information for any purpose other than storing it. 
                            • You have the right, under certain circumstances, to be forgotten and require the erasure of your Personal Information from us.
                            • You have the right to receive your Personal Information in a structured,  commonly used, and machine-readable format and, if technically feasible, to have it transmitted to another controller without any hindrance. 
                            You have the right to exercise the above-mentioned rights concerning your Personal Information, but they are only applicable if your information is processed through automated means and the processing is based on your consent, a contract you are a part of or pre-contractual obligations. Please note that there may be circumstances where we cannot fulfill your request or instruction about these rights, such as when your Personal Information no longer exists, or there is an exception that applies, such as an ongoing legal dispute or service. 

                            The Right to Object to Processing 
                            If your Personal Information is processed for the public interest, in the exercise of an official authority vested in us, or for our legitimate interests, you have the right to object to such processing by providing a reason related to your specific situation to justify the objection. However, if we process your Personal Information for direct marketing purposes, you can object to such processing at any time without having to provide any justification. 

                            How To Exercise These Rights 
                            Requests to exercise your rights can be submitted to CLINICA MARKETING L.L.C. using the contact information provided in this document. Please be aware that we may request you to verify your identity before responding to your request. You need to provide enough information to enable us to authenticate your identity or your authority to act on behalf of the person concerned. Additionally, you should provide sufficient details about your request for us to comprehend it accurately and respond to it appropriately. Unless we have confirmed your identity or authorization to make such a request and verified that the Personal Information pertains to you, we are unable to fulfill your request or disclose Personal Information to you. 

                            Minimum Age Requirements 
                            We do not intentionally collect Personal Information from individuals who are under 18 years old. If you are under 18 years old, please refrain from submitting any Personal Information through our Website and Services. If you believe that someone under 18 years old has provided us with their Personal Information through our Website and Services, please notify us immediately.
                            Privacy of Children: We advise parents and legal guardians to supervise and monitor their children's use of the internet and educate them to never share Personal Information through our Website and Services without their permission.

                            Cookies 
                            The Website and Services utilize "cookies" to enhance and personalize your online experience. A cookie is a small text file that a web page server places on your hard drive. Cookies cannot be used to execute programs or deliver viruses to your computer. They are uniquely assigned to you and can only be read by a web server in the domain that issued the cookie.
                            We may use cookies for statistical purposes, to collect, store, and track information necessary to operate the Website and Services. You can accept or decline cookies. Although most web browsers automatically accept cookies, you can usually modify your browser settings to decline them. If you decline cookies, you may not have access to all the features of the Website and Services.

                            Do Not Track Signals 
                            Certain web browsers offer a Do Not Track feature, which indicates to websites that you do not want your online activities to be tracked. It's important to note that tracking is different from collecting information in connection with a website. Tracking refers to the collection of personally identifiable information from consumers who use or visit a website or online service as they move across various websites over time.
                            Our Website and Services do not track visitors over time and across third-party websites. However, some third-party sites may track your browsing activities when serving you content, enabling them to personalize their content for you.
                            We may share aggregated and non-identifying information about our customers, collected through the use of our Website and Services, to better understand how our customers use our website, create performance metrics, and customize our website to better suit our customers. We do not share personally identifiable information about individual customers with advertisers. 

                            Affiliates & Advertising 
                            We may participate in affiliate marketing and display third-party online advertisements on our Website and Services. Our affiliates may have links on our Website, and third-party companies may help us tailor advertising that we believe may be of interest to our users, as well as collect and use other data about user activities on the Website. These companies may use cookies to track user behavior and deliver ads that are targeted to the user's interests.
                            If you click on an affiliate link or third-party advertisement, a third-party cookie may be placed on your browser to track any resulting sales for commission purposes. These third-party cookies automatically collect information about your visit to our Website, including your IP address, ISP, and browser. The collected information may be used to deliver advertising targeted to your interests and to better understand the usage and visitation patterns of our platform and Websites. 

                            Information Security 
                            We take reasonable measures to secure the information you provide on our computer servers in a controlled and secure environment to prevent unauthorized access, use, or disclosure. We have implemented administrative, technical, and physical safeguards to protect your Personal Information from unauthorized access, use, modification, or disclosure. However, please be aware that no method of data transmission over the Internet or wireless network can be 100% secure, and we cannot guarantee the absolute security of your Personal Information.
                            Therefore, while we strive to protect your Personal Information, you acknowledge  that:
                            • There exist security and privacy limitations of the Internet that are outside of our jurisdiction. 
                            • The security, integrity, and privacy of any information and data exchanged between you and the Website and Services cannot be guaranteed.
                            • Any such information and data may be viewed or tampered with in transit by a third party, despite best efforts.

                            Links to Third Party Websites and Services 
                            The Website and Services may provide links to external resources that are not under our ownership or control. Please note that we do not have control over the privacy practices or policies of such resources or third parties. Therefore, we recommend that you exercise caution and review the privacy statements of every external resource that may collect Personal Information before interacting with them. It's important to note that we are not liable for the privacy policies or practices of any third-party websites.

                            Data Breach 
                            If we become aware of any external activity compromising the security of the Website and Services, or if the Personal Information of our users has been disclosed to unrelated third parties, we will take appropriate measures. These may include investigating the incident, reporting it to the relevant authorities, and cooperating with law enforcement. In case of a data breach that poses a risk of harm to our users or is required by law, we will make reasonable efforts to notify the affected individuals. We will notify you via email.

                            Changes and Amendments 
                            We may update this Policy or its terms related to the Website and Services at any time at our discretion. If we make significant changes to how we process your information, we will notify you via email and ask for your consent to such changes as required by law. We will also update the date on this page and may provide notice through other means such as the contact information you have provided. The updated Policy will be effective immediately upon posting unless otherwise specified. Your continued use of the Website and Services after the effective date of the revised Policy (or such other act specified at that time) will constitute your consent to the changes. However, we will not use your Personal Information in a way materially different from what was stated at the time it was collected without your consent. 

                            Acceptance of this Policy 
                            By accessing and using the Website and Services, you confirm that you have read and understood this Policy and agree to all its terms and conditions. Your continued use of the Website and Services constitutes your acceptance of this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to access or use the Website and Services.

                            Contacting Us 
                            If you have any questions or concerns about this Policy, or if you would like to exercise any of your rights related to your Personal Information, please feel free to contact us at hello@drclinica.com. We will make reasonable efforts to address your inquiry in a timely and professional manner. This Policy was last updated on April 16, 2023.
                            " */}
    



















                        </div>
                    </div>
 
                </div>
                </main>
            </>

        )
    }
}
{/* // <main class="p-4 px-8">
            //     <div class="mx-4 p-4 grid grid-cols-1 gap-4 sm:gap-5 lg:grid-cols-1 lg:gap-6 text-center">
            //         <div class="card rounded-2xl px-4 py-4 sm:px-5">
            //             <div>
            //                 <h1 class="text-5xl font-medium tracking-wide text-slate-700 dark:text-navy-100">Privacy Policy</h1>
            //             </div>
            //             <div class="pt-2 text-left">
            //                 <div class="pt-2">
            //                     <p>This privacy policy ("Policy") explains and is intended to help you understand how the personally identifiable information ("Personal Information") we may collect from you through your use of the www.drclinica.com website ("Website" or "Service") and any of its related products and services (collectively, "Services") and, importantly, how your Personal Information is used and protected to ensure compliance with all applicable laws.</p>
            //                 </div>
            //                 <div class="pt-2">
            //                     <p>This Policy also explains your rights and choices available to you regarding our use of your Personal Information and how you can access, manage, update, export, and, if necessary, retrieve and delete this information.</p>
            //                 </div>
            //                 <div class="pt-2">
            //                     <p>This Policy should be read and considered closely by all visitors and users of the Website (hereinafter referred to as a “User,” “you,” or “your”).</p>
            //                 </div>
            //                 <div class="pt-2">
            //                     <p>This Policy does not apply to the practices of companies we do not own or control or individuals we do not employ or manage.</p>
            //                 </div>
            //                 <div class="pt-2">
            //                     <p>Personal Information gathered about you and processed in accordance with this Privacy Policy is controlled by the laws of the United Arab Emirates, including but not limited to Federal Law No. 3 of 1987, also known as the UAE Penal Code.</p>
            //                 </div>
            //                 <div class="pt-2">
            //                     <p>By accessing and using the Website and Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Policy.</p>
            //                 </div>
            //             </div>

            //             <div class="pt-4 mt-4 text-left">
            //                 <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">Automatic Collection of Information</h2>
            //             </div>


            //             <div class="pt-2 text-left">
            //                 <div class="pt-2">
            //                     <p>When you open the Website, our servers automatically record information that your browser sends to us</p>
            //                 </div>
            //                 <div class="pt-2">
            //                     <p>This data may include information such as:</p>
            //                 </div>
            //                 <div class="pt-2 ml-4">
            //                     <ul>
            //                         <li style={{listStyleType : "disc"}}>Your device's Internet Protocol (“IP”) address, browser type and version, operating system type and version, and language preferences.</li>
            //                         <li style={{listStyleType : "disc"}}>Location information where you have accessed the Website on a device that is transmitting location data, and such data may include country, province, city, and neighborhood information.</li>
            //                         <li style={{listStyleType : "disc"}}>The webpage you were visiting before you came to the Website and Services.</li>
            //                         <li style={{listStyleType : "disc"}}>How you found our website, including whether you discovered us through a search engine, partner website, ad campaign, or email campaign.</li>
            //                         <li style={{listStyleType : "disc"}}>The pages of the Website and Services that you visit.</li>
            //                         <li style={{listStyleType : "disc"}}>The time spent on those pages, the information you search for on the Website.</li>
            //                         <li style={{listStyleType : "disc"}}>The access times and dates and the other statistics.</li>
            //                     </ul>
            //                 </div>
            //                 <div class="pt-2">
            //                     <p>Information collected automatically is used only to identify potential cases of abuse and establish statistical information regarding the usage and traffic of the Website and Services. This statistical information is not otherwise aggregated in such a way that would identify any particular User of the system.</p>
            //                 </div>
            //             </div>

            //             <div class="pt-4 mt-4 text-left">
            //                 <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">Collection of Personal Information</h2>
            //             </div>


            //             <div class="pt-2 text-left">
            //                 <div class="pt-2">
            //                     <p>You can access and use the Website and Services without telling us who you are or revealing any information by which someone could identify you as a specific, identifiable individual.</p>
            //                 </div>
            //                 <div class="pt-2">
            //                     <p>If, however, you wish to use some of the features on the Website, you may be asked to provide certain Personal Information. This information may include the following:</p>
            //                 </div>
            //                 <div class="pt-2 ml-4">
            //                     <ul>
            //                         <li style={{listStyleType : "disc"}}>Personal details such as your name, country of residence, etc.</li>
            //                         <li style={{listStyleType : "disc"}}>Contact information such as your email address, address, etc.</li>
            //                         <li style={{listStyleType : "disc"}}>Account details such as your user name, unique user ID, password, etc.</li>
            //                         <li style={{listStyleType : "disc"}}>Areas of interest you have provided in your account.</li>
            //                         <li style={{listStyleType : "disc"}}>Financial information need for payment processing purposes such as your credit or debit card number, expiration date and card verification code and associated bank details, etc.</li>
            //                         <li style={{listStyleType : "disc"}}>Transaction details such as any bookings, purchases, redemptions or cancellations you action via the Website.</li>
            //                         <li style={{listStyleType : "disc"}}>Participation information such as any comments, posts, reviews or other methods of communication you make or submit via the Website.</li>
            //                         <li style={{listStyleType : "disc"}}>Geolocation data such as your latitude and longitude.</li>
            //                         <li style={{listStyleType : "disc"}}>Any other materials you willingly submit to us such as articles, images, feedback, etc.</li>
            //                     </ul>
            //                 </div>
            //                 <div class="pt-2">
            //                     <p>We receive and store any information you knowingly provide to us when you create an account, publish content, make a purchase, or fill any online forms on the Website.</p>
            //                 </div>
            //                 <div class="pt-2">
            //                     <p>In addition to the information we collect directly from you via the Website and Services we may also receive and collect Personal Information about you from other affiliated sources which you have registered with. Such sources may include public databases, social media platforms, third-party data providers, and our joint marketing partners. We may associate and use this information with the other Personal Information we have collected.</p>
            //                 </div>
            //                 <div class="pt-2">
            //                     <p>Personal Information we collect from other sources may include demographic information (such as age and gender), device information (such as IP addresses) location data (such as city and state), and online behavioral data (such as information about your use of social media websites, page view information and search results and links).</p>
            //                 </div>
            //                 <div class="pt-2">
            //                     <p>You can choose not to provide us with your Personal Information, but then you may not be able to take advantage of some of the features on the Website. Users who are uncertain about what information is mandatory are welcome to contact us.</p>
            //                 </div>
            //             </div>


            //             <div class="pt-4 mt-4 text-left">
            //                 <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">Information About Others</h2>
            //             </div>


            //             <div class="pt-2 text-left">
            //                 <div class="pt-2">
            //                     <p>If, through your use of the Website or Services, you provide information about someone else you must ensure that you are authorized to disclose such information to Dr Clinica and that Dr Clinica is not required to take any steps to ensure the receipt of this data is in accordance with any applicable law. If you have provided Dr Clinica with Personal Information about someone else or someone has provided Personal Information about you and you would not like it to be retained please contact Dr Clinica to arrange for its deletion.</p>
            //                 </div>
            //             </div>


            //             <div class="pt-4 mt-4 text-left">
            //                 <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">Use and Processing of Collected Information</h2>
            //             </div>

            //             <div class="pt-2 text-left">
            //                 <div class="pt-2">
            //                     <p>The use of your Personal Information shall primarily be for the purposes of making our Website and Services available to you.</p>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>Where your Personal Information is used in relation to our Website and Services (and/or legal obligations) such uses may include (but not be limited to):</p>
            //                 </div>

            //                 <div class="pt-2 ml-4">
            //                     <ul>
            //                         <li style={{listStyleType : "disc"}}>Creation and management of your account.</li>
            //                         <li style={{listStyleType : "disc"}}>Facilitate, arrange and deliver requested appointments (and/or products) from service providers.</li>
            //                         <li style={{listStyleType : "disc"}}>Track appointments (and/or products) and perform any necessary analytics.</li>
            //                         <li style={{listStyleType : "disc"}}>Improve our Website, products and services including analysis of your use of the Website and Services for the purposes of improving the Users experience, displaying more relevant advertising and content.</li>
            //                         <li style={{listStyleType : "disc"}}>Providing support services to Users including responding to enquiries and feedback of Website Users.</li>
            //                         <li style={{listStyleType : "disc"}}>Providing marketing, promotional advertising, targeted advertising, offers, discounts and sales by way of electronic communication directly to the User and/or via the Website.</li>
            //                         <li style={{listStyleType : "disc"}}>Communicating and providing you with additional information that may be of interest to you about Dr Clinica and our service providers.</li>
            //                         <li style={{listStyleType : "disc"}}>Responding and attending to customer testimonials, legal requests, reports of online abusive behavior, trolling and malicious use of the Website and/or Services.</li>
            //                         <li style={{listStyleType : "disc"}}>Administer surveys, sweepstakes, contests, promotional activities, events, prize draws and other competitions sponsored by Dr Clinica or their service providers.</li>
            //                         <li style={{listStyleType : "disc"}}>Managing the Website and Services including the everyday business needs of the same such as administration, management of Website pages, fraud prevention, reporting obligations, enforcement of the terms of use, policies, procedures and conditions, undertaking analytics and any other steps as required under the applicable law.</li>
            //                     </ul>
            //                 </div>



            //                 <div class="pt-2">
            //                     <p>Beyond the above we may and will only otherwise use your personal Information where necessary to meet or comply with a legal obligation or applicable law.</p>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>Processing your Personal Information depends on how you interact with the Website and Services, where you are located in the world and if one of the following applies:</p>
            //                 </div>


            //                 <div class="pt-2 ml-4">
            //                     <ul>
            //                         <li style={{listStyleType : "disc"}}>You have given your consent for one or more specific purposes (this, however, does not apply, whenever the processing of Personal Information is subject to California Consumer Privacy Act of 2018 or the European General Data Protection Regulation (EU) 2016/679).</li>
            //                         <li style={{listStyleType : "disc"}}>Provision of information is necessary for the performance of an agreement with you and/or for any pre-contractual obligations thereof.</li>
            //                         <li style={{listStyleType : "disc"}}>Processing is necessary for compliance with a legal obligation to which you are subject.</li>
            //                         <li style={{listStyleType : "disc"}}>Processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in us.</li>
            //                         <li style={{listStyleType : "disc"}}>Processing is necessary for the purposes of the legitimate interests pursued by us or by a third party.</li>
            //                     </ul>
            //                 </div>


            //                 <div class="pt-2">
            //                     <p>We note that under some legislations we may be allowed to process information until you object to such processing (by opting out), without having to rely on consent or any other of the following legal bases below.</p>
            //                 </div>


            //                 <div class="pt-2">
            //                     <p>In any case, we will be happy to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Information is a statutory or contractual requirement, or a requirement necessary to enter into a contract.</p>
            //                 </div>

            //             </div>



            //             <div class="pt-4 mt-4 text-left">
            //                 <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">Billing and Payments</h2>
            //             </div>

            //             <div class="pt-2 text-left">

            //                 <div class="pt-2">
            //                     <p>In case of services requiring payment, we will request only the necessary credit card, debit card or other payment account information, which will be used solely for the processing of payments. We do not store or retain any payment data received for the purposes of your transaction.</p>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>Any payment data provided is encrypted by way of a secure iFrame Tokenizer which converts your payment data into a string of randomly generated numbers called a ‘token’ which is utilized to obtain approval from your bank to transfer the necessary payment for any services. Tokenization is irreversible in the event of a data breach or other exposure.</p>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>Sensitive and private data exchange happens over an SSL secured communication channel and is encrypted and protected with digital signatures, and the Website and Services are also in compliance with PCI vulnerability standards in order to create as secure of an environment as possible for Users. Scans for malware are performed on a regular basis for additional security and protection.</p>
            //                 </div>

            //             </div>

            //             <div class="pt-4 mt-4 text-left">
            //                 <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">Retention of Information</h2>
            //             </div>

            //             <div class="pt-2 text-left">

            //                 <div class="pt-2">
            //                     <p>We will retain and use your Personal Information for the period you continue to use our Website and Services and only otherwise as necessary to comply with any contractual obligations with you, our legal, financial, or auditing obligations, to resolve disputes, and enforce our agreements unless a longer retention period is required or permitted by law.</p>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>We may use any aggregated data derived from or incorporating your Personal Information after you update or delete it, but not in a manner that would identify you personally.</p>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>Once the retention period expires, Personal Information shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification, and the right to data portability cannot be enforced after the expiration of the retention period.</p>
            //                 </div>

            //             </div>

            //             <div class="pt-4 mt-4 text-left">
            //                 <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">Managing & Deletion of Information</h2>
            //             </div>

            //             <div class="pt-2 text-left">

            //                 <div class="pt-2">
            //                     <p>You can arrange for the deletion of your account at your discretion. To delete your account, you can do so on your account's settings page on the Website or by contacting us. Where you request or arrange the deletion of your account, we shall arrange for the anonymization of any and all Personal Information you have provided to us, which shall not be utilized for any purposes beyond our legal, financial, or auditing obligations, to resolve disputes, and enforce our agreements unless a longer retention period is required or permitted by law.</p>    
            //                 </div>
            //             </div>



            //             <div class="pt-4 mt-4 text-left">
            //                 <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">Disclosure of Information</h2>
            //             </div>

            //             <div class="pt-2 text-left">

            //                 <div class="pt-2">
            //                     <p>We do not share, sell, trade, or rent personal information with unaffiliated third parties.</p>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>Depending on the requested services, or as necessary to complete any transaction or provide any service you have requested through our Website, we may contract with other companies and share your information (with your consent) with our trusted third parties that work with us, any other affiliates and subsidiaries we rely upon to assist in the operation of the Website and Services available to you.</p>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>We may share your Personal Information for these purposes only with third parties whose privacy policies are consistent with ours or who agree to abide by our policies with respect to Personal Information. These third parties are given Personal Information they need only to perform their designated functions, and we do not authorize them to use or disclose Personal Information for their own marketing or other purposes.</p>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>Any contracted third party or service provider is not authorized to use or disclose your information except as necessary to perform services on our behalf or comply with legal requirements.</p>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>We will disclose any Personal Information we collect, use or receive if required or permitted by law (including to our professional or legal advisors), such as to comply with a court order, subpoena, or similar legal process, and when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request.</p>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>In the event we go through a business transition, such as a merger or acquisition by another company, or sale of all or a portion of its assets, your user account, and Personal Information will likely be among the assets transferred.</p>
            //                 </div>

            //             </div>

            //             <div class="pt-4 mt-4 text-left">
            //                 <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">Transfer of Information</h2>
            //             </div>

            //             <div class="pt-2 text-left">

            //                 <div class="pt-2">
            //                     <p>Depending on your location, data transfers may involve transferring and storing your information in a country other than your own.</p>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>You are entitled to learn about the legal basis of information transfers to a country outside the United Arab Emirates to any other jurisdiction, country, international organization governed by public international law or set up by two or more countries, such as the UN, and about the security measures taken by us to safeguard your information.</p>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>If any such transfer takes place, you can find out more by checking the relevant sections of this Policy or inquire with us using the information provided in the contact section. Whenever such a data transfer occurs we shall take all steps reasonably necessary to ensure that any transferred personal information is treated securely and in accordance with the applicable data protection laws.</p>
            //                 </div>
            //             </div>

            //             <div class="pt-4 mt-4 text-left">
            //                 <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">The Rights of Users</h2>
            //             </div>

            //             <div class="pt-2 text-left">

            //                 <div class="pt-2">
            //                     <p>You may exercise certain rights regarding your Personal Information processed by us.</p>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>In particular, you have the right to do the following:</p>
            //                 </div>

            //                 <div class="pt-2 ml-4">
            //                     <ul>
            //                         <li style={{listStyleType : "disc"}}>You have the right to withdraw consent where you have previously given your consent to the processing of your Personal Information.</li>
            //                         <li style={{listStyleType : "disc"}}>You have the right to object to the processing of your Personal Information if the processing is carried out on a legal basis other than consent.</li>
            //                         <li style={{listStyleType : "disc"}}>You have the right to learn if Personal Information is being stored and/or processed by us, to obtain disclosure regarding certain aspects of the processing, and obtain a copy of the Personal Information undergoing processing.</li>
            //                         <li style={{listStyleType : "disc"}}>You have the right to verify the accuracy of your Personal Information and ask for it to be updated or corrected.</li>
            //                         <li style={{listStyleType : "disc"}}>You have the right, under certain circumstances, to restrict or object to the processing of your Personal Information (including for marketing, profiling or a third party’s interest), in which case, we will not process your Personal Information for any purpose other than storing it.</li>
            //                         <li style={{listStyleType : "disc"}}>You have the right, under certain circumstances, to be forgotten and require the erasure of your Personal Information from us.</li>
            //                         <li style={{listStyleType : "disc"}}>You have the right to receive your Personal Information in a structured, commonly used and machine-readable format and, if technically feasible, to have it transmitted to another controller without any hindrance.</li>
            //                     </ul>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>These rights are applicable provided that your Personal Information is processed by automated means and that the processing is based on your consent, on a contract which you are part of, or on pre-contractual obligations thereof</p>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>Please also keep in mind that there may be situations where we cannot honour any request or instruction submitted in accordance with the above rights, such as where the Personal Information no longer exists, or there is an exception that applies (i.e., an ongoing service or legal dispute).</p>
            //                 </div>

            //             </div>

            //             <div class="pt-4 mt-4 text-left">
            //                 <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">The Right to Object to Processing</h2>
            //             </div>

            //             <div class="pt-2 text-left">

            //                 <div class="pt-2">
            //                     <p>Where Personal Information is processed for the public interest, in the exercise of an official authority vested in us or for the purposes of the legitimate interests pursued by us, you may object to such processing by providing a ground related to your particular situation to justify the objection.</p>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>You must know that, however, should your Personal Information be processed for direct marketing purposes, you can object to that processing at any time without providing any justification.</p>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>To learn whether we are processing Personal Information for direct marketing purposes, you may refer to the relevant sections of this document.</p>
            //                 </div>
            //             </div>


            //             <div class="pt-4 mt-4 text-left">
            //                 <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">Data Protection Rights under GDPR</h2>
            //             </div>

            //             <div class="pt-2 text-left">

            //                 <div class="pt-2">
            //                     <p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights and Dr Clinica aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Information.</p>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>If you wish to be informed what Personal Information we hold about you and if you want it to be removed from our systems, please get in touch with us. In certain circumstances, you have the following data protection rights:</p>
            //                 </div>

            //                 <div class="pt-2 ml-4">
            //                     <ul>
            //                         <li style={{listStyleType : "disc"}}>You have the right to request access to the Personal Information that we store and have the ability to access your Personal Information.</li>
            //                         <li style={{listStyleType : "disc"}}>You have the right to request that we correct any Personal Information you believe is inaccurate. You also have the right to request us to complete the Personal Information you believe is incomplete.</li>
            //                         <li style={{listStyleType : "disc"}}>You have the right to request the erase of your Personal Information under certain conditions of this Policy.</li>
            //                         <li style={{listStyleType : "disc"}}>You have the right to object to our processing of your Personal Information.</li>
            //                         <li style={{listStyleType : "disc"}}>You have the right to seek restrictions on processing your Personal Information. When you restrict processing your Personal Information, we may store it but will not process it further.</li>
            //                         <li style={{listStyleType : "disc"}}>You have the right to be provided with a copy of the information we have on you in a structured, machine-readable, and commonly used format.</li>
            //                         <li style={{listStyleType : "disc"}}>You also have the right to withdraw your consent at any time when Dr Clinica relied on your consent to process your Personal Information.</li>
            //                     </ul>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>You have the right to complain to the appropriate data protection authorities about our collection and use of your Personal Information. For more information, please get in touch with your local data protection authority in the European Economic Area (EEA).</p>
            //                 </div>
            //             </div>

            //             <div class="pt-4 mt-4 text-left">
            //                 <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">These Rights</h2>
            //             </div>

            //             <div class="pt-2 text-left">

            //                 <div class="pt-2">
            //                     <p>Any requests to exercise your rights can be directed to Dr. Clinica through the contact details provided in this document.</p>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>Please note that we may ask you to verify your identity before responding to such requests. You must provide sufficient information that allows us to verify that you are the person you are claiming to be or that you are the authorized representative of such a person. You must include sufficient details to allow us to understand the request and respond to it properly. We cannot respond to your request or provide you with Personal Information unless we first verify your identity or authority to make such a request and confirm that the Personal Information relates to you.</p>
            //                 </div>
            //             </div>

            //             <div class="pt-4 mt-4 text-left">
            //                 <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">Minimum Age Requirements</h2>
            //             </div>

            //             <div class="pt-2 text-left">

            //                 <div class="pt-2">
            //                     <p>We do not knowingly collect any Personal Information from anyone under the age of 18.</p>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>If you are under the age of 18, please do not submit any Personal Information through the Website and Services.</p>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>If you have reason to believe anyone under 18 has provided Personal Information to us through the Website and Services, please contact us.</p>
            //                 </div>

            //             </div>


            //             <div class="pt-4 mt-4 text-left">
            //                 <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">Privacy of Children</h2>
            //             </div>

            //             <div class="pt-2 text-left">

            //                 <div class="pt-2">
            //                     <p>We encourage parents and legal guardians to monitor their children's Internet usage and to help enforce this Policy by instructing their children never to provide Personal Information through the Website and Services without their permission.</p>
            //                 </div>

            //             </div>

            //             <div class="pt-4 mt-4 text-left">
            //                 <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">Do Not Track Signals</h2>
            //             </div>

            //             <div class="pt-2 text-left">

            //                 <div class="pt-2">
            //                     <p>Some browsers incorporate a Do Not Track feature that signals to websites you visit that you do not want to have your online activity tracked. Tracking is not the same as using or collecting information in connection with a website.</p>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>For these purposes, tracking refers to collecting personally identifiable information from consumers who use or visit a website or online service as they move across different websites over time.</p>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>Our Website and Services do not track its visitors over time and across third-party websites. However, some third-party sites may keep track of your browsing activities when they serve you content, which enables them to tailor what they present to you.</p>
            //                 </div>

            //             </div>

            //             <div class="pt-4 mt-4 text-left">
            //                 <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">Aggregated Data</h2>
            //             </div>

            //             <div class="pt-2 text-left">

            //                 <div class="pt-2">
            //                     <p>We may share aggregated and non-identifying information about our customers that is collected through your use of the Website and Services in order to better understand how our customers use our Website, create performance metrics, tailor the information on our website to better suit our customers.</p>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>We do not share personally identifiable information about individual customers with advertisers.</p>
            //                 </div>

            //             </div>

            //             <div class="pt-4 mt-4 text-left">
            //                 <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">Affiliates & Advertising</h2>
            //             </div>

            //             <div class="pt-2 text-left">

            //                 <div class="pt-2">
            //                     <p>We may engage in affiliate marketing and display third-party online advertisements on the Website and Services.</p>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>Affiliates may have links present on the Website and Services and third-party companies may help us tailor advertising that we think may be of interest to users and to collect and use other data about user activities on the Website. These companies may deliver ads that might place cookies and otherwise track user behavior.</p>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>If you click on an affiliate link or third party advertisement, a third-party cookie will be placed on your browser to track any sales for purposes of commissions. These third-party cookies automatically collect information about your visit to our Website, your IP address, your ISP and the browser you use. Information collected may be used, among other things, to deliver advertising targeted to your interests and to better understand the usage and visitation patterns of our platform and Websites.</p>
            //                 </div>

            //             </div>

            //             <div class="pt-4 mt-4 text-left">
            //                 <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">Email Marketing</h2>
            //             </div>

            //             <div class="pt-2 text-left">

            //                 <div class="pt-2">
            //                     <p>We offer electronic newsletters to which you may voluntarily subscribe at any time. We are committed to keeping your e-mail address confidential and will not disclose your email address to any third parties except as allowed in the information use and processing section or for the purposes of utilizing a third party provider to send such emails. We will maintain the information sent via e-mail in accordance with applicable laws and regulations.</p>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>E-mails sent from us will state who the e-mail is from and provide clear information on how to contact the sender (at our discretion). You may choose to stop receiving our newsletter or marketing emails by following the unsubscribe instructions included in these emails or by contacting us. However, you will continue to receive essential transactional emails.</p>
            //                 </div>
            //             </div>

            //             <div class="pt-4 mt-4 text-left">
            //                 <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">Links to Third Party Websites and Services</h2>
            //             </div>

            //             <div class="pt-2 text-left">

            //                 <div class="pt-2">
            //                     <p>The Website and Services contain links to other resources that are not owned or controlled by us.</p>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>Please be aware that we are not responsible for the privacy practices of such other resources or third parties. We encourage you to be aware when you leave the Website and Services and to read the privacy statements of each and every resource that may collect Personal Information.</p>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>We are not responsible for the privacy policies of any third-party websites.</p>
            //                 </div>
            //             </div>

            //             <div class="pt-4 mt-4 text-left">
            //                 <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">Information Security</h2>
            //             </div>

            //             <div class="pt-2 text-left">

            //                 <div class="pt-2">
            //                     <p>We secure the information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use, or disclosure.</p>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>We maintain reasonable administrative, technical, and physical safeguards in an effort to protect against unauthorized access, use, modification, and disclosure of Personal Information in its control and custody. However, no data transmission over the Internet or wireless network can be guaranteed.</p>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>Therefore, while we strive to protect your Personal Information, you acknowledge that</p>
            //                 </div>

            //                 <div class="pt-2 ml-4">
            //                     <ul>
            //                         <li style={{listStyleType : "disc"}}>There are security and privacy limitations of the Internet which are beyond our control.</li>
            //                         <li style={{listStyleType : "disc"}}>The security, integrity, and privacy of any and all information and data exchanged between you and the Website and Services cannot be guaranteed.</li>
            //                         <li style={{listStyleType : "disc"}}>Any such information and data may be viewed or tampered with in transit by a third party, despite best efforts.</li>
            //                     </ul>
            //                 </div>
            //             </div>

            //             <div class="pt-4 mt-4 text-left">
            //                 <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">Data Breach</h2>
            //             </div>

            //             <div class="pt-2 text-left">

            //                 <div class="pt-2">
            //                     <p>In the event we become aware that the security of the Website and Services has been compromised or users Personal Information has been disclosed to unrelated third parties as a result of external activity, including, but not limited to, security attacks or fraud, we reserve the right to take reasonably appropriate measures, including, but not limited to, investigation and reporting, as well as notification to and cooperation with law enforcement authorities.</p>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>In the event of a data breach, we will make reasonable efforts to notify affected individuals if we believe that there is a reasonable risk of harm to the user as a result of the breach or if notice is otherwise required by law. When we do, we will send you an email.</p>
            //                 </div>
            //             </div>

            //             <div class="pt-4 mt-4 text-left">
            //                 <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">Changes and Amendments</h2>
            //             </div>

            //             <div class="pt-2 text-left">

            //                 <div class="pt-2">
            //                     <p>We reserve the right to modify this Policy or its terms relating to the Website and Services from time to time in our discretion and you will also be notified via E-mail of any significant changes of how we process your information and you will be requested to provide your consent to such changes when required by law.</p>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>When we do, we will revise the updated date on this page. We may also provide notice to you in other ways in our discretion, such as through contact information you have provided.</p>
            //                 </div>

            //                 <div class="pt-2">
            //                     <p>Any updated version of this Policy will be effective immediately upon the posting of the revised Policy unless otherwise specified. Your continued use of the Website and Services after the effective date of the revised Policy (or such other act specified at that time) will constitute your consent to those changes. However, we will not, without your consent, use your Personal Information in a manner materially different than what was stated at the time your Personal Information was collected.</p>
            //                 </div>
            //             </div>

            //             <div class="pt-4 mt-4 text-left">
            //                 <h2 class="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">Acceptance of this Policy</h2>
            //             </div>

            //             <div class="pt-2 text-left">

            //                 <div class="pt-2">
            //                     <p>You acknowledge that you have read this Policy and agree to all its terms and conditions. By accessing and using the Website and Services, you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to access or use the Website and Services.</p>
            //                 </div>
            //             </div>

            //         </div>
            //     </div>
            // </main> */}
