import { useRef } from "react"
import configData from "../../utils/constants/config.json"
import { useState } from "react"
import axios from "axios"

const AddMeasurement = ({
    handleOpenAddMeasurement,
    setSelectedMeasurement,
    handleSelectMeasurement
}) => {

    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)

    const [message, setMessage] = useState("")

    const unit = useRef()
    const amount = useRef()

    const handleCreate = () => {
        if (!unit?.current?.value) {
            setError(true)
            setMessage("Please fill unit field")
            setTimeout(() => {
                setError(false)
            }, 1500);
            return
        }
        if (!amount?.current?.value ) {
            setError(true)
            setMessage("Please fill amount field")
            setTimeout(() => {
                setError(false)
            }, 1500);
            return
        }
        if (parseFloat(amount?.current?.value) < 0   ) {
            setError(true)
            setMessage("Amount should be greater than 0")
            setTimeout(() => {
                setError(false)
            }, 1500);
            return
        }

        const data = {
            "unit": unit.current.value,
            "amount": parseInt(amount.current.value),
        }

        axios({
            method: "post",
            url: `${configData.SERVER_URL}partner/product/add-product-measurement`,
            headers: {
                accesstoken: configData.ACCESSTOKEN,
                logintoken: localStorage.getItem("loginToken"),
            },
            data
        })
            .then(res => {
                if (res?.data[200]) {
                    setSelectedMeasurement(res?.data?.data?.measurement)
                    handleOpenAddMeasurement()
                    handleSelectMeasurement()
                    setSuccess(true)
                    setTimeout(() => {
                        setSuccess(false)
                    }, 1500);
                }
            })
            .catch(err => {
                setError(true)
                setMessage("Something went Wrong try again")
                setTimeout(() => {
                    setError(false)
                }, 1500);
                console.log(err)
            })
    }

    return (
        <div className={`fixed inset-0 flex items-center overflow-hidden justify-center transition-opacity duration-300 ease-in-out z-[999]`} >
            <div className="absolute inset-0 bg-gray-800 opacity-75 "></div>
            <div className={`w-full sm:w-1/2 lg:w-1/3 z-50 bg-white p-8 rounded-lg shadow-lg mx-4`}>
                <h2 className="text-xl font-semibold mb-4">add a measurement</h2>

                {
                    success && <div className='p-3' style={{ color: '#fff', backgroundColor: 'rgba(0,225, 0, .3)', borderRadius: '8px' }}>
                        Measurement Has Been Added Successfully
                    </div>
                }
                {
                    error && <div className='p-3' style={{ color: '#fff', backgroundColor: 'rgba(225,0, 0, .3)', borderRadius: '8px' }}>
                        {message}
                    </div>
                }

                <label className="p-2 block w-full  text-left" style={{ marginTop: "8px" }}>
                    <span className="block lg:w-1/2 text-sm font-medium text-slate-700">
                        Measurement Unit
                    </span>
                    <input
                        ref={unit}
                        type="text"
                        name="unit"
                        id="unit"
                        className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                        placeholder="Enter Measurement Unit"
                        required
                    />
                </label>
                <label className="p-2 block w-full  text-left" style={{ marginTop: "8px" }}>
                    <span className="block lg:w-1/2 text-sm font-medium text-slate-700">
                        Measurement Amount
                    </span>
                    <input
                        ref={amount}
                        type="number"
                        name="amount"
                        id="amount"
                        className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                        placeholder="Enter Measurement Amount"
                        required
                    />
                </label>

                <div className="flex justify-around">
                    <div
                        className='mt-4 btn border border-slate-300 font-medium hover:text-slate-300 text-slate-800 hover:bg-slate-800 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90'
                        onClick={handleCreate}
                    >
                        Add
                    </div>
                    <div
                        className='mt-4 btn border border-slate-300 font-medium hover:text-slate-300 text-slate-800 hover:bg-slate-800 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90'
                        onClick={handleOpenAddMeasurement}
                    >
                        Close
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddMeasurement