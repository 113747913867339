export const data = [
  {
    title: 'Comment on Revenue Projections',
    priorityId: 2,
    startDate: new Date(2018, 4, 28, 10, 0, 0),
    endDate: new Date(2018, 4, 28, 13, 0, 0),
    id: 51,
  },
  {
    title: 'Write title here',
    priorityId: 1,
    startDate: new Date(2018, 4, 28, 10, 0, 0),
    endDate: new Date(2018, 4, 28, 11, 0, 0),
    id: 52,
  },
]

export const priorityData = []
