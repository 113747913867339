import React from 'react';

class MobileSearchBar extends React.Component{
    constructor(props) {
        super(props)
    }

	render(){
		return(
            null
		);
	}
}

export default MobileSearchBar