import React, { Fragment, useState, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import ClearDataButton from "../ClearDataButton/ClearDataButton";


const TimeSlotInput= ({
  className = "flex-1",
  classNameTopMines = "",
  slots = [],
  selectedSlot = "",
  slotMessage = null,
  setSelectedSlot,
  selectedService,
  duration,
}) => {

  const [slotsList, setSlotsList] = useState(slots);
  const [labelSlot, setLabelSlot] = useState(null);
  useEffect(() => {
    setSlotsList(slots);
  }, [slots]);

  function addMinutesToTime(initialTime, minutesToAdd) {
    // Parse the input time using a regular expression
    const timeRegex = /^(\d{1,2}):(\d{2}) (am|pm)$/i;
    const match = initialTime.match(timeRegex);

    if (!match) {
      return "Invalid time format. Please use 'hh:mm am/pm'.";
    }

    let [, hours, minutes, period] = match;

    // Convert hours and minutes to integers
    hours = parseInt(hours, 10);
    minutes = parseInt(minutes, 10);

    // Convert to 24-hour format for easier calculation
    if (period.toLowerCase() === "pm") {
      hours += 12;
    }

    // Add the specified minutes
    const newDate = new Date();
    newDate.setHours(hours);
    newDate.setMinutes(minutes + minutesToAdd);

    // Format the result back to "hh:mm am/pm" format
    let newHours = newDate.getHours();
    const newMinutes = newDate.getMinutes();
    const newPeriod = newHours < 12 ? "AM" : "PM";

    // Convert newHours to 12-hour format
    if (newHours > 12) {
      newHours -= 12;
    }

    const result = `${newHours}:${String(newMinutes).padStart(2, "0")} ${newPeriod}`;

    return result;
  }

  const formatTime = (time) => {
    // Function to convert 24-hour time to AM/PM format
    const [hours, minutes] = time.split(":").map(Number);
    const period = hours < 12 ? "AM" : "PM";
    const formattedHours = hours % 12 || 12;
    return `${formattedHours}:${minutes.toString().padStart(2, "0")} ${period}`;
  };

  const handleChangeData = (value) => {
    setSelectedSlot(value.toString());
    document.getElementById("TimeSelector")?.click();
  };

  const renderLabel = (slot, index) => {
    return (
      <div
        key={index}
        onClick={() => handleChangeData(slot)}
        className={`w-full flex-1 flex items-center focus:outline-none rounded-b-3xl p-2`}
      >
        <i className={`text-4xl text-neutral-300 text-[27px] fas fa-clock`} />
        <div className="w-full flex flex-col border-b border-neutral-200 p-2">
          <span className="font-medium text-neutral-800 dark:text-neutral-200">
            {formatTime(slot)}
          </span>
        </div>
        <i
          className={`text-4xl text-[27px] fas ${selectedSlot && slot === selectedSlot ? "fa-check-circle text-green-600" : " text-neutral-300 fa-circle"
            }`}
        ></i>
      </div>
    );
  };
  return (
    <Popover className={`flex relative ${className}`}>
      {({ open }) => (
        <>
          <div
            className={`  flex-1 flex items-center focus:outline-none rounded-b-3xl ${open ? "shadow-lg" : ""
              }`}
          >
            <Popover.Button
              id={"TimeSelector"}
              className={`relative z-10 flex-1 flex text-left items-center p-3 space-x-3 focus:outline-none `}
              style={{ zIndex: "9" }}
            >
              <i className={`text-[27px]  text-neutral-300 fas fa-clock`} />
              <div className="flex-grow">
                <span className="block xl:text-lg font-semibold">
                  {
                    selectedSlot && duration ?
                      `${formatTime(selectedSlot)} to ${addMinutesToTime(`${selectedSlot} am`, duration )} ` :
                      selectedSlot ? formatTime(selectedSlot)
                        : "Select Time Slot"
                  }
                </span>
                <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                  {selectedSlot
                    ? "Your appointment time slot"
                    : "Select time slot for your appointment"}
                </span>
              </div>
              {!!selectedSlot && open && (
                <ClearDataButton
                  onClick={() => {
                    setSelectedSlot(null);
                  }}
                />
              )}
            </Popover.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              className={`max-h-[400px] overflow-y-auto absolute right-0 z-20 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl time-slot-height ${classNameTopMines}`}
            >
              {slotMessage == null && slotsList == null ? (
                <div className={`w-full flex-1 flex items-center focus:outline-none rounded-b-3xl p-2`}>
                  <i className={`text-4xl text-neutral-400 las la-clock`} />
                  <div className="w-full flex flex-col border-b border-neutral-200 p-2">
                    <span className="font-medium text-neutral-800 dark:text-neutral-200">
                      {`Please Wait...`}
                    </span>
                  </div>
                  <i className={`text-2xl text-neutral-6000 las la-spinner la-spin p-2`} />
                </div>
              ) : slotsList == null ? (
                <div
                  className={`w-full flex-1 flex items-center focus:outline-none rounded-b-3xl p-2 text-error-400 ${classNameTopMines}`}
                >
                  <i className={`text-4xl text-red-600 dark:text-red-200 las la-clock`} />
                  <div className="w-full flex flex-col border-b border-neutral-200 p-2">
                    <span className="font-medium text-red-600 dark:text-red-200">
                      {slotMessage}
                    </span>
                  </div>
                </div>
              ) : (
                slotsList.map((slot, index) => renderLabel(slot, index))
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};
export default TimeSlotInput;