import { useState } from "react"
import { useRef } from "react"
import configData from '../../utils/constants/config.json'
import axios from "axios"

const AddCategoryProduct = ({
    handleOpenAddCategory,
    setSelectedCategory,
    handleOpenSelectCategory
}) => {

    const name = useRef()
    const isPopular = useRef()

    const [errorText, setErrorText] = useState(false)

    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)

    const handleCreate = () => {

        if (!name?.current?.value) {
            setErrorText(true)
            setTimeout(() => {
                setErrorText(false)
            }, 2500);
            return
        }

        const data = {
            "name": name?.current?.value,
            "ispopular": isPopular?.current?.checked
        }

        axios({
            method: "post",
            url: `${configData.SERVER_URL}partner/product/add-product-category`,
            headers: {
                accesstoken: configData.ACCESSTOKEN,
                logintoken: localStorage.getItem("loginToken"),
            },
            data
        }
        )
            .then(res => {
                if(res?.data[200]){
                    setSelectedCategory(res?.data?.data?.category)
                    setSuccess(true)
                    setTimeout(() => {
                        setSuccess(false)
                        handleOpenAddCategory()
                        handleOpenSelectCategory()
                    }, 2500);
                    return
                }
            })
            .catch(err => {
                console.log(err)
                setError(true)
                    setTimeout(() => {
                        setError(false)
                    }, 2500);
               
                console.log(err)
            })
    }

    return (
        <div className={`fixed inset-0 flex items-center overflow-hidden justify-center transition-opacity duration-300 ease-in-out z-[999]`} >
            <div className="absolute inset-0 bg-gray-800 opacity-75 "></div>
            <div className={`w-full sm:w-1/2 lg:w-1/3 z-50 bg-white p-8 rounded-lg shadow-lg mx-4`}>
                <h2 className="text-xl font-semibold mb-4">add a category</h2>

                {
                    success && <div className='p-3' style={{ color: '#fff', backgroundColor: 'rgba(0, 255, 0, .3)', borderRadius: '8px' }}>
                        Category Added Successfully 
                    </div>
                }
                {
                    error && <div className='p-3' style={{ color: '#fff', backgroundColor: 'rgba(255, 0, 0, .3)', borderRadius: '8px' }}>
                        something went wrong, try again
                    </div>
                }
                {
                    errorText && <div className='p-3' style={{ color: '#fff', backgroundColor: 'rgba(255, 0, 0, .3)', borderRadius: '8px' }}>
                        Please fill the name field
                    </div>
                }

                <label className="p-2 block w-full  text-left" style={{ marginTop: "8px" }}>
                    <span className="block lg:w-1/2 text-sm font-medium text-slate-700">
                        Category Name
                    </span>
                    <input
                        ref={name}
                        type="text"
                        name="name"
                        id="name"
                        className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                        placeholder="Enter Category Name"
                        required
                    />
                </label>
                {/* <div className="flex p-2">
                    <input type="file" name="file" id="file" />
                </div> */}
                <div className="p-2">
                    <div class="flex items-center">
                        <input ref={isPopular} id="checked-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                        <label for="checked-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Is Popular </label>
                    </div>
                </div>

                <div className="flex justify-around">
                    <div
                        className='mt-4 btn border border-slate-300 font-medium hover:text-slate-300 text-slate-800 hover:bg-slate-800 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90'
                        onClick={handleCreate}
                    >
                        Add
                    </div>
                    <div
                        className='mt-4 btn border border-slate-300 font-medium hover:text-slate-300 text-slate-800 hover:bg-slate-800 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90'
                        onClick={handleOpenAddCategory}
                    >
                        Close
                    </div>
                </div>



            </div>
        </div>
    )
}

export default AddCategoryProduct